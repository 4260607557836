import { ProposalsAndRequests_ABI } from "../../../abis/ProposalsAndRequests";
import { DisputeResolution_ABI } from "../../../abis/DisputeResolution";
import { CompletedTransactions_ABI } from "../../../abis/CompletedTransactions";
import { MainContract_ABI } from "../../../abis/MainContract";
import { readContract, writeContract, fetchFeeData } from "@wagmi/core";
import { conversionData } from "../../../utils/conversionData";
import { handledError } from "../../../utils/handledError";
import {
  fetchActiveProposals,
  fetchActiveProposalsSuccess,
  fetchActiveProposalsSuccessAll,
  fetchActiveProposalsFailure,
  fetchCompletedProposals,
  fetchCompletedProposalsSuccess,
  fetchCompletedProposalsSuccessAll,
  fetchCompletedProposalsFailure,
  updateActiveProposals,
  deleteProposalSuccesSeller,
  cleanActiveProposals,
  addIdForNewProposalSuccess,
  addIdForUpdateProposalSuccess,
  addIdForDeleteProposalSuccess,
  addIdForProposaCompletedSuccess,
  // addMessageForUpdateProposal,
  sendBuyerRequestSuccess,
  sendBuyerRequestFailure,
  confirmFiatPaymentSuccess,
  confirmFiatPaymentFailure,
  createDisputeSuccess,
  createDisputeFailure,
  requestCancellationSuccess,
  requestCancellationFailure,
  deactivateBuyerRequestSuccess,
  deactivateBuyerRequestFailure,
  filterProposalsSuccess,
  cleanMessageSuccess,
  cleanErrorSuccess,
  addNewProposalSucces,
} from "./buyerSlice";

const fetchProposal = async (
  id,
  address,
  ProposalsAndRequests_ADDRESS,
  DisputeResolution_ADDRESS
) => {
  try {
    let proposals = {};
    const proposal = await readContract({
      address: ProposalsAndRequests_ADDRESS,
      abi: ProposalsAndRequests_ABI,
      functionName: "proposals",
      args: [id],
    });

    const requests = await readContract({
      address: ProposalsAndRequests_ADDRESS,
      abi: ProposalsAndRequests_ABI,
      functionName: "getActiveRequestsByProposal",
      args: [id],
    });

    let buyerRequest = null;
    if (requests.length) {
      for (let i = 0; i < requests.length; i++) {

        if (requests[i].buyer === address) {
          const request = requests[i];
         
          const canCreateDisput = await readContract({
            address: DisputeResolution_ADDRESS,
            abi: DisputeResolution_ABI,
            functionName: "canCreateDispute",
            args: [id, conversionData(request.buyerRequestIndex)],
          });

          buyerRequest = {
            buyerRequestIndex: conversionData(request.buyerRequestIndex),
            proposalId: conversionData(request.proposalId),
            amount: (conversionData(request.amount) * 10 ** -6).toFixed(2),
            date: conversionData(request.date),
            buyer: request.buyer,
            accepted: request.accepted,
            paymentDeclared: request.paymentDeclared,
            transactionCompleted: request.transactionCompleted,
            buyerRequestedtoCancel: request.buyerRequestedtoCancel,
            sellerRequestedtoCancel: request.sellerRequestedtoCancel,
            disputeCreated: request.disputeCreated,
            active: request.active,
            canCreateDisput,
          };
        }
      }
    }
  
    const amount = (conversionData(proposal[0]) * 10 ** -6).toFixed(2);
    const escrowBalance = (conversionData(proposal[1]) * 10 ** -6).toFixed(2);
    const transactionsCount =
      conversionData(proposal[10]) + conversionData(proposal[11]);
    const paymentMethods = proposal[4].split(",");
    proposals = {
      id: id,
      amount,
      price: conversionData(proposal[2]) / 100,
      paymentMethods,
      description: proposal[5],
      seller: proposal[9],
      request: buyerRequest,
      escrowBalance,
      date: conversionData(proposal[3]),
      transactionsCount,
      discord: proposal[7],
      comment: proposal[6],
    };

    return proposals;
  } catch (error) {
    console.error("Error fetching active proposals:", error);
  }
};



export const fetchProposals =
  (address, ProposalsAndRequests_ADDRESS, DisputeResolution_ADDRESS) =>
  async (dispatch) => {
    dispatch(fetchActiveProposals());

    try {
      const proposalIdActive = await readContract({
        address: ProposalsAndRequests_ADDRESS,
        abi: ProposalsAndRequests_ABI,
        functionName: "getActiveProposalIds",
      });

      for (let i = proposalIdActive.length - 1; i >= 0; i--) {
        const currentId = conversionData(proposalIdActive[i]);
        const proposal = await fetchProposal(
          currentId,
          address,
          ProposalsAndRequests_ADDRESS,
          DisputeResolution_ADDRESS
        );
       
        dispatch(fetchActiveProposalsSuccess(proposal));
      }
      dispatch(fetchActiveProposalsSuccessAll());
    } catch (error) {
      console.error("Error fetching active proposals:", error);
      dispatch(fetchActiveProposalsFailure("Error fetching active proposals."));
    }
  };

export const fetchProposalsCompleted =
  (address, CompletedTransactions_ADDRESS) => async (dispatch) => {
    dispatch(fetchCompletedProposals());
    try {
      const transactionscompletedBuyer = await readContract({
        address: CompletedTransactions_ADDRESS,
        abi: CompletedTransactions_ABI,
        functionName: "buyerTransactionCounts",
        args: [address],
      });

      for (
        let j = conversionData(transactionscompletedBuyer) - 1;
        j >= 0;
        j--
      ) {
        const completedTransaction = await readContract({
          address: CompletedTransactions_ADDRESS,
          abi: CompletedTransactions_ABI,
          functionName: "buyerCompletedTransactions",
          args: [address, j],
        });

        const proposal = {
          date: conversionData(completedTransaction[0]),
          buyer: completedTransaction[1],
          seller: completedTransaction[2],
          amount: (
            conversionData(completedTransaction[3]) *
            10 ** -6
          ).toFixed(),
          price: conversionData(completedTransaction[4]) / 100,
          proposalID: conversionData(completedTransaction[5]),
          buyerRequestIndex: conversionData(completedTransaction[6]),
        };

        dispatch(fetchCompletedProposalsSuccess(proposal));
      }

      dispatch(fetchCompletedProposalsSuccessAll());
    } catch (error) {
      console.error("Error fetching active proposals:", error);
      dispatch(
        fetchCompletedProposalsFailure("Error fetching active proposals.")
      );
    }
  };

export const cleanProposals = () => async (dispatch) => {
  dispatch(cleanActiveProposals());
};

export const addIdForNewProposal = (id) => async (dispatch) => {
  dispatch(addIdForNewProposalSuccess(id));
};

export const addIdForUpdateProposal =
  (id, eventRequest, visibilitymessage = false) => async (dispatch) => {
    // if (eventRequest === "requestAccepted") {
    //   dispatch(addMessageForUpdateProposal("The seller accepted the request"));
    // }

    // if (eventRequest === "cancellationRequested" && visibilitymessage) {
    //   dispatch(addMessageForUpdateProposal("Cancellation request received"));
    // }

    // if (eventRequest === "cryptoReleased") {
    //   dispatch(addMessageForUpdateProposal("The seller released crypto"));
    // }

    dispatch(addIdForUpdateProposalSuccess(id));
  };

export const addIdForDeleteProposal = (id) => async (dispatch) => {
  dispatch(addIdForDeleteProposalSuccess(id));
};

export const addIdForProposalCompleted = (id) => async (dispatch) => {
  dispatch(addIdForProposaCompletedSuccess(id));
};

export const addProposal =
  (
    id,
    activeProposals,
    address,
    ProposalsAndRequests_ADDRESS,
    DisputeResolution_ADDRESS
  ) =>
  async (dispatch) => {
    const proposal = await fetchProposal(
      id,
      address,
      ProposalsAndRequests_ADDRESS,
      DisputeResolution_ADDRESS
    );
    const newActiveProposals = [...activeProposals];
    newActiveProposals.unshift(proposal);
    dispatch(addNewProposalSucces(newActiveProposals));
  };

export const updateProposals =
  (
    updateProposalId,
    activeProposals,
    address,
    ProposalsAndRequests_ADDRESS,
    DisputeResolution_ADDRESS
  ) =>
  async (dispatch) => {
    for (let i = 0; i < updateProposalId.length; i++) {
      const proposal = await fetchProposal(
        updateProposalId[i],
        address,
        ProposalsAndRequests_ADDRESS,
        DisputeResolution_ADDRESS
      );
      const newActiveProposals = activeProposals
        .map((elment) => {
          if (elment.id === updateProposalId[i]) {
            return proposal;
          }
          return elment;
        })
        .filter((proposal) => proposal !== null);
      console.log(newActiveProposals);
      dispatch(updateActiveProposals(newActiveProposals));
    }
  };

export const deleteProposal = (id, activeProposals) => async (dispatch) => {
  const newActiveProposals = [...activeProposals].filter(
    (proposal) => proposal.id !== id
  );
  dispatch(deleteProposalSuccesSeller(newActiveProposals));
};

export const sendBuyerRequest =
  (
    proposalId,
    selectedCryptoAmount,
    publicClient,
    address,
    MainContract_ADDRESS
  ) =>
  async (dispatch) => {
    const selectedCryptoAmountValue =
      selectedCryptoAmount[proposalId] * 10 ** 6;
    try {
      const gas = await publicClient.estimateContractGas({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "requestToBuy",
        args: [proposalId, selectedCryptoAmountValue],
        account: address,
      });

      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "requestToBuy",
        args: [proposalId, selectedCryptoAmountValue],
        account: address,
        gas: gas,
      });

      dispatch(sendBuyerRequestSuccess("Request sucessfully submitted!"));
    } catch (error) {
      console.error("Error sending buyer request:", error);
      const returnError = handledError("Error sending buyer request:", error);
      dispatch(sendBuyerRequestFailure(returnError));
    }
  };

export const confirmFiatPayment =
  (
    proposalId,
    buyerRequestIndex,
    publicClient,
    address,
    MainContract_ADDRESS
  ) =>
  async (dispatch) => {
    try {
      const gas = await publicClient.estimateContractGas({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "confirmFiatPayment",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });

      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "confirmFiatPayment",
        args: [proposalId, buyerRequestIndex],
        account: address,
        gas: gas,
      });

      dispatch(confirmFiatPaymentSuccess("Payment confirmed successfully!"));
    } catch (error) {
      console.error("Error confirming payment:", error);
      const returnError = handledError("Error confirming payment:", error);
      dispatch(confirmFiatPaymentFailure(returnError));
    }
  };

export const createDispute =
  (
    proposalId,
    buyerRequestIndex,
    address,
    publicClient,
    MainContract_ADDRESS
  ) =>
  async (dispatch) => {
    try {
      const gasPrice = await fetchFeeData();

      const gas = await publicClient.estimateContractGas({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "createDispute",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });

      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "createDispute",
        args: [proposalId, buyerRequestIndex],
        account: address,
        gas: gas,
        gasPrice: gasPrice.gasPrice,
      });
      dispatch(createDisputeSuccess("Dispute created successfully!"));
    } catch (error) {
      console.error("Error creating dispute:", error);
      const returnError = handledError("Error creating dispute:", error);
      dispatch(createDisputeFailure(returnError));
    }
  };

export const requestCancellation =
  (proposalId, buyerRequestIndex, address, MainContract_ADDRESS) =>
  async (dispatch) => {
    try {
      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "requestCancellation",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });

      dispatch(
        requestCancellationSuccess("Cancellation request sent successfully!")
      );
    } catch (error) {
      console.error("Cancellation request error:", error);
      const returnError = handledError("Cancellation request error:", error);
      dispatch(requestCancellationFailure(returnError));
    }
  };

export const deactivateBuyerRequest =
  (proposalId, buyerRequestIndex, address, MainContract_ADDRESS) =>
  async (dispatch) => {
    try {
      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "deactivateBuyerRequest",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });

      dispatch(deactivateBuyerRequestSuccess("Request canceled successfully!"));
    } catch (error) {
      console.error("Cancellation request error:", error);
      const returnError = handledError("Cancellation request error:", error);
      dispatch(deactivateBuyerRequestFailure(returnError));
    }
  };

export const updateFilterProposals =
  (filterActiveProposals) => async (dispatch) => {
    dispatch(filterProposalsSuccess(filterActiveProposals));
  };

export const cleanMessage = () => async (dispatch) => {
  dispatch(cleanMessageSuccess());
};

export const cleanError = () => async (dispatch) => {
  dispatch(cleanErrorSuccess());
};
