import { useState, useEffect } from "react";
import "./USDCFaucetList.scss";
import GetUSDCButton from "../GetUSDCButton/GetUSDCButton";
import { Link } from "react-router-dom";
import SwitchChainTestUSDC from "../../SwitchChain/SwitchChainTestUSDC";
import { getAccount } from "@wagmi/core";
import { Web3Button } from "@web3modal/react";
import { useAccount } from "wagmi";

const USDCFaucet = () => {
  const { address, isConnected } = getAccount();
  const { isDisconnected } = useAccount();
  const copyToClipboard = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const [connect, SetConnect] = useState();

  useEffect(() => {
    if (isConnected) {
      SetConnect(true);
    } else {
      SetConnect(false);
    }
  }, [address, isConnected, isDisconnected]);

  return (
    <>
      <div className="USDC-faucet">
        <div className="USDC-faucet__block-top">
          <h1 className="USDC-faucet__title">
            USDC Faucet for Base Goerli, Arbitrum Goerli and Polygon Mumbai
            Networks
          </h1>
          <div className="USDC-faucet__block-rules">
            <p className="USDC-faucet__slogan">
              Get 100 USDC test tokens on Base Goerli or Abitrum Goerli network!
            </p>
            <ul className="USDC-faucet__rules">
              <li className="USDC-faucet__rules-item">
              Add USDC token address to your wallet:
              <ul className="USDC-faucet__rules">
            
                <li className="USDC-faucet__rules-item">
                Base Goerli: 0x2EE2A4d8fEBffCae849647F9ae2d64704C8fFdC7
                  <button
                    onClick={() =>
                      copyToClipboard(
                        "0x2EE2A4d8fEBffCae849647F9ae2d64704C8fFdC7"
                      )
                    }
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#ffffff",
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </li>
                <li className="USDC-faucet__rules-item">
                Arbitrum Goerli: 0x481F937e4b91a634B59A2327b17ca129fca26754
                  <button
                    onClick={() =>
                      copyToClipboard(
                        "0x481F937e4b91a634B59A2327b17ca129fca26754"
                      )
                    }
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#ffffff",
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </li>
                <li className="USDC-faucet__rules-item">
                Polygon Mumbai: 0xA285Fe78b1378c65771687BDA5F33b43A66B04c0
                  <button
                    onClick={() =>
                      copyToClipboard(
                        "0xA285Fe78b1378c65771687BDA5F33b43A66B04c0"
                      )
                    }
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#ffffff",
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </li>
              </ul>
              </li>
             
              <li className="USDC-faucet__rules-item">Connect your wallet</li>
              <li className="USDC-faucet__rules-item">
                Click "Get 100 USDC" button"
              </li>
            </ul>
          </div>
          <>
            {connect && (
              <div className="USDC-faucet__block-button">
                <SwitchChainTestUSDC />
                <div className="USDC-faucet__get-USDC_button">
                  <GetUSDCButton />
                </div>
              </div>
            )}
          </>
          {!connect && (
            <div className="USDC-faucet__block-button">
              <Web3Button />
            </div>
          )}
        </div>

        <div className="USDC-faucet__block-FAQs">
          <h2 className="USDC-faucet__title-FAQs">FAQs</h2>
          <ul className="USDC-faucet__FAQs">
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                What is the USDC Testnet Faucet?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                The USDC Testnet Faucet is a service we provide for developers
                that allows them to get free USDC tokens for testing purposes on
                <Link
                  className="USDC-faucet__FAQs-link"
                  to="https://base.org/"
                  target="_blank"
                >
                  Base Goerli
                </Link>,
                <Link
                  className="USDC-faucet__FAQs-link"
                  to="https://arbitrum.io/"
                  target="_blank"
                >
                  Abitrum Goerli
                </Link> and 
                <Link
                  className="USDC-faucet__FAQs-link"
                  to="https://polygon.technology/"
                  target="_blank"
                >
                 Polygon Mumbai 
                </Link>
                
                testnets.  The tokens are not real USDC tokens, but they behave
                exactly like the real ones.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                How do I get USDC from the faucet?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                To get USDC tokens, simply connect your web 3 wallet (e.g.
                <Link
                  className="USDC-faucet__FAQs-link"
                  to="https://metamask.io/"
                  target="_blank"
                >
                  MetaMask
                </Link>
                or
                <Link
                  className="USDC-faucet__FAQs-link"
                  to="https://www.coinbase.com/ru/wallet"
                  target="_blank"
                >
                  CoinBase Wallet
                </Link>
                ) and click the "Get 100 USDC" button. The tokens will be
                transferred to your connected wallet.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                How many USDC tokens can I get?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                You can get 100 USDC tokens for one wallet. If you need more,
                use another wallet/account.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                How often can I get USDC tokens?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                The frequency at which you can claim tokens from the faucet may
                be subject to USDC availability and network congestion. As of
                now, there's no limit on the frequency, but this may change in
                the future.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                What can I do with these USDC tokens?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                These USDC tokens are intended for testing purposes only. They
                can be used on  Base Goerli, Arbitrum Goerli and Polygon Mumbai testnets for deploying contracts,
                testing transactions, or any other activities that help you in
                developing and testing your application.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
               Can I use these USDC tokens on Ethereum mainnet or any other mainnet?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                No, these USDC tokens are test tokens and have no value on the
                main Ethereum network or any other mainnet. They only exist on
                our new chain for testing purposes.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                I clicked the "Get 100 USDC" button but didn't receive any
                tokens. What should I do?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                First, ensure that you're connected to the correct network.
                These tokens only exist on  Base Goerli, Arbitrum Goerli and Polygon Mumbai testnets, not on Ethereum
                mainnet or any other network. If you're still experiencing
                issues, please contact us with details of the problem.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                Do I need to pay for gas?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                Since this is a testnet, the gas fee is typically lower than on
                the mainnet. However, this could vary depending on the specific
                rules and conditions of  Base Goerli, Arbitrum Goerli and Polygon Mumbai testnets at any given time.
              </p>
            </li>
            <li className="USDC-faucet__FAQs-item">
              <h3 className="USDC-faucet__FAQs-question">
                I am done with my tokens, what should I do?
              </h3>
              <p className="USDC-faucet__FAQs-answer">
                If you're done testing with your tokens, you can simply ignore
                them, as they don't have any real value. There's no need to
                return them.
              </p>
            </li>
          </ul>
          <p className="USDC-faucet__prevention-text">
            Please note that the faucet is designed to assist developers in
            their work. The availability of tokens is not guaranteed, and this
            service may be modified or discontinued at any time. This service is
            brough to you as a courtesy of
            <Link
              className="USDC-faucet__FAQs-link"
              to="https://vendingmachine.studio/"
              target="_blank"
            >
              VendingMachine.Studio,
            </Link>
            the developer behind
            <Link
              className="USDC-faucet__FAQs-link"
              to="https://finja.exchange/"
              target="_blank"
            >
              Finja.Exchange.
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default USDCFaucet;
