import React, { useState } from "react";
import "./HomeRulesPhone.scss";
import classNames from "classnames";

export const HomeRulesPhone = () => {
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);
  };

  return (
    <div className="home-rules">
      <div className="home-rules__slider">
        <div className="home-rules__slider-text-container">
          <p className="home-rules__slider-text">Sell Crypto</p>
          <p className="home-rules__slider-text">Buy Crypto</p>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          step="100"
          className={classNames(
            "home-rules__slider-range home-rules__slider-range-sell",
            {
              "home-rules__slider-range-buy": sliderValue === 100,
            }
          )}
          value={sliderValue}
          onChange={handleSliderChange}
        />
      </div>
      <article className="home-rules__rules">
        {sliderValue === 0 ? (
          <ul className="home-rules__rules-item">
            <li className="home-rules__rules-rule">
              <div className="home-rules__rule-number">1</div>
              <p className="home-rules__rule-text">
                Create a proposal specifying the crypto amount and price
              </p>
            </li>
            <li className="home-rules__rules-rule">
              <div className="home-rules__rule-number">2</div>
              <p className="home-rules__rule-text">
                Accept the buyer's proposal, which deposits your crypto into an
                escrow account
              </p>
            </li>
            <li className="home-rules__rules-rule">
              <div className="home-rules__rule-number">3</div>
              <p className="home-rules__rule-text">
                Release the cryptocurrency to the buyer upon receiving the
                payment
              </p>
            </li>
          </ul>
        ) : (
          <ul className="home-rules__rules-item">
            <li className="home-rules__rules-rule">
              <div className="home-rules__rule-number">1</div>
              <p className="home-rules__rule-text">
                Browse available proposals, select one, and request to buy
              </p>
            </li>
            <li className="home-rules__rules-rule">
              <div className="home-rules__rule-number">2</div>
              <p className="home-rules__rule-text">
                Transfer fiat to the seller once your request is accepted and
                crypto is deposited in escrow. Verify the payment details in the
                "Check Payments" field
              </p>
            </li>
            <li className="home-rules__rules-rule">
              <div className="home-rules__rule-number">3</div>
              <p className="home-rules__rule-text">
                Wait for the seller to receive the payment and release the
                crypto from escrow
              </p>
            </li>
          </ul>
        )}
      </article>
    </div>
  );
};
