/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  DisputeResolution_ABI,
} from "../abis/DisputeResolution";
import { toast, ToastContainer } from "react-toastify";
import { getAccount } from "@wagmi/core";
import { useAccount } from "wagmi";
import { DisputeResolutionList } from "../components/DisputeResolution/DisputeResolutionList/DisputeResolutionList";
import PleaseConnect from "../components/PleaseConnect/PleaseConnect";
import { conversionData } from "../utils/conversionData";
import { useDispatch, useSelector } from "react-redux";
import {
  addIdForUpdateDispute,
  fetchDisputes,
  updateDisputes,
  cleanDisputes,
  getTimeActiveDispute,
  addIdForNewDispute,
  addDisputes,
  cleanMessage,
  cleanMError,
} from "../store/reducers/dispute/disputeActions";
import { watchContractEvent } from '@wagmi/core';
import SwitchChain from "../components/SwitchChain/SwitchChain";
import { eventNames } from "../data/Events";


const DisputeResolutionPage = () => {
  const { address, isConnected } = getAccount();
  const { isConnecting, isDisconnected } = useAccount();
  const [tick, setTick] = useState(false);

  const {
    disputesListFilter,
    loaderDisputeList,
    idForNewDispute,
    idForUpdateDispute,
    error,
    message,
    
  } = useSelector((state) => state.dispute);

  
  const {
    DisputeResolution_ADDRESS,
    allowedChain
  } = useSelector((state) => state.contractsAddress);

  const dispatch = useDispatch();

  useEffect(() => {
    if (address) {
      const unwatch = watchContractEvent(
        {
        address: DisputeResolution_ADDRESS,
        abi: DisputeResolution_ABI,
        },
       (logs) => {
          if (
            logs[0].eventName === eventNames.DisputeCreated ) {
              const currentDesputelID = conversionData(logs[0].args.disputeId);
            dispatch(addIdForNewDispute(currentDesputelID));
          }
          if (
            logs[0].eventName === eventNames.VoteSubmitted ||
            logs[0].eventName === eventNames.DisputeResolved ||
            logs[0].eventName === eventNames.DisputeReresolveDisputeFunctionTriggered
          ) {
            const currentDesputelID = conversionData(logs[0].args.disputeId);
            dispatch(addIdForUpdateDispute(currentDesputelID));
          }
        },
      );
      return () => unwatch?.();
    }
  }, [isConnected, isDisconnected, DisputeResolution_ADDRESS]);


  useEffect(() => {
    if (idForNewDispute && !loaderDisputeList) {
      dispatch(addDisputes(idForNewDispute, disputesListFilter, DisputeResolution_ADDRESS));
    }
  }, [idForNewDispute, loaderDisputeList]);

  useEffect(() => {
    if (idForUpdateDispute && !loaderDisputeList) {
      dispatch(updateDisputes(idForUpdateDispute, disputesListFilter, DisputeResolution_ADDRESS));
    }
  }, [idForUpdateDispute, loaderDisputeList]);

  useEffect(() => {
    if (address && DisputeResolution_ADDRESS &&  allowedChain) {
      dispatch(fetchDisputes(DisputeResolution_ADDRESS));
    } else {
      dispatch(cleanDisputes());
    }
  }, [isConnecting, isDisconnected, DisputeResolution_ADDRESS]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTick(!tick);
      dispatch(getTimeActiveDispute(disputesListFilter));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [tick]);

  useEffect(() => {
    if (message !== "") {
      showSuccessNotification(message);
      dispatch(cleanMessage());
    }
  }, [message]);

  useEffect(() => {
    if (error !== "") {
      showErrorNotification(error);
      dispatch(cleanMError());
    }
  }, [error]);

  const showSuccessNotification = (message) => {
    toast.success(message);
  };

  const showErrorNotification = (message) => {
    toast.error(message);
  };

  return (
    <>
      <SwitchChain />
      <ToastContainer />
      {!isConnected && <PleaseConnect />}
      <DisputeResolutionList />
    </>
  );
};

export default DisputeResolutionPage;
