export const chains = {
    polygon: 137,
    polygonMumbai: 80001,
    arbitrum: 42161,
    arbitrumGoerli: 421613,
    base: 84531
  }
  export const chainsNames = {
    137:"Polygon",
    80001: "Polygon Mumbai",
    42161: "Arbitrum",
    421613: "Arbitrum Goerli",
    84531: 'Base'
  } 
  export const allowedChainsEscrow = ["Polygon", "Arbitrum"];
  export const allowedChainsEscrowTest = ["Polygon Mumbai", "Arbitrum Goerli" ];
  export const allowedChainsGetTestUSDC = ["Arbitrum Goerli", "Base", "Polygon Mumbai"];
   