import React, { useEffect, useState, useRef } from "react";
import "./CreateProposal.scss";
import { toast } from "react-toastify";
import { getAccount, getPublicClient } from "@wagmi/core";
import { useAccount } from "wagmi";
import classNames from "classnames";
import FieldDescription from "../../../UI/FieldDescription/FieldDescription";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentMethods,
  createProposal,
  cleanPaymentMethods,
} from "../../../store/reducers/createProposal/createProposalActions";
import useOutsideClick from "@rooks/use-outside-click";

export const CreateProposal = () => {
  const { address } = getAccount();
  const { isConnecting, isDisconnected } = useAccount();
  const [descriptionPayment, setDescriptionPayment] = useState([]);
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [toglePaymentMetod, setToglePaymentMetod] = useState(false);
  const [paymentMethodIndex, setPaymentMethodIndex] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [discordUsername, setDiscordUsername] = useState("");
  const publicClient = getPublicClient();

  const { paymentMethods, error, message } = useSelector(
    (state) => state.createProposal
  );

  const { PaymentMethods_ADDRESS, MainContract_ADDRESS, chainsName, allowedChain } = useSelector(
    (state) => state.contractsAddress
  );

  const [errorAmount, setErrorAmount] = useState("");

  const dispatch = useDispatch();

  const nameField = {
    amount: "amount",
    price: "price",
    description: "description",
    discordUsername: "discordUsername",
    comment: "comment",
  };

  useEffect(() => {
    if (address && PaymentMethods_ADDRESS && 
      allowedChain) {
      dispatch(fetchPaymentMethods(PaymentMethods_ADDRESS));
    } else {
      dispatch(cleanPaymentMethods());
    }
  }, [isConnecting, isDisconnected, PaymentMethods_ADDRESS, chainsName]);

  useEffect(() => {
    const arrayIndex = Array(paymentMethods.length).fill(false);
    setPaymentMethodIndex(arrayIndex);
  }, [isConnecting, isDisconnected, paymentMethods]);

  useEffect(() => {
    if (error !== "") {
      showErrorNotification(error);
    }
  }, [error]);

  useEffect(() => {
    if (message !== "") {
      showSuccessNotification(message);
    }
  }, [message]);

  const showSuccessNotification = (message) => {
    toast.success(message);
  };

  const showErrorNotification = (message) => {
    toast.error(message);
  };

  const handleHover = (metod) => {
    setDescriptionPayment({
      ...descriptionPayment,
      [metod[0]]: metod[1],
    });
  };

  const handleLeave = (metod) => {
    setDescriptionPayment({
      ...descriptionPayment,
      [metod[0]]: "",
    });
  };

  const handlePayment = (metod) => {
    
    if (paymentMethodIndex[metod] === false) {
      paymentMethodIndex[metod] = metod;
    } else {
      paymentMethodIndex[metod] = false;
    }

    const cutrrentMetod = paymentMethods[metod][0];
    if (selectedPaymentMethod.includes(cutrrentMetod)) {
      const filterMetods = selectedPaymentMethod.filter(
        (element) => element !== cutrrentMetod
      );
      setSelectedPaymentMethod(filterMetods);
    } else {
      setSelectedPaymentMethod([...selectedPaymentMethod, cutrrentMetod]);
    }
  };

  const handleAmount = (event) => {
    const { value } = event.target;
    if (value < 10 && value !== "") {
      setErrorAmount("minimum amount 10");
    } else {
      setErrorAmount("");
    }
    const filteredValue = value.replace(/[^0-9.]/g, "");
    const [integerPart, decimalPart] = filteredValue.split(".");
    if (decimalPart && decimalPart.length > 2) {
      const truncatedDecimalPart = decimalPart.slice(0, 2);

      setAmount(integerPart + "." + truncatedDecimalPart);
    } else {
      setAmount(filteredValue);
    }
  };

  const handlePrice = (event) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[^0-9.]/g, "");
    const [integerPart, decimalPart] = filteredValue.split(".");
    if (decimalPart && decimalPart.length > 2) {
      const truncatedDecimalPart = decimalPart.slice(0, 2);

      setPrice(integerPart + "." + truncatedDecimalPart);
    } else {
      setPrice(filteredValue);
    }
  };

  const  onCreateProposal = () => {
 
    if(allowedChain) {
    dispatch(
      createProposal(
        address,
        price,
        amount,
        paymentMethodIndex,
        description,
        publicClient,
        comment,
        discordUsername,
        MainContract_ADDRESS
      )
    )} 
  }

  const pRef = useRef();
  function outsidePClickSort() {
    setToglePaymentMetod(false);
  }
  useOutsideClick(pRef, outsidePClickSort);

  return (
    <>
      <h1 className="title">Sell Crypto</h1>
      <div className="createProposal">
        <h2 className="createProposal__title">Create Proposal</h2>

        <ul className="createProposal__field-list">
          <li className="createProposal__field createProposal__field-amount">
            <label className="createProposal__label">Amount</label>
            <input
              className="createProposal__input"
              type="number"
              value={amount}
              onChange={(e) => handleAmount(e)}
              placeholder="0.00"
            />
            <FieldDescription nameField={nameField.amount} />
            <div className="createProposal__error">{errorAmount}</div>
          </li>

          <li className="createProposal__field createProposal__field-price">
            <label className="createProposal__label">Price</label>
            <input
              className="createProposal__input"
              type="number"
              value={price}
              onChange={handlePrice}
              placeholder="0.00"
            />
            <FieldDescription nameField={nameField.price} />
          </li>

          <li className="createProposal__field" ref={pRef}>
            <button
              className={classNames("createProposal__payment-method-button", {
                "createProposal__payment-method-button-active":
                  toglePaymentMetod,
              })}
              onClick={() => setToglePaymentMetod(!toglePaymentMetod)}
            >
            
            Select a payment method
            </button>
            {toglePaymentMetod && (
              <div className="createProposal__payment-method-dropdown">
                {paymentMethods.map((paymentMethod, index) => (
                  <div key={index} className="createProposal__dropdown">
                    <button className="createProposal__dropdown-item">
                      <input
                        className="createProposal_checkbox"
                        type="checkbox"
                        defaultChecked={paymentMethodIndex[index] !== false}
                        onChange={() => handlePayment(index)}
                      />
                      {paymentMethod[0]}
                    </button>
                    <div
                      className="createProposal__payment-method-description"
                      onMouseEnter={() => handleHover(paymentMethod)}
                      onMouseLeave={() => handleLeave(paymentMethod)}
                    >
                      {descriptionPayment[paymentMethod[0]] && (
                        <span className="createProposal__payment-method-description-item">
                          {descriptionPayment[paymentMethod[0]]}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </li>

          <li className="createProposal__field">
            <label className="createProposal__label">Payment details</label>
            <input
              className="createProposal__input "
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <FieldDescription nameField={nameField.description} />
          </li>

          <li className="createProposal__field createProposal__field-overflow-hiden">
            <label className="createProposal__label createProposal__label-nowrap">
              Discord username
            </label>
            <input
              className="createProposal__input "
              type="text"
              value={discordUsername}
              onChange={(e) => setDiscordUsername(e.target.value)}
            />
            <FieldDescription nameField={nameField.discordUsername} />
          </li>

          <li className="createProposal__field createProposal__field-overflow-hiden">
            <label className="createProposal__label createProposal__label-nowrap">
              Comment (optional)
            </label>
            <input
              className="createProposal__input "
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <FieldDescription nameField={nameField.comment} />
          </li>

          <li className="createProposal__field">
            <button
              className="createProposal__button"
              onClick={onCreateProposal}
              disabled={
                !description ||
                !amount ||
                !price ||
                !selectedPaymentMethod.length ||
                amount < 10
              }
            >
              Create
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};
