import React from "react";
import "./HomeRulesDesktop.scss";

export const HomeRulesDesktop = () => {
  return (
    <div className="home-rules-desk">
      <article className="home-rules-desk__rules">
        <ul className="home-rules-desk__rules-item">
          <h2 className="home-rules-desk__title">Sell Crypto</h2>
          <li className="home-rules-desk__rules-rule">
            <div className="home-rules-desk__rule-number">1</div>
            <p className="home-rules-desk__rule-text">
              Create a proposal specifying the crypto amount and price
            </p>
          </li>
          <li className="home-rules-desk__rules-rule">
            <div className="home-rules-desk__rule-number">2</div>
            <p className="home-rules-desk__rule-text">
              Accept the buyer's proposal, which deposits your crypto into an
              escrow account
            </p>
          </li>
          <li className="home-rules-desk__rules-rule">
            <div className="home-rules-desk__rule-number">3</div>
            <p className="home-rules-desk__rule-text">
              Release the cryptocurrency to the buyer upon receiving the payment
            </p>
          </li>
        </ul>

        <ul className="home-rules-desk__rules-item">
          <h2 className="home-rules-desk__title">Buy Crypto</h2>
          <li className="home-rules-desk__rules-rule">
            <div className="home-rules-desk__rule-number">1</div>
            <p className="home-rules-desk__rule-text">
              Browse available proposals, select one, and request to buy
            </p>
          </li>
          <li className="home-rules-desk__rules-rule">
            <div className="home-rules-desk__rule-number">2</div>
            <p className="home-rules-desk__rule-text">
              Transfer fiat to the seller once your request is accepted and
              crypto is deposited in escrow. Verify the payment details in the
              "Check Payments" field
            </p>
          </li>
          <li className="home-rules-desk__rules-rule">
            <div className="home-rules-desk__rule-number">3</div>
            <p className="home-rules-desk__rule-text">
              Wait for the seller to receive the payment and release the crypto
              from escrow
            </p>
          </li>
        </ul>
      </article>
    </div>
  );
};
