import React from "react";
import "./SellerProposal.scss";

export const SellerProposalCompleted = ({ proposal }) => {
  const copyToClipboard = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div className="proposal">
      <ul className="proposal__list-completed-transactions">
        <li className="proposal__list-item">
          <span className="proposal__lable">Proposal ID</span>
          <p className="proposal__value">{proposal.proposalID}</p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable ">Transaction amount</span>
          <p className="proposal__value proposal__value-amount">
            {proposal.amount}
          </p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Price</span>
          <p className="proposal__value">${proposal.price}</p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Seller Address</span>
          <p className="proposal__value">
            {" "}
            {proposal.seller.substring(0, 5)}
            <button
              onClick={() => copyToClipboard(proposal.seller)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "#ffffff",
              }}
            >
              <i className="fas fa-copy"></i>
            </button>
          </p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Buyer Address</span>
          <p className="proposal__value">

            {proposal.buyer.substring(0, 5)}
            <button
              onClick={() => copyToClipboard(proposal.buyer)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "#ffffff",
              }}
            >
              <i className="fas fa-copy"></i>
            </button>
          </p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Date</span>
          <p className="proposal__value">

            {new Date(proposal.date * 1000).toLocaleString()}
          </p>
        </li>
      </ul>
    </div>
  );
};
