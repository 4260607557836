import React, { useState, useEffect } from "react";
import "./SellerProposal.scss";
import checkmark_tick from "../../../assets/images/checkmark_tick.svg";
import FieldDescription from "../../../UI/FieldDescription/FieldDescription";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptBuyerRequest,
  releaseCrypto,
  createDispute,
  requestCancellationSeller,
  deactivateProposal,
} from "../../../store/reducers/sellerProposals/sellerActions";
import { getPublicClient, getAccount } from "@wagmi/core";
import { MapPaymentMethods } from "../../MapPaymentMethods/MapPaymentMethods.js";
import WarningPopup from "../../../UI/WarningPopup/WarningPopup";

export const SellerProposal = ({
  proposal,
  request,
  dataToRequests,
}) => {
  const dispatch = useDispatch();
  const { address } = getAccount();
  const publicClient = getPublicClient();
  const { MainContract_ADDRESS, USDC_ADDRESS } =
  useSelector((state) => state.contractsAddress);

  const [showLess, setShowLess] = useState(false);
  const [togleWarningPopup, setTogleWarningPopup] = useState(false);
  const [canRequestCancellation, setCanRequestCancellation] = useState(false);
  const nameField = {
    acceptRequest: "acceptRequest",
    releaseCrypto: "releaseCrypto",
    disputeSeller: "disputeSeller",
    transactionCancellationSeller: "transactionCancellationSeller",
  };


  const copyToClipboard = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const onRequestCancellation = (
    ) => {
      if (request.buyerRequestedtoCancel && request.paymentDeclared) {
        setTogleWarningPopup(true);
      } else {
        setCanRequestCancellation(true)
      }
    };

    useEffect(() => {
      if(canRequestCancellation) {
        dispatch(
          requestCancellationSeller(
            proposal.id,
            request.buyerRequestIndex,
            address,
            MainContract_ADDRESS
          )
        );
        setCanRequestCancellation(false)
      }
    }, [canRequestCancellation])
  

  return (
    <div className="proposal">
      <ul className="proposal__list proposal__list-main">
        <li className="proposal__list-item">
          <span className="proposal__lable">Proposal ID</span>
          <p className="proposal__value">{proposal.id}</p>
        </li>
        
        <li className="proposal__list-item">
          <span className="proposal__lable">Price</span>
          <p className="proposal__value">${proposal.price}</p>
        </li>

        <li className="proposal__list-item">
            <span className="proposal__lable">Amount requested</span>
            <p className="proposal__value proposal__value-amount">
              {request.amount}
            </p>
          </li>
        
        <li className="proposal__list-item">
          <span className="proposal__lable">Payment method</span>
          <MapPaymentMethods paymentMethods={proposal.paymentMethods}/>
        </li>
      </ul>
      <div className="proposal__line-phone"></div>
      <ul className="proposal__list proposal__list-other">
        <ul className="proposal__list-other-item">
          <li className="proposal__list-item">
          <span className="proposal__lable">Amount available</span>
          <p className="proposal__value proposal__value-amount">
            {proposal.amount}
          </p>
        </li>

          <li className="proposal__list-item">
            <span className="proposal__lable">Buyer Address</span>
            <p className="proposal__value">
              {request.buyer.substring(0, 5)}
              <button
                onClick={() => copyToClipboard(request.buyer)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </p>
          </li>
          <li className="proposal__list-item">
            <span className="proposal__lable"># of completed transactions</span>
            {dataToRequests && dataToRequests[request.buyerRequestIndex] && (
              <p className="proposal__value">
                {dataToRequests[request.buyerRequestIndex][1]}
              </p>
            )}
          </li>
        </ul>
        <ul className="proposal__list-other-item">
          <li className="proposal__list-item proposal__hiden-phone">
            <span className="proposal__lable">Request Accepted</span>
            <p className="proposal__value  proposal__value-icon">
              {request.accepted && (
                <img
                  className="proposal__value-icon-tick"
                  src={checkmark_tick}
                  alt="tick"
                />
              )}
            </p>
          </li>
          <li className="proposal__list-item proposal__hiden-phone">
            <span className="proposal__lable">Payment Declared</span>
            <p className="proposal__value proposal__value-icon">
              {request.paymentDeclared && (
                <img
                  className="proposal__value-icon-tick"
                  src={checkmark_tick}
                  alt="tick"
                />
              )}
            </p>
          </li>
          <li className="proposal__list-item proposal__hiden-phone">
            <span className="proposal__lable">Crypto Released</span>
            <p className="proposal__value proposal__value-icon">
              {request.transactionCompleted && (
                <img
                  className="proposal__value-icon-tick"
                  src={checkmark_tick}
                  alt="tick"
                />
              )}
            </p>
          </li>
        </ul>

        <ul className="proposal__list-item proposal__list-item-container-button">
          <li>
            {request.accepted &&
               !request.transactionCompleted && (
                <>
                  {dataToRequests &&
                    dataToRequests[request.buyerRequestIndex] &&
                    dataToRequests[request.buyerRequestIndex][0] && (
                      
                      <button
                        className="proposal__button proposal__button-dispute"
                        onClick={() =>
                          dispatch(
                            createDispute(
                              proposal.id,
                              request.buyerRequestIndex,
                              address,
                              publicClient,
                              MainContract_ADDRESS
                            )
                          )
                        }
                      >
                        <span>Dispute </span>
                        <FieldDescription nameField={nameField.disputeSeller} />
                      </button>
                    )}

                  <>
                    {request.disputeCreated && (
                      <p className="proposal__text-disput-resolved">
                        Dispute for this transaction was initiated. Do not
                        cancel, transfer crypto or fiat until the dispute is
                        resolved
                      </p>
                    )}
                  </>
                </>
              )}
          </li>
          <li>
            <>
              {request.accepted && !request.sellerRequestedtoCancel && (
                <>
                  <button
                    className="proposal__button proposal__button-cancellation"
                    disabled={request.disputeCreated}
                    onClick={() => onRequestCancellation()}
                  >
                    {request.buyerRequestedtoCancel ? ( 
                       <span>Confirm transaction cancellation</span>
                    ) : (
                       <span>Request transaction cancellation</span>
                    )}
                  
                    <FieldDescription
                      nameField={nameField.transactionCancellationSeller}
                    />
                  </button>
                  <WarningPopup
                          visibility={togleWarningPopup}
                          setVisibility={setTogleWarningPopup}
                          text={
                            "The buyer requested to cancel the transaction. Are you sure you'd like to release the crypto to the buyer?” “Reject” та “Confirm"
                          }
                          confirmFunction={setCanRequestCancellation}
                        />
                  <>
                    {request.buyerRequestedtoCancel && (
                      <p>Buyer sent request to cancel this transaction</p>
                    )}
                  </>
                </>
              )}
            </>

            <>
              {request.accepted &&
                request.sellerRequestedtoCancel &&
                !request.buyerRequestedtoCancel && (
                  <p>
                    Your cancellation request has been submitted. This
                    transaction will be canceled once the buyer confirms the
                    cancellation
                  </p>
                )}
            </>
          </li>
        </ul>
      </ul>

      <button
        className="proposal__showLess proposal__hiden-desktop"
        onClick={() => setShowLess(!showLess)}
      >
        {showLess ? <p>Show less</p> : <p>Show all</p>}
      </button>
      {showLess && (
        <>
          <ul className="proposal__list proposal__hiden-desktop">
            <li className="proposal__list-item">
              <span className="proposal__lable">Request Accepted</span>
              <p className="proposal__value  proposal__value-icon">
                {request.accepted && (
                  <img
                    className="proposal__value-icon-tick"
                    src={checkmark_tick}
                    alt="tick"
                  />
                )}
              </p>
            </li>
            <li className="proposal__list-item">
              <span className="proposal__lable">Payment Declared</span>
              <p className="proposal__value proposal__value-icon">
                {request.paymentDeclared && (
                  <img
                    className="proposal__value-icon-tick"
                    src={checkmark_tick}
                    alt="tick"
                  />
                )}
              </p>
            </li>
            <li className="proposal__list-item">
              <span className="proposal__lable">Crypto Released</span>
              <p className="proposal__value proposal__value-icon">
                {request.cryptoReleased && (
                  <img
                    className="proposal__value-icon-tick"
                    src={checkmark_tick}
                    alt="tick"
                  />
                )}
              </p>
            </li>
          </ul>
        </>
      )}
      <ul className="proposal__list-button">
        <li className="proposal__list-item">
          {!request.accepted ? (
            <button
              className="proposal__button proposal__button-accept-request"
              onClick={() =>
                dispatch(
                  acceptBuyerRequest(
                    proposal.id,
                    request.buyerRequestIndex,
                    request.amount,
                    address,
                    MainContract_ADDRESS,
                    USDC_ADDRESS
                  )
                )
              }
            >
              <span>Accept Request</span>
              <FieldDescription nameField={nameField.acceptRequest} />
            </button>
          ) : !request.paymentDeclared ? (
            <span>Waiting for the buyer to confirm fiat payment</span>
          ) : request.paymentDeclared && !request.transactionCompleted ? (
            <button
              className="proposal__button proposal__button-release-crypto"
              disabled={request.disputeCreated}
              onClick={() =>
                dispatch(
                  releaseCrypto(
                    proposal.id,
                    request.buyerRequestIndex,
                    address,
                    publicClient,
                    MainContract_ADDRESS
                  )
                )
              }
            >
              <span>Release Crypto</span>
              <FieldDescription nameField={nameField.releaseCrypto} />
            </button>
          ) : (
            <span>Transaction completed</span>
          )}
        </li>
        <li className="proposal__list-item">
          {!request.transactionCompleted && (
            <>
              {!request.accepted ? (
                <button
                  className="proposal__button"
                  onClick={() => dispatch(deactivateProposal(proposal.id, MainContract_ADDRESS))}
                >
                  Cancel proposal
                </button>
              ) : (
                <span>Request accepted already</span>
              )}
            </>
          )}
        </li>
      </ul>
    </div>
  );
};
