import {
  ProposalsAndRequests_ABI,
} from "../../../abis/ProposalsAndRequests";
import {
  DisputeResolution_ABI,
} from "../../../abis/DisputeResolution";
import { USDC_ABI } from "../../../abis/USDC";
import {
  CompletedTransactions_ABI,
} from "../../../abis/CompletedTransactions";
import {
  MainContract_ABI,
} from "../../../abis/MainContract";
import { readContract, writeContract, fetchFeeData } from "@wagmi/core";
import { conversionData } from "../../../utils/conversionData";
import { handledError } from "../../../utils/handledError";
import {
  fetchActiveProposalsSeller,
  fetchActiveProposalsSuccessSeller,
  fetchActiveProposalsSuccessAllSeller,
  fetchActiveProposalsFailureSeller,
  fetchCompletedProposalsSeller,
  fetchCompletedProposalsSuccessSeller,
  fetchCompletedProposalsSuccessAllSeller,
  fetchCompletedProposalsFailureSeller,
  cleanActiveProposalsSeller,
  addNewProposalSellerSucces,
  updateActiveProposalsSeller,
  deleteProposalSucces,
  addIdForNewProposalSellerSuccess,
  addIdForUpdateProposalSellerSuccess,
  addIdForDeleteProposalSellerSuccess,
  addIdForProposalCompletedSellerSuccess,
  // addMessageForUpdateProposalSeller,
  acceptBuyerRequestApprove,
  acceptBuyerRequestSuccess,
  acceptBuyerRequestFailure,
  releaseCryptoSuccess,
  releaseCryptoFailure,
  createDisputeSuccess,
  createDisputeFailure,
  requestCancellationSuccess,
  requestCancellationFailure,
  deactivateProposalSuccess,
  deactivateProposalFailure,
  cleanMessageSuccess,
  cleanErrorSuccess,
  addDataToRequestsSuccess,
} from "./sellerSlice";

const fetchProposalSeller = async (id, address, ProposalsAndRequests_ADDRESS) => {
  try {
    let proposals = null;
    const proposal = await readContract({
      address: ProposalsAndRequests_ADDRESS,
      abi: ProposalsAndRequests_ABI,
      functionName: "proposals",
      args: [id],
    });

    if (proposal[9] === address) {
      const requests = await readContract({
        address: ProposalsAndRequests_ADDRESS,
        abi: ProposalsAndRequests_ABI,
        functionName: "getActiveRequestsByProposal",
        args: [id],
      });

      let buyerRequests = [];

      if (requests.length) {
        for (let j = 0; j < requests.length; j++) {
          const request = requests[j];
          const amount = (conversionData(request.amount) * 10 ** -6).toFixed(2);

          buyerRequests.push({
            buyerRequestIndex: conversionData(request.buyerRequestIndex),
            proposalId: conversionData(request.proposalId),
            amount,
            date: conversionData(request.date),
            buyer: request.buyer,
            accepted: request.accepted,
            paymentDeclared: request.paymentDeclared,
            transactionCompleted: request.transactionCompleted,
            buyerRequestedtoCancel: request.buyerRequestedtoCancel,
            sellerRequestedtoCancel: request.sellerRequestedtoCancel,
            disputeCreated: request.disputeCreated,
            active: request.active,
          });
        }
      }
      const paymentMethods = proposal[4].split(",")
      const amount = (conversionData(proposal[0]) * 10 ** -6).toFixed(2);
      proposals = {
        id,
        amount,
        price: conversionData(proposal[2]) / 100,
        paymentMethods,
        description: proposal[5],
        requests: buyerRequests,
        seller: address
      };
    }
    return proposals;
  } catch (error) {
    console.error("Error fetching active proposals:", error);
  }
};

export const addDataToRequests = (
  proposals, 
  DisputeResolution_ADDRESS, 
  CompletedTransactions_ADDRESS) => async (dispatch) => {
  let dataObjects = {};
  let object = {};

  for (let i = 0; i < proposals.length; i++) {
    if (proposals[i].requests) {
      for (let j = 0; j < proposals[i].requests.length; j++) {
        try{
        const createDisput = await readContract({
          address: DisputeResolution_ADDRESS,
          abi: DisputeResolution_ABI,
          functionName: "canCreateDispute",
          args: [proposals[i].id, proposals[i].requests[j].buyerRequestIndex],
        });

        const transactionsCompletedSeller = await readContract({
          address: CompletedTransactions_ADDRESS,
          abi: CompletedTransactions_ABI,
          functionName: "sellerTransactionCounts",
          args: [proposals[i].requests[j].buyer],
        });

        const transactionsCompletedBuyer = await readContract({
          address: CompletedTransactions_ADDRESS,
          abi: CompletedTransactions_ABI,
          functionName: "buyerTransactionCounts",
          args: [proposals[i].requests[j].buyer],
        });

        const transactionsCountByWallet =
          conversionData(transactionsCompletedSeller) +
          conversionData(transactionsCompletedBuyer);

        object = {
          ...object,
          [proposals[i].requests[j].buyerRequestIndex]: [
            createDisput,
            transactionsCountByWallet,
          ],
        };
        dataObjects = { ...dataObjects, [proposals[i].id]: object };
      } catch (error) {
console.error(error);
      }
      }
    }
  }
  dispatch(addDataToRequestsSuccess(dataObjects));
};

export const fetchProposalsSeller = (address, ProposalsAndRequests_ADDRESS) => async (dispatch) => {
  if(!address) return;
  
  dispatch(fetchActiveProposalsSeller());
  try {
    const proposalIdActive = await readContract({
      address: ProposalsAndRequests_ADDRESS,
      abi: ProposalsAndRequests_ABI,
      functionName: "getActiveProposalIds",
    });

    for (let i = proposalIdActive.length - 1; i >= 0; i--) {
      const currentId = conversionData(proposalIdActive[i]);
      const proposal = await fetchProposalSeller(currentId, address, ProposalsAndRequests_ADDRESS);

      if (proposal) {
        dispatch(fetchActiveProposalsSuccessSeller(proposal));
      }
    }
    dispatch(fetchActiveProposalsSuccessAllSeller());
  } catch (error) {
    console.error("Error fetching active proposals:", error);
    dispatch(
      fetchActiveProposalsFailureSeller("Error fetching active proposals.")
    );
  }
};

export const fetchProposalsSellerCompleted = (address, CompletedTransactions_ADDRESS) => async (dispatch) => {
  if(!address) return;
  dispatch(fetchCompletedProposalsSeller());

  try {
    const transactionscompletedSeller = await readContract({
      address: CompletedTransactions_ADDRESS,
      abi: CompletedTransactions_ABI,
      functionName: "sellerTransactionCounts",
      args: [address],
    });

    for (let j = conversionData(transactionscompletedSeller) - 1; j >= 0; j--) {
      const completedTransaction = await readContract({
        address: CompletedTransactions_ADDRESS,
        abi: CompletedTransactions_ABI,
        functionName: "sellerCompletedTransactions",
        args: [address, j],
      });

      const proposal = {
        date: conversionData(completedTransaction[0]),
        buyer: completedTransaction[1],
        seller: completedTransaction[2],
        amount: (conversionData(completedTransaction[3]) * 10 ** -6).toFixed(),
        price: conversionData(completedTransaction[4]) / 100,
        proposalID: conversionData(completedTransaction[5]),
        buyerRequestIndex: conversionData(completedTransaction[6]),
      };

      dispatch(fetchCompletedProposalsSuccessSeller(proposal));
    }
    dispatch(fetchCompletedProposalsSuccessAllSeller());
  } catch (error) {
    console.error("Error fetching completed proposals:", error);
    dispatch(
      fetchCompletedProposalsFailureSeller(
        "Error fetching completed proposals."
      )
    );
  }
};

export const cleanProposalsSeller = () => async (dispatch) => {
  dispatch(cleanActiveProposalsSeller());
};

export const addIdForNewProposalSeller = (id) => async (dispatch) => {
  dispatch(addIdForNewProposalSellerSuccess(id));
};

export const addIdForUpdateProposalSeller = (id, event, visibilitymessage = false) => async (dispatch) => {
  // if(event === "newBuyerRequest") {
  //   dispatch(addMessageForUpdateProposalSeller("A buyer has created a request for your proposal"));
  // }
  // if(event === "paymentConfirmed") {
  //   dispatch(addMessageForUpdateProposalSeller( "The buyer confirmed the payment"));

  // }
  // if(event === "cancellationRequested"  && visibilitymessage) {
  //   dispatch(addMessageForUpdateProposalSeller( "Cancellation request received"
  //   ));

  // }
  // if(event === "buyerRequestDeactivated") {
  //   dispatch(addMessageForUpdateProposalSeller("The buyer deactivated the request"));
  // }
  dispatch(addIdForUpdateProposalSellerSuccess(id));
};

export const addIdForDeleteProposalSeller = (id) => async (dispatch) => {
  dispatch(addIdForDeleteProposalSellerSuccess(id));
};

export const addIdForProposalCompletedSeller = (id) => async (dispatch) => {
  dispatch(addIdForProposalCompletedSellerSuccess(id));
};

export const addProposal =
  (id, activeProposals, address, ProposalsAndRequests_ADDRESS) => async (dispatch) => {
    const proposal = await fetchProposalSeller(id, address, ProposalsAndRequests_ADDRESS);
    const newActiveProposals = [...activeProposals];
    newActiveProposals.unshift(proposal);
    dispatch(addNewProposalSellerSucces(newActiveProposals));
  };

export const updateProposalsSeller =
  (updateProposalId, activeProposals, address, ProposalsAndRequests_ADDRESS) => async (dispatch) => {
    for (let i = 0; i < updateProposalId.length; i++) {
      const proposal = await fetchProposalSeller(updateProposalId[i], address, ProposalsAndRequests_ADDRESS);
      const newActiveProposals = activeProposals
        .map((elment) => {
          if (elment.id === updateProposalId[i]) {
                return proposal;
          }
          return elment;
        })
        .filter((proposal) => proposal !== null);
      dispatch(updateActiveProposalsSeller(newActiveProposals));
    }
  };

export const deleteProposal = (id, activeProposals) => async (dispatch) => {
  const newActiveProposals = [...activeProposals].filter(
    (proposal) => proposal.id !== id
  );
  dispatch(deleteProposalSucces(newActiveProposals));
};

export const acceptBuyerRequest =
  (proposalId, buyerRequestIndex, amount, address, MainContract_ADDRESS, USDC_ADDRESS) => async (dispatch) => {
    try {
      await writeContract({
        address: USDC_ADDRESS,
        abi: USDC_ABI,
        functionName: "approve",
        args: [MainContract_ADDRESS, amount * 10 ** 6],
        account: address,
      });
    } catch (error) {
      console.error("Error accepting buyer request:", error);
      const returnError = handledError("Error accepting buyer request:", error);
      dispatch(acceptBuyerRequestApprove(returnError));
    }

    setTimeout(async () => {
      try {
        await writeContract({
          address: MainContract_ADDRESS,
          abi: MainContract_ABI,
          functionName: "acceptBuyerRequest",
          args: [proposalId, buyerRequestIndex],
          account: address,
        });
        dispatch(
          acceptBuyerRequestSuccess("Buyer request accepted successfully!")
        );
      } catch (error) {
        console.error("Error accepting buyer request:", error);
        const returnError = handledError(
          "Error accepting buyer request:",
          error
        );
        dispatch(acceptBuyerRequestFailure(returnError));
      }
    }, 5000);
  };

export const releaseCrypto =
  (proposalId, buyerRequestIndex, address, publicClient, MainContract_ADDRESS) =>
  async (dispatch) => {
    try {
      const gas = await publicClient.estimateContractGas({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "releaseCrypto",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });

      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "releaseCrypto",
        args: [proposalId, buyerRequestIndex],
        account: address,
        gas: gas,
      });

      dispatch(releaseCryptoSuccess("Crypto released successfully!"));
    } catch (error) {
      console.error("Error releasing crypto:", error);
      const returnError = handledError("Error releasing crypto:", error);
      dispatch(releaseCryptoFailure(returnError));
    }
  };

export const createDispute =
  (proposalId, buyerRequestIndex, address, publicClient, MainContract_ADDRESS) =>
  async (dispatch) => {
    try {
      const gasPrice = await fetchFeeData();

      const gas = await publicClient.estimateContractGas({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "createDispute",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });

      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "createDispute",
        args: [proposalId, buyerRequestIndex],
        account: address,
        gas: gas,
        gasPrice: gasPrice.gasPrice,
      });
      dispatch(createDisputeSuccess("Dispute created successfully!"));
    } catch (error) {
      console.error("Error creating dispute:", error);
      const returnError = handledError("Error creating dispute:", error);
      dispatch(createDisputeFailure(returnError));
    }
  };

export const requestCancellationSeller =
  (proposalId, buyerRequestIndex, address, MainContract_ADDRESS) => async (dispatch) => {
    console.log("proposalId", proposalId, buyerRequestIndex, address);
    try {
      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "requestCancellation",
        args: [proposalId, buyerRequestIndex],
        account: address,
      });
      dispatch(
        requestCancellationSuccess("Cancellation request sent successfully!")
      );
    } catch (error) {
      console.error("Error request sending:", error);
      const returnError = handledError("Cancellation request error:", error);
      dispatch(requestCancellationFailure(returnError));
    }
  };

export const deactivateProposal = (proposalId, MainContract_ADDRESS) => async (dispatch) => {
  try {
    await writeContract({
      address: MainContract_ADDRESS,
      abi: MainContract_ABI,
      functionName: "deactivateProposal",
      args: [proposalId],
    });

    dispatch(deactivateProposalSuccess("Proposal cancelled sucessfully!"));
  } catch (error) {
    console.error("Error canceling proposal:", error);
    const returnError = handledError("Error canceling proposal:", error);
    dispatch(deactivateProposalFailure(returnError));
  }
};

export const cleanMessage = () => async (dispatch) => {
  dispatch(cleanMessageSuccess());
};

export const cleanError = () => async (dispatch) => {
  dispatch(cleanErrorSuccess());
};
