import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import { Web3Button } from "@web3modal/react";
import { useState } from "react";
import classNames from "classnames";
import discord_logo from "../../assets/images/discord_logo.svg";
import twitter_logo from "../../assets/images/twitter_logo.svg";
import finja_Logo from "../../assets/images/Finja_Logo.svg";
import polygon from "../../assets/images/Powered_by_Polygon.svg";
import github_logo from "../../assets/images/github_logo.svg";

const Header = ({setTogleSellerBuyer}) => {
  const [togleMenu, settogleMenu] = useState(false);


  const handleMenu = () => {
    settogleMenu(!togleMenu);
  };

  const clouseMenu = () => {
    settogleMenu(false);
  };

  const onSeller = () => {
    clouseMenu();
    setTogleSellerBuyer("seller")
  };

  const  onBuyer = () => {
    setTogleSellerBuyer("buyer")
    clouseMenu();
  };


  return (
    <>
      <div className="header" >
        <div className="header__phone">
          <div className="header__logo">
            <Link to="/">
              <img
                className="header__logo-img"
                src={finja_Logo}
                alt="discord_logo"
              />
             
            </Link>
            <img
              className="header__logo-img-polygon"
              src={polygon}
              alt="polygon"
            />
           
          </div>

          <Web3Button />
          <button className="header__menu-button" onClick={handleMenu}>
            <span
              className={classNames("header__menu-button-item", {
                "header__menu-button-item-active-top": togleMenu,
              })}
            ></span>
            <span
              className={classNames("header__menu-button-item", {
                "header__menu-button-item-active-bottom": togleMenu,
              })}
            ></span>
          </button>

          {togleMenu && (
            <nav className="header__menu-phone">
              <ul className="header__menu-phone-list">
                <ul className="header__menu-phone-list-cripto">
                  <ul className="header__menu-phone-list-cripto-container">
                    <li className="header__menu-phone-link header__menu-phone-link-cripto">
                      <button
                        className="header__menu-phone-link-button"
                        onClick={onSeller}
                      >
                        <Link to="/escrow">Sell Crypto</Link>
                      </button>
                    </li>

                    <li className="header__menu-phone-link header__menu-phone-link-cripto">
                      <button
                        className="header__menu-phone-link-button"
                        onClick={onBuyer}
                      >
                        <Link to="/escrow">Buy Crypto</Link>
                      </button>
                    </li>
                  </ul>
                  <li className="header__menu-phone-link">
                    <button
                      className="header__menu-phone-link-button"
                      onClick={clouseMenu}
                    >
                      <Link to="/token-governance">Tokens and Governancе</Link>
                    </button>
                  </li>
                  <li className="header__menu-phone-link">
                    <button
                      className="header__menu-phone-link-button"
                      onClick={clouseMenu}
                    >
                      <Link to="https://blog.finja.exchange/">Blog</Link>
                    </button>
                  </li>

                  <li className="header__menu-phone-link">
                    <button
                      className="header__menu-phone-link-button"
                      onClick={clouseMenu}
                    >
                      <Link to="/dispute-resolution">Dispute Resolution</Link>
                    </button>
                  </li>
                </ul>
                <ul className="header__menu-phone-list-social">
                  <li className="header__menu-phone-link">
                    <button
                      className="header__menu-phone-link-button"
                      onClick={clouseMenu}
                    >
                      <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">
                        <img
                          className="header__menu-phone-img-discord"
                          src={discord_logo}
                          alt="discord_logo"
                        />
                      </Link>{" "}
                    </button>
                  </li>
                  <li className="header__menu-phone-link">
                    <button
                      className="header__menu-phone-link-button"
                      onClick={clouseMenu}
                    >
                      <Link to="https://github.com/Finja-Exchange" target="_blank">
                        <img
                          className="header__menu-phone-img-discord"
                          src={github_logo}
                          alt="discord_logo"
                        />
                      </Link>{" "}
                    </button>
                  </li>
                  <li className="header__menu-phone-link">
                    <button
                      className="header__menu-phone-link-button"
                      onClick={clouseMenu}
                    >
                      <Link
                        to="https://twitter.com/VMachineStudio"
                        target="_blank"
                      >
                        <img
                          className="header__menu-phone-img-twitter"
                          src={twitter_logo}
                          alt="twitter_logo"
                        />
                      
                      </Link>{" "}
                    </button>
                  </li>
                </ul>
              </ul>
            </nav>
          )}
        </div>
      </div>
      <div className="header__desktop">
        <nav className="header__menu-desktop">
          <ul className="header__menu-desktop-item">
            <li className="header__logo">
              <Link to="/">
                <img
                  className="header__logo-img"
                  src={finja_Logo}
                  alt="finja_Logo"
                />

                <img
                  className="header__logo-img-polygon"
                  src={polygon}
                  alt="polygon"
                />
              </Link>
            </li>
          </ul>
          <ul className="header__menu-desktop-item">
            <li className="header__menu-desktop-link">
              <button 
               className="header__menu-phone-link-button"
                onClick={() => setTogleSellerBuyer("seller")}>
              <Link to="/escrow">Sell Crypto</Link>
              </button>
              
            </li>
            <li className="header__menu-desktop-link">
            <button
              className="header__menu-phone-link-button" 
              onClick={() => setTogleSellerBuyer("buyer")}>
              <Link to="/escrow">Buy Crypto</Link>
              </button>
            </li>
          </ul>
          <ul className="header__menu-desktop-item">
            <li className="header__menu-desktop-link">
              <Link to="/token-governance">Tokens and Governance</Link>
            </li>

            <li className="header__menu-desktop-link">
              <Link to="https://blog.finja.exchange/">Blog</Link>
            </li>

            <li className="header__menu-desktop-link">
              <Link to="/dispute-resolution">Dispute Resolution</Link>
            </li>
            <li className="header__menu-desktop-link">
              <Web3Button />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
export default Header;