import "./PleaseConnect.scss";
import finja from "../../assets/images/Finja.png";
import { Web3Button } from "@web3modal/react";
import metaMask from "../../assets/images/MetaMask.png";
import { Link } from "react-router-dom";

const PleaseConnect = () => {
  return (
    <div className="please-connect">
      <div className="please-connect__container">
      <img className="please-connect__finja" src={finja} alt="finja" />
        <div className="please-connect__content">
        
         
            <h1 className="please-connect__title">
              Please connect your web3 wallet
            </h1>
            <div className="please-connect__button-connect">
              <Web3Button />
            </div>
            <div>
            <p className="please-connect__text">Don't have a web3 wallet? </p>
            <p className="please-connect__text">Download <Link to="https://metamask.io/"  target="_blank">
            <img className="please-connect__metaMask" src={metaMask} alt="metaMask" />
            </Link></p>
            </div>
          </div>
        </div>
      </div>
 
  );
};

export default PleaseConnect;
