import React, { useEffect, useState } from "react";
import "./SwitchChain.scss";
import { switchNetwork } from "@wagmi/core";
import { getAccount } from "@wagmi/core";
import down_button from "../../assets/images/down_button.svg";
import up_button from "../../assets/images/up_button.svg";
import matic_logo from "../../assets/images/matic_logo.svg";
import arbitrum_logo from "../../assets/images/arbitrum-logo.svg";
import base_logo from "../../assets/images/base-logo.svg";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import { chains, chainsNames } from "../../data/Chains";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { allowedChainsGetTestUSDC } from "../../data/Chains";

const SwitchChainTestUSDC = () => {
  const [togleNetwork, setTogleNetwork] = useState(false);
  const { isConnected } = getAccount();
  const { isDisconnected } = useAccount();
  const { chainsName } = useSelector((state) => state.contractsAddress);
  const [cryptoLogo, setCryptoLogo] = useState(null);
  const [allowedChains, setAllowedChains] = useState(false);
  const showSuccessNotification = (message) => {
    toast.success(message);
  };
  const showErrorNotification = (message) => {
    toast.error(message);
  };

  useEffect(() => {
    if (!isConnected) return;
    const aloved = allowedChainsGetTestUSDC.includes(chainsName);
    setAllowedChains(aloved);

    switch (chainsName) {
      case chainsNames[80001]:
        setCryptoLogo(matic_logo);
        break;
      case chainsNames[421613]:
        setCryptoLogo(arbitrum_logo);
        break;
      case chainsNames[84531]:
        setCryptoLogo(base_logo);
        break;
      default:
        setCryptoLogo(null);
    }
  }, [chainsName, useLocation, isConnected, isDisconnected]);

  const switchChain = async (id) => {
    try {
      await switchNetwork({
        chainId: id,
      });
      setTogleNetwork(false);
      showSuccessNotification("Network successfully changed");
    } catch (error) {
      console.error(error);
      showErrorNotification("Network changes error");
    }
  };

  return (
    <div className="switch-chain">
      <button
        className="switch-chain__select-network"
        onClick={() => setTogleNetwork(!togleNetwork)}
      >
        {cryptoLogo && (
          <img
            className="switch-chain__select-network-logo"
            src={cryptoLogo}
            alt="matic"
          />
        )}
        {chainsName && allowedChains ? (
          <p> {chainsName}</p>
        ) : (
          <p>Select an available network </p>
        )}
        {togleNetwork ? (
          <img src={down_button} alt="" />
        ) : (
          <img src={up_button} alt="" />
        )}
      </button>
      {togleNetwork && (
        <div className="switch-chain__block-networks">
          <button
            className="switch-chain__network"
            onClick={() => switchChain(chains.polygonMumbai)}
          >
            <img
              className="switch-chain__network-logo"
              src={matic_logo}
              alt="matic"
            />
            <p>Polygon Mumbai</p>
          </button>

          <button
            className="switch-chain__network"
            onClick={() => switchChain(chains.arbitrumGoerli)}
          >
            <img
              className="switch-chain__network-logo"
              src={arbitrum_logo}
              alt="matic"
            />
            <p>Arbitrum Goerli</p>
          </button>

          <button
            className="switch-chain__network"
            onClick={() => switchChain(chains.base)}
          >
            <img
              className="switch-chain__network-logo"
              src={base_logo}
              alt="matic"
            />
            <p>Base</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default SwitchChainTestUSDC;
