import React, { useEffect} from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAccount } from "@wagmi/core";
import { BuyerProposalsList } from "./BuyerProposalsList/BuyerProposalsList";
import PleaseConnect from "../PleaseConnect/PleaseConnect";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProposals,
  cleanMessage,
  cleanError,
  fetchProposalsCompleted,
  addProposal,
  deleteProposal,
} from "../../store/reducers/buyerProposals/buyerActions";

const EscrowBuyer = () => {
  const { address, isConnected } = getAccount();

  const {
    activeProposalsFilter,
    loaderActiveProposals,
    error,
    message,
    idForNewProposal,
    idForUpdateProposal,
    idForDeleteProposal,
    idForUpdateProposalCompleted,
  } = useSelector((state) => state.buyer);

  const {
    DisputeResolution_ADDRESS,
    ProposalsAndRequests_ADDRESS,
    CompletedTransactions_ADDRESS
  } = useSelector((state) => state.contractsAddress);

  const dispatch = useDispatch();

  useEffect(() => {
    if (idForNewProposal && !loaderActiveProposals) {
      dispatch(addProposal(
        idForNewProposal, 
        activeProposalsFilter, 
        address, 
        ProposalsAndRequests_ADDRESS, 
        DisputeResolution_ADDRESS
      ));
    }
  }, [idForNewProposal]);

  useEffect(() => {
    if (idForUpdateProposal.length && !loaderActiveProposals) {
      dispatch(
        updateProposals(
          idForUpdateProposal, 
          activeProposalsFilter, 
          address, 
          ProposalsAndRequests_ADDRESS, 
          DisputeResolution_ADDRESS
        )
      );
    }
  }, [idForUpdateProposal, ProposalsAndRequests_ADDRESS]);

  useEffect(() => {
    if (idForDeleteProposal && !loaderActiveProposals) {
      dispatch(deleteProposal(idForDeleteProposal, activeProposalsFilter));
    }
  }, [idForDeleteProposal]);


  useEffect(() => {
    if (idForUpdateProposalCompleted.length && !loaderActiveProposals ) {
      dispatch(fetchProposalsCompleted(address, CompletedTransactions_ADDRESS));

    }
  }, [idForUpdateProposalCompleted]);

  useEffect(() => {
    if (message !== "") {
      showSuccessNotification(message);
      dispatch(cleanMessage());
    }
  }, [message]);

  useEffect(() => {
    if (error !== "") {
      showErrorNotification(error);
      dispatch(cleanError());
    }
  }, [error]);

  
  const showSuccessNotification = (message) => {
    toast.success(message);
  };

  const showErrorNotification = (message) => {
    toast.error(message);
  };

  return (
    <div>
      <ToastContainer />
      {!isConnected && <PleaseConnect />}
      <BuyerProposalsList />
    </div>
  );
};

export default EscrowBuyer;
