import React from "react";
import { Link } from "react-router-dom";
import "./HomeList.scss";
import discord_logo from "../../../assets/images/discord_logo.svg";
import twitter_logo from "../../../assets/images/twitter_logo.svg";
import github_logo from "../../../assets/images/github_logo.svg";
import finja from "../../../assets/images/Finja.png";
import { HomeRulesPhone } from "../../../components/Home/HomeRulesPhone/HomeRulesPhone";
import { HomeRulesDesktop } from "../../../components/Home/HomeRulesDesktop/HomeRulesDesktop";
import { FAQList } from "../../FAQ/FAQList";
import usdc from "../../../assets/images/USDT.svg";

const HomeList = () => {
  return (
    <div className="home">
      <div className="home__main">
        <div>
        <h1 className="home__title">
          Decentralized Bridge from Centralized Finance
        </h1>
        <p className="home__tagline">
          Buy and sell <img className="home__usdc" src={usdc} alt="usdc"/>USDC via P2P transactions using your web3 wallet, without entrusting your crypto to anyone or sharing your personal data
        </p>
        </div>
       
        <ul className="home__social">
          <li className="home__social-item">
            <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">
              <img
                className="home__social-img"
                src={discord_logo}
                alt="discord_logo"
              />
            </Link>
          </li>
          <li className="home__social-item">
            <Link to="https://github.com/Finja-Exchange" target="_blank">
              <img
                className="home__social-img"
                src={github_logo}
                alt="github_logo"
              />
            </Link>
          </li>
          <li className="home__social-item">
            <Link to="https://twitter.com/VMachineStudio" target="_blank">
              <img
                className="home__social-img"
                src={twitter_logo}
                alt="twitter_logo"
              />
            </Link>
          </li>
        </ul>
      </div>
      <section className="home__about">
        <div className="home__advantage-container">
          <div className="home__finja">
            <img className="home__finja-img" src={finja} alt="finja"></img>
          </div>
          <ul className="home__advantage">
            <li className="home__advantage-item">
              <h2 className="home__advantage-title">Safe</h2>
              <p className="home__advantage-text">
              Transact with a user-controlled wallet, never entrusting crypto or personal data to a centralized exchange
              </p>
            </li>
            <li className="home__advantage-item">
              <h2 className="home__advantage-title">Anonymous</h2>
              <p className="home__advantage-text">
              No personal data, emails, or phone numbers required
              </p>
            </li>
          </ul>
          <ul className="home__advantage">
            <li className="home__advantage-item">
              <h2 className="home__advantage-title">Permissionless</h2>
              <p className="home__advantage-text">No central authority, no registration needed</p>
            </li>
            <li className="home__advantage-item">
              <h2 className="home__advantage-title"> Web3 Native</h2>
              <p className="home__advantage-text">
                No back-end, no databases, powered solely by smart contracts
              </p>
            </li>
          </ul>
          <ul className="home__advantage">
            <li className="home__advantage-item">
              <h2 className="home__advantage-title">Community-Governed</h2>
              <p className="home__advantage-text">
                PoS mechanism for voting and decentralized governance
              </p>
            </li>
            <li className="home__advantage-item">
              <h2 className="home__advantage-title">Immutable</h2>
              <p className="home__advantage-text">
                Non-upgradeable and unchangeable contracts, ensuring reliable
                use and predictable outcome
              </p>
            </li>
          </ul>
        </div>

        <HomeRulesPhone />
        <HomeRulesDesktop />

        <article className="home__discord">
          <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">
            <img
              className="home__social-img"
              src={discord_logo}
              alt="discord_logo"
            />
          </Link>
          <p className="home__discord-text">
            In case of disputes, click the "Create Dispute" button and present
            your evidence on
            <Link
              className="home__discord-link"
              to="https://discord.gg/4rfVXCmnBH"
              target="_blank"
            >
              Discord
            </Link>
          </p>
        </article>
        <div className="home__FAQ">
          <FAQList />
        </div>
      </section>
    </div>
  );
};

export default HomeList;
