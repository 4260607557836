import React, { useState } from "react";
import "./MapPaymentMethods.scss";


export const MapPaymentMethods = ({ paymentMethods }) => {
  const [togleAll, setTogleAll] = useState(false);
  const lengthMetods = paymentMethods.length - 4;

  return (
    <div className="payment-methods">
     
      <>
        {paymentMethods.map((method, index) => {
          if (index < 4) {
            return (
              <div className="payment-methods__metod" key={index}>
                {method}
              </div>
            );
          }
          return null;
        })}
      </>
      {lengthMetods > 0 && (
        <>
          {togleAll && (
            <>
              {paymentMethods.map((method, index) => {
                if (index >= 4) {
                  return (
                    <div className="payment-methods__metod" key={index*2}>
                      {method}
                    </div>
                  );
                }
                return null;
              })}
            </>
          )}
          <button
            className="payment-methods__button"
            onClick={() => setTogleAll(!togleAll)}
          >
            {!togleAll ? <>{lengthMetods}+</> : <>{lengthMetods}-</>}
          </button>
        </>
      )}
    </div>
  );
};
