export const descriptionInputText = {
  amount: "Enter the quantity of USDC you wish to sell",
  price:
    "Specify the price in USD you wish to receive for each unit of USDC you're selling",
  description: `Enter preferred payment details such as @revtag, IBAN, card number, or email. 
      Only a hashed version will be stored for future reference, not the actual details. 
      Make a note of this information, as you'll need to provide it to the Buyer later.`,
  discordUsername: `Input your Discord username here. 
      This will serve as a contact point for potential buyers and will be publicly displayed.`,
  comment: `Input any remarks or additional instructions for potential buyers in this field. 
      You may include details such as your flexibility with pricing, alternate modes of contact, etc. 
      Remember, this information will be visible to all potential buyers.`,
  acceptRequest: `By accepting the request, your crypto will be moved into an escrow account. 
    Two confirmations from your wallet will be required: one for authorizing the spending of USDC, 
    and another for approving the transaction itself.`,
  releaseCrypto: `Only release the crypto after you have received the fiat payment. 
      Please note that this action is irreversible`,
  disputeSeller: `Initiate a dispute if the Buyer hasn't sent the fiat payment within the agreed-upon timeframe.`,
  transactionCancellationSeller: `The transaction will be cancelled if both the Buyer and the Seller mutually agree to cancel it.`,
  requestToBuy: `Initiate your transaction here. 
    Input the amount of USDC you wish to purchase and submit your request to the seller. 
    Please note that the seller will need to confirm your request`,
  confirmFiaPayment: `By clicking this button, you confirm that you have dispatched the fiat payment to the seller. Please ensure that you have checked the payment details in the form below before proceeding with the transaction.`,
  disputeBuyer: `Initiate a dispute if the Seller hasn't released the crypto within the agreed-upon timeframe`,
  transactionCancellationBuyer: `The transaction will be cancelled if both the Buyer and the Seller mutually agree to cancel it.`,
  enterPaymentDetails: `Please request the seller's payment details and verify them in this field. This step is critical in order to prevent errors or fraudulent activities. Keep in mind that the payment details will be cross-referenced with the hash stored at the time the seller created the proposal.`,
  enterCryptoAmount: `Begin here! Input the amount of cryptocurrency you wish to buy. This action will generate a request to the seller. The seller must confirm this request before you proceed to transfer your fiat payment.`,
  resolveDispute: `After the voting period concludes, this button can be clicked to process the results. If a winner is declared, the escrowed amount will be transferred to them. Supporters of the losing side will forfeit 10% of their tokens, which will be subsequently redistributed among the winning side's supporters.`
};
