import "./TokenAndGovernanceList.scss";
import { Link } from "react-router-dom";

const TokenAndGovernanceList = () => {
  return (
    <div className="token-governance">
      <h2 className="token-governance__title">Finja Token</h2>
      <div className="token-governance__rules-container">
        <p className="token-governance__rules-title">
          Our project utilizes FinjaTokens ($FNJ) with three key objectives:
        </p>
        <ul className="token-governance__rules">
          <li className="token-governance__rules-item">
            <div className="token-governance__rule-number">1</div>
            <p className="token-governance__rule-text">
              Staking tokens for voting in dispute resolutions: Stakeholders can
              support a buyer's or a seller's stance by staking their tokens.
            </p>
          </li>
          <li className="token-governance__rules-item">
            <div className="token-governance__rule-number">2</div>
            <p className="token-governance__rule-text">
              Earning a commission income (this feature is under development): Token holders earn a commission
              income, proportional to their share of the total tokens in
              circulation.
            </p>
          </li>
          <li className="token-governance__rules-item">
            <div className="token-governance__rule-number">3</div>
            <p className="token-governance__rule-text">
              Participating in project governance (this feature is under development): Token holders have a say in
              the ongoing development and future direction of our project.
            </p>
          </li>
          <li className="token-governance__rules-item">
            Explore Finja token on 
            <Link 
              className="token-governance__link" 
              to="https://polygonscan.com/token/0x5f57Cb9c975703364fdd7444dD426aFc11aaC5b3"
              target="_blank">
              PolygonScan
            </Link>
          </li>
        </ul>
      </div>

      <div className="token-governance__infornations-container">
        <div className="token-governance__infornations">
          <h2 className="token-governance__infornations-title">Token Supply</h2>
          <p className="token-governance__infornations-text">
            Initially, 10,000,000 tokens have been issued and distributed among
            the development team. Upon each successful transaction, both the
            buyer and the seller are rewarded with tokens equivalent to the
            transaction amount in USDC.
          </p>
          <p className="token-governance__infornations-text">
            At present, we do not offer the option to directly purchase tokens.
            Our aim is to ensure a wide and fair distribution of tokens among
            our community of users. Token holders are entitled to a share of the
            commission, proportional to their individual token holding as a
            percentage of the total token supply.
          </p>
        </div>

        <div className="token-governance__infornations">
          <h2 className="token-governance__infornations-title">Governance</h2>
          <p className="token-governance__infornations-text">
            The governance process is currently under development. In the
            meantime, we invite you to join our Discord server to discuss any
            ideas or suggestions you might have. We look forward to your active
            participation in shaping the future of our project.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TokenAndGovernanceList;
