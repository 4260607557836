import {
  MainContract_ABI,
} from "../../../abis/MainContract";
import {
  PaymentMethods_ABI,
} from "../../../abis/PaymentMethods";
import { readContract, writeContract } from "@wagmi/core";
import { conversionData } from "../../../utils/conversionData";
import { handledError } from "../../../utils/handledError";

import {
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsFailure,
  createProposalSuccess,
  createProposalFailure,
  cleanPaymentMethodsSuccess,
} from "./createProposalSlice";

export const fetchPaymentMethods = (PaymentMethods_ADDRESS) => async (dispatch) => {
  try {
    const paymentMethodCount = await readContract({
      address: PaymentMethods_ADDRESS,
      abi: PaymentMethods_ABI,
      functionName: "getPaymentMethodCount",
    });

    const fetchedPaymentMethods = [];
    for (let i = 0; i < conversionData(paymentMethodCount); i++) {
      const paymentMethod = await readContract({
        address: PaymentMethods_ADDRESS,
        abi: PaymentMethods_ABI,
        functionName: "paymentMethods",
        args: [i],
      });

      fetchedPaymentMethods.push(paymentMethod);
    }
    dispatch(fetchPaymentMethodsSuccess(fetchedPaymentMethods));
  } catch (error) {
    console.error("Error fetching payment methods:", error);
    dispatch(
      fetchPaymentMethodsFailure(
        "An error occurred while fetching payment methods."
      )
    );
  }
};

export const cleanPaymentMethods = () => async (dispatch) => {
  dispatch(cleanPaymentMethodsSuccess());
};

export const createProposal =
  (
    address,
    price,
    amount,
    paymentMethodIndex,
    description,
    publicClient,
    comment,
    discordUsername,
    MainContract_ADDRESS
  ) =>
  async (dispatch) => {
    if (!address) return;
    const transferPrice = (Number(price) * 100).toFixed();
    const currentAmount = amount * 10 ** 6;
    const paymentMethod = paymentMethodIndex.filter(element => element !== false)

    try {
      const gas = await publicClient.estimateContractGas({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "createProposal",
        args: [
          currentAmount,
          transferPrice,
          paymentMethod,
          description,
          comment,
          discordUsername,
        ],
        account: address,
      });

      await writeContract({
        address: MainContract_ADDRESS,
        abi: MainContract_ABI,
        functionName: "createProposal",
        args: [
          currentAmount,
          transferPrice,
          paymentMethod,
          description,
          comment,
          discordUsername,
        ],
        account: address,
        gas: gas,
      });

      dispatch(createProposalSuccess("Proposal created successfully!"));
    } catch (error) {
      console.error("Error creating proposal:", error);
      const returnError = handledError("Error creating proposal:", error);
      dispatch(createProposalFailure(returnError));
    }
  };
