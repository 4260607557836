import React, { useState } from "react";
import "./DisputeRulesPhone.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const DisputeRulesPhone = () => {
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);
  };

  return (
    <div className="dispute-rules">
      <div className="dispute-rules__slider">
        <div className="dispute-rules__slider-text-container">
          <p className="dispute-rules__slider-text"> Buyers and Sellers</p>
          <p className="dispute-rules__slider-text">For Token Holders</p>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          step="100"
          className={classNames("dispute-rules__slider-range-sell", {
            "dispute-rules__slider-range-buy": sliderValue === 100,
          })}
          value={sliderValue}
          onChange={handleSliderChange}
        />
      </div>
      <article className="dispute-rules__rules">
        {sliderValue === 0 ? (
          <ul className="dispute-rules__rules-item">
            <li className="dispute-rules__rules-rule">
              <p className="dispute-rules__rule-text">
                rely on community to resolve your disputes{" "}
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">1</div>
              <p className="dispute-rules__rule-text">
                If a dispute arises, create a dispute and then present your
                evidence on Discord to support your position{" "}
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">2</div>
              <p className="dispute-rules__rule-text">
                Finja Token holders will vote to support either the buyer's or
                the seller's position.
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">3</div>
              <p className="dispute-rules__rule-text">
                The voting period is 48 hours. The winner is the side that
                receives 75% of the votes. The winner will receive the crypto
                from the escrow account.
              </p>
            </li>
          </ul>
        ) : (
          <ul className="dispute-rules__rules-item">
            <li className="dispute-rules__rules-rule">
              <p className="dispute-rules__rule-text">vote and get rewarded</p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">1</div>
              <p className="dispute-rules__rule-text">
                Review the evidence on
                <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">
                  Discord
                </Link>
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">2</div>
              <p className="dispute-rules__rule-text">
                Vote for either the buyer's or the seller's position by staking
                your Finja Tokens here.
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">3</div>
              <p className="dispute-rules__rule-text">
                Receive a reward equivalent to 20% of your staked tokens for
                participating in the voting process.
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">4</div>
              <p className="dispute-rules__rule-text">
                If you support the winning side of the dispute, you will also
                receive a share of the tokens staked by those who supported the
                losing side. If you support the losing side of the dispute, you
                will forfeit your staked tokens.{" "}
              </p>
            </li>
            <li className="dispute-rules__rules-rule">
              <div className="dispute-rules__rule-number">5</div>
              <p className="dispute-rules__rule-text">
                The winner is the side that receives 75% of votes. If no side
                receive 75% of votes, dispute can be relaunched again.{" "}
              </p>{" "}
            </li>
          </ul>
        )}
      </article>
    </div>
  );
};
