import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentMethods: [],
  message: "",
  error: "",
};

const createProposalSlice = createSlice({
  name: "createProposal",
  initialState,
  reducers: {
    fetchPaymentMethodsSuccess: (state, action) => {
      state.paymentMethods = action.payload;
    },

    fetchPaymentMethodsFailure: (state, action) => {
      state.error = action.payload;
    },

    cleanPaymentMethodsSuccess: (state) => {
      state.paymentMethods = [];
    },
    createProposalSuccess: (state, action) => {
      state.message = action.payload;
    },
    createProposalFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsFailure,
  cleanPaymentMethodsSuccess,
  createProposalSuccess,
  createProposalFailure,
} = createProposalSlice.actions;

export default createProposalSlice.reducer;
