import "./WarningPopup.scss";

const WarningPopup = ({ visibility, setVisibility, text, confirmFunction }) => {

  const confirm = () => {
    setVisibility(false);
    confirmFunction(true);
  }

  return (
    <div className="warning-popup">
      {visibility &&
      <div className="warning-popup__container" >
        <div className="warning-popup__content">
        
           { text }
         
        </div>
        <div className="warning-popup__button-container">
          <button onClick={() => setVisibility(false)} className="warning-popup__button">Reject</button>
          <button onClick={() => confirm(true)} className="warning-popup__button">Confirm</button>
        </div>
      </div>}
    </div>
  );
};

export default WarningPopup;
