import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeProposals: [],
  activeProposalsFilter: [],
  completedProposals: [],
  loaderActiveProposals: false,
  loaderCompletedProposals: false,
  idForNewProposal: "",
  idForUpdateProposal: [],
  idForDeleteProposal: "",
  idForUpdateProposalCompleted: [],
  error: "",
  message: "",
  selectedCryptoAmount: {},
};

const buyerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    fetchActiveProposals: (state) => {
      state.activeProposals = [];
      state.activeProposalsFilter = [];
      state.loaderActiveProposals = true;
    },

    fetchActiveProposalsSuccess: (state, action) => {
      state.activeProposals = [...state.activeProposals, action.payload];
      state.activeProposalsFilter = [
        ...state.activeProposalsFilter,
        action.payload,
      ];
    },
    fetchActiveProposalsSuccessAll: (state) => {
      state.loaderActiveProposals = false;
    },

    fetchActiveProposalsFailure: (state, action) => {
      state.loaderActiveProposals = false;
      state.error = action.payload;
    },

    fetchCompletedProposals: (state) => {
      state.loaderCompletedProposals = true;
      state.completedProposals = [];
    },

    fetchCompletedProposalsSuccess: (state, action) => {
      state.completedProposals = [...state.completedProposals, action.payload];
    },

    fetchCompletedProposalsSuccessAll: (state, action) => {
      state.loaderCompletedProposals = false;
    },
    fetchCompletedProposalsFailure: (state, action) => {
      state.loaderCompletedProposals = false;
      state.error = action.payload;
    },

    cleanActiveProposals: (state) => {
      state.activeProposals = [];
      state.activeProposalsFilter = [];
      state.completedProposals = [];
    },
    addIdForNewProposalSuccess: (state, action) => {
      state.idForNewProposal = action.payload;
    },

    addIdForUpdateProposalSuccess: (state, action) => {
      state.idForUpdateProposal = [
        ...state.idForUpdateProposal,
        action.payload,
      ];
    },

    addIdForDeleteProposalSuccess: (state, action) => {
      state.idForDeleteProposal = action.payload;
    },

    addIdForProposaCompletedSuccess: (state, action) => {
      state.idForUpdateProposalCompleted = [
        ...state.idForUpdateProposalCompleted,
        action.payload,
      ];
    },

    // addMessageForUpdateProposal: (state, action) => {
    //   state.message = action.payload;
    // },

    addNewProposalSucces: (state, action) => {
      state.activeProposalsFilter = action.payload;
      state.idForNewProposal = "";
      state.activeProposals = action.payload;
    },

    updateActiveProposals: (state, action) => {
      state.activeProposals = action.payload;
      state.activeProposalsFilter = action.payload;
      state.idForUpdateProposal = [];
      state.message = "";
    },

    deleteProposalSuccesSeller: (state, action) => {
      state.activeProposals = action.payload;
      state.activeProposalsFilter = action.payload;
      state.idForDeleteProposal = "";
    },

    sendBuyerRequestSuccess: (state, action) => {
      state.message = action.payload;
    },
    sendBuyerRequestFailure: (state, action) => {
      state.error = action.payload;
    },
    confirmFiatPaymentSuccess: (state, action) => {
      state.message = action.payload;
    },
    confirmFiatPaymentFailure: (state, action) => {
      state.error = action.payload;
    },

    createDisputeSuccess: (state, action) => {
      state.message = action.payload;
    },
    createDisputeFailure: (state, action) => {
      state.error = action.payload;
    },
    requestCancellationSuccess: (state, action) => {
      state.message = action.payload;
    },
    requestCancellationFailure: (state, action) => {
      state.error = action.payload;
    },

    deactivateBuyerRequestSuccess: (state, action) => {
      state.message = action.payload;
    },
    deactivateBuyerRequestFailure: (state, action) => {
      state.error = action.payload;
    },
    filterProposalsSuccess: (state, action) => {
      state.activeProposalsFilter = action.payload;
    },

    cleanMessageSuccess: (state) => {
      state.message = "";
    },
    cleanErrorSuccess: (state) => {
      state.error = "";
    },
  },
});

export const {
  fetchActiveProposals,
  updateActiveProposals,
  deleteProposalSuccesSeller,
  fetchActiveProposalsSuccess,
  fetchActiveProposalsSuccessAll,
  fetchActiveProposalsFailure,

  fetchCompletedProposals,
  fetchCompletedProposalsSuccess,
  fetchCompletedProposalsSuccessAll,
  fetchCompletedProposalsFailure,

  cleanActiveProposals,
  addIdForNewProposalSuccess,
  addIdForUpdateProposalSuccess,
  addIdForProposaCompletedSuccess,
  addIdForDeleteProposalSuccess,
  // addMessageForUpdateProposal,
  addNewProposalSucces,
  sendBuyerRequestSuccess,
  sendBuyerRequestFailure,
  confirmFiatPaymentSuccess,
  confirmFiatPaymentFailure,
  createDisputeSuccess,
  createDisputeFailure,
  requestCancellationSuccess,
  requestCancellationFailure,
  deactivateBuyerRequestSuccess,
  deactivateBuyerRequestFailure,
  filterProposalsSuccess,
  cleanMessageSuccess,
  cleanErrorSuccess,
} = buyerSlice.actions;

export default buyerSlice.reducer;
