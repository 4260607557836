import React, { useState, useEffect } from "react";
import "./BuyerProposal.scss";
import discord_logo from "../../../assets/images/discord_logo.svg";
import checkmark_tick from "../../../assets/images/checkmark_tick.svg";
import FieldDescription from "../../../UI/FieldDescription/FieldDescription";
import { useDispatch, useSelector } from "react-redux";
import {
  sendBuyerRequest,
  confirmFiatPayment,
  createDispute,
  requestCancellation,
  deactivateBuyerRequest,
} from "../../../store/reducers/buyerProposals/buyerActions";
import { getPublicClient, getAccount } from "@wagmi/core";
import { Link } from "react-router-dom";
import { MapPaymentMethods } from "../../MapPaymentMethods/MapPaymentMethods.js";
import WarningPopup from "../../../UI/WarningPopup/WarningPopup";

const keccak256 = require("keccak256");

export const BuyerProposal = ({ proposal }) => {
  const { address } = getAccount();
  const publicClient = getPublicClient();
  const [showLess, setShowLess] = useState(false);
  const [paymentDetailsInput, setPaymentDetailsInput] = useState({});
  const [checkResult, setCheckResult] = useState({});
  const [showCheckAnother, setShowCheckAnother] = useState({});
  const [selectedCryptoAmount, setSelectedCryptoAmount] = useState({});
  const [togleWarningPopup, setTogleWarningPopup] = useState(false);
  const [canRequestCancellation, setCanRequestCancellation] = useState(false);

  const { MainContract_ADDRESS } = useSelector(
    (state) => state.contractsAddress
  );
 
  const dispatch = useDispatch();

  const nameField = {
    requestToBuy: "requestToBuy",
    confirmFiaPayment: "confirmFiaPayment",
    disputeBuyer: "disputeBuyer",
    transactionCancellationBuyer: "transactionCancellationBuyer",
    enterCryptoAmount: "enterCryptoAmount",
    enterPaymentDetails: "enterPaymentDetails",
  };

  const handleCheckAnother = (proposalId) => {
    setPaymentDetailsInput((prev) => ({ ...prev, [proposalId]: "" }));
    setCheckResult((prev) => ({ ...prev, [proposalId]: null }));
    setShowCheckAnother((prev) => ({ ...prev, [proposalId]: false }));
  };

  const checkPaymentDetails = async (proposalId, description) => {
    const paymentDetails = keccak256(
      Buffer.from(String(paymentDetailsInput[proposalId]))
    ).toString("hex");
    const descriptionAdaptive = description.substring(2);

    let correctId = "";
    if (paymentDetails === descriptionAdaptive) {
      correctId = proposalId;
    }
    
    setCheckResult((prev) => ({
      ...prev,
      [proposalId]: correctId ? (
        <p className="correct">Payment method details are correct</p>
      ) : (
        <p className="not-correct">Payment details are not correct</p>
      ),
    }));
    setShowCheckAnother((prev) => ({ ...prev, [proposalId]: true }));
  };

  const onSendBuyerRequest = (proposalId) => {
    dispatch(
      sendBuyerRequest(
        proposalId,
        selectedCryptoAmount,
        publicClient,
        address,
        MainContract_ADDRESS
      )
    );
    setSelectedCryptoAmount((prev) => ({
      ...prev,
      [proposalId]: "",
    }));
  };

  
  const onRequestCancellation = (
  ) => {
    if (!proposal?.request.paymentDeclared) {
      setCanRequestCancellation(true)

    } else {
      setTogleWarningPopup(true);
    }
  };

  useEffect(() => {
    if(canRequestCancellation) {
      dispatch(
        requestCancellation(
          proposal.id,
          proposal?.request.buyerRequestIndex,
          address,
          MainContract_ADDRESS,
        )
      );
      setCanRequestCancellation(false)
    }
   
  }, [canRequestCancellation])

  const copyToClipboard = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const renderAction = (proposal) => {
    if (!proposal.request) {
      return (
        <li className="buyre-proposal__list-button-item">
          <div className="buyre-proposal__field">
            <input
              placeholder="Enter crypto amount you'd like to buy"
              className="buyre-proposal__input "
              type="number"
              value={selectedCryptoAmount[proposal.id] || ""}
              onChange={(e) =>
                setSelectedCryptoAmount((prev) => ({
                  ...prev,
                  [proposal.id]: e.target.value,
                }))
              }
            />
            <FieldDescription nameField={nameField.enterCryptoAmount} />
          </div>
          <button
            className="buyre-proposal__button buyre-proposal__button-request-to-buy"
            onClick={() => onSendBuyerRequest(proposal.id)}
            disabled={!selectedCryptoAmount[proposal.id]}
          >
            <span>Request to Buy</span>
            <FieldDescription nameField={nameField.requestToBuy} />
          </button>
        </li>
      );
    } else if (!proposal.request.accepted) {
      return (
        <li className="buyre-proposal__list-button-item">
          <p>Waiting for the Seller to accept your request</p>
          <button
            className="buyre-proposal__button"
            onClick={() =>
              dispatch(
                deactivateBuyerRequest(
                  proposal.id,
                  proposal.request.buyerRequestIndex,
                  address,
                  MainContract_ADDRESS
                )
              )
            }
          >
            Cancel request
          </button>
        </li>
      );
    } else if (
      proposal.request &&
      proposal.request.accepted &&
      !proposal.request.paymentDeclared
    ) {
      return (
        <li className="buyre-proposal__list-button-item">
          <button
            className="buyre-proposal__button buyre-proposal__button-confirmFiaPayment"
            onClick={() =>
              dispatch(
                confirmFiatPayment(
                  proposal.id,
                  proposal.request.buyerRequestIndex,
                  publicClient,
                  address,
                  MainContract_ADDRESS
                )
              )
            }
          >
            <span>Confirm fiat payment</span>
            <FieldDescription nameField={nameField.confirmFiaPayment} />
          </button>
        </li>
      );
    } else if (
      proposal.request &&
      proposal.request.accepted &&
      proposal.request.paymentDeclared &&
      !proposal.request.transactionCompleted
    ) {
      return <div>Waiting for the Seller to release crypto</div>;
    } else {
      return <p>Transaction completed</p>;
    }
  };

  return (
    <div className="buyre-proposal">
      <ul className="buyre-proposal__list buyre-proposal__list-main">
        <li className="buyre-proposal__list-item">
          <span className="buyre-proposal__lable">Price</span>
          <p className="buyre-proposal__value-main">${proposal.price}</p>
        </li>
        <li className="buyre-proposal__list-item buyre-proposal__hiden-desktop">
          <span className="buyre-proposal__lable">Amount available</span>
          <p className="buyre-proposal__value buyre-proposal__value-amount">
            {proposal.amount}
          </p>
        </li>
        <li className="buyre-proposal__list-item buyre-proposal__completed-transactions  buyre-proposal__hiden-phone">
          <span className="buyre-proposal__lable ">
            # of completed transactions
          </span>
          <p className="buyre-proposal__value">{proposal.transactionsCount}</p>
        </li>
        <li className="buyre-proposal__list-item  buyre-proposal__hiden-phone">
          <span className="buyre-proposal__lable">Amount available</span>
          <p className="buyre-proposal__value buyre-proposal__value-amount">
            {proposal.amount}
          </p>
        </li>

        <li className="buyre-proposal__list-item">
          <span className="buyre-proposal__lable">Payment method</span>
          <MapPaymentMethods paymentMethods={proposal.paymentMethods} />
        </li>
      </ul>
      <ul className="buyre-proposal__list buyre-proposal__list-other ">
        <ul className="buyre-proposal__list-container">
          <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable">Proposal ID</span>
            {proposal.id}
          </li>
           <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable">Date</span>
            <p className="buyre-proposal__value buyre-proposal__value-date">
              {new Date(proposal.date * 1000).toLocaleString()}
            </p>
          </li>

          <li className="buyre-proposal__list-item">
            <span className="buyre-proposal__lable">Seller Address</span>
            <p className="buyre-proposal__value">
              {proposal?.seller?.substring(0, 5)}
              <button
                onClick={() => copyToClipboard(proposal.seller)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </p>
          </li>
          <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable">Discord username</span>
            <p className="buyre-proposal__value-discord">
              <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">
                <img
                  className="buyre-proposal__discord-logo"
                  src={discord_logo}
                  alt={discord_logo}
                />
              </Link>
              {proposal?.discord.length > 8
                ? `${proposal?.discord.substring(0, 8)} ...`
                : proposal?.discord.substring(0, 8)}
              {proposal.discord && (
                <button
                  onClick={() => copyToClipboard(proposal.discord)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#ffffff",
                  }}
                >
                  <i className="fas fa-copy"></i>
                </button>
              )}
            </p>
          </li>
          <li className="buyre-proposal__list-item buyre-proposal__hiden-desktop">
            <span className="buyre-proposal__lable ">
              # of completed transactions
            </span>
            <p className="buyre-proposal__value">
              {proposal.transactionsCount}
            </p>
          </li>
          <li className="buyre-proposal__list-item buyre-proposal__hiden-desktop">
            <span className="buyre-proposal__lable ">Proposal ID</span>
            {proposal.id}
          </li>
        </ul>
        <ul className="buyre-proposal__list-container">
          <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable">Seller Accepted</span>
            <p className="buyre-proposal__value buyre-proposal__value-icon">
              {proposal.request && proposal.request.accepted && (
                <img
                  className="buyre-proposal__value-icon-tick"
                  src={checkmark_tick}
                  alt="tick"
                />
              )}
            </p>
          </li>
          <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable">Escrow Balance</span>
            <p className="buyre-proposal__value">{proposal.escrowBalance}</p>
          </li>
          <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable"> Payment Declared</span>
            <p className="buyre-proposal__value buyre-proposal__value-icon">
              {proposal.request && proposal.request.paymentDeclared && (
                <img
                  className="buyre-proposal__value-icon-tick"
                  src={checkmark_tick}
                  alt="tick"
                />
              )}
            </p>
          </li>

          <li className="buyre-proposal__list-item buyre-proposal__hiden-phone">
            <span className="buyre-proposal__lable"> Crypto Released</span>
            <p className="buyre-proposal__value buyre-proposal__value-icon">
              {proposal.request && proposal.request.transactionCompleted && (
                <img
                  className="buyre-proposal__value-icon-tick"
                  src={checkmark_tick}
                  alt="tick"
                />
              )}
            </p>
          </li>
        </ul>

        <ul className="buyre-proposal__list-container">
          <li className="buyre-proposal__list-item-blockButton">
            <div>
              {proposal.request &&
                proposal.request.accepted &&
                proposal.request.paymentDeclared &&
                !proposal.request.transactionCompleted && (
                  <>
                    <>
                      {proposal.request.canCreateDisput && (
                        <button
                          onClick={() =>
                            dispatch(
                              createDispute(
                                proposal.id,
                                proposal.request.buyerRequestIndex,
                                address,
                                publicClient,
                                MainContract_ADDRESS
                              )
                            )
                          }
                          className="buyre-proposal__button buyre-proposal__button-dispute"
                        >
                          <span>Dispute</span>
                          <FieldDescription
                            nameField={nameField.disputeBuyer}
                          />
                        </button>
                      )}
                    </>
                  </>
                )}

              {proposal.request &&
                proposal.request.accepted &&
                proposal.request.paymentDeclared &&
                !proposal.request.transactionCompleted && (
                  <>
                    {proposal.request.disputeCreated && (
                      <p className="buyre-proposal__text-disput-resolved">
                        Dispute for this transaction was initiated. Do not
                        cancel, transfer crypto or fiat until the dispute is
                        resolved
                      </p>
                    )}
                  </>
                )}
            </div>
            <div>
              <>
                {proposal.request &&
                  proposal.request.accepted &&
                  !proposal.request.buyerRequestedtoCancel && (
                    <>
                      <button
                        className="buyre-proposal__button buyre-proposal__button-cancellation"
                        disabled={proposal.request.disputeCreated}
                        onClick={() =>
                          onRequestCancellation()
                        }
                      >

                        {proposal.request.sellerRequestedtoCancel ? (
                          <span>Confirm transaction cancellation</span>
                        ) : (
                          <span>Request transaction cancellation</span>
                        )}
                        <FieldDescription
                          nameField={nameField.transactionCancellationBuyer}
                        />
                      </button>
                      
                      <WarningPopup
                          visibility={togleWarningPopup}
                          setVisibility={setTogleWarningPopup}
                          text={
                            "You confirmed sending fiat payment. If you request transaction cancellation and the seller will confirm it, you might lose your sent fiat"
                          }
                          confirmFunction={setCanRequestCancellation}
                        />
                      <>
                        {proposal.request.sellerRequestedtoCancel && (
                          <p className="buyre-proposal__value">
                            Seller sent request to cancel this transaction
                          </p>
                        )}
                      </>
                    </>
                  )}
              </>
              <>
                {proposal.request &&
                  proposal.request.accepted &&
                  proposal.request.buyerRequestedtoCancel &&
                  !proposal.request.sellerRequestedtoCancel &&
                  !proposal.request.transactionCompleted && (
                    <p>
                      Your cancellation request has been sent. The transaction
                      will be cancelled once the seller also confirms the
                      cancellation
                    </p>
                  )}
              </>
            </div>
          </li>

          <li className="buyre-proposal__list-item-blockButton buyre-proposal__hiden-phone">
            <div>
              <span className="buyre-proposal__lable">Seller’s comments :</span>
              <p className="buyre-proposal__comment">{proposal.comment}</p>
            </div>
          </li>
        </ul>
      </ul>

      <button
        className="buyre-proposal__showLess buyre-proposal__hiden-desktop"
        onClick={() => setShowLess(!showLess)}
      >
        {showLess ? <p>Show less</p> : <p>Show all</p>}
      </button>
      {showLess && (
        <>
          <ul className="buyre-proposal__list  buyre-proposal__hiden-desktop">
            <li className="buyre-proposal__list-item">
              <span className="buyre-proposal__lable">Seller Accepted</span>
              <p className="buyre-proposal__value buyre-proposal__value-icon">
                {proposal.request && proposal.request.accepted && (
                  <img
                    className="buyre-proposal__value-icon-tick"
                    src={checkmark_tick}
                    alt="tick"
                  />
                )}
              </p>
            </li>

            <li className="buyre-proposal__list-item">
              <span className="buyre-proposal__lable">Escrow Balance</span>
              <p className="buyre-proposal__value">{proposal.escrowBalance}</p>
            </li>
            <li className="buyre-proposal__list-item">
              <span className="buyre-proposal__lable"> Payment Declared</span>
              <p className="buyre-proposal__value buyre-proposal__value-icon">
                {proposal.request && proposal.request.paymentDeclared && (
                  <img
                    className="buyre-proposal__value-icon-tick"
                    src={checkmark_tick}
                    alt="tick"
                  />
                )}
              </p>
            </li>
            <li className="buyre-proposal__list-item">
              <span className="buyre-proposal__lable"> Crypto Released</span>
              <p className="buyre-proposal__value buyre-proposal__value-icon">
                {proposal.request && proposal.request.transactionCompleted && (
                  <img
                    className="buyre-proposal__value-icon-tick"
                    src={checkmark_tick}
                    alt="tick"
                  />
                )}
              </p>
            </li>
          </ul>
          <ul className="buyre-proposal__list  buyre-proposal__hiden-desktop">
            <li className="buyre-proposal__list-item">
              <span className="buyre-proposal__lable">Date</span>
              <p className="buyre-proposal__value">
                {new Date(proposal.date * 1000).toLocaleString()}
              </p>
            </li>
            <li className="buyre-proposal__list-item">
              <span className="buyre-proposal__lable">Discord username</span>
              <p className="buyre-proposal__value-discord">
                <img
                  className="buyre-proposal__discord-logo"
                  src={discord_logo}
                  alt={discord_logo}
                />
                {proposal?.discord.length > 8
                  ? `${proposal?.discord.substring(0, 8)} ...`
                  : proposal?.discord.substring(0, 8)}
                {proposal.discord && (
                  <button
                    onClick={() => copyToClipboard(proposal.discord)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#ffffff",
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                )}
              </p>
            </li>
          </ul>
          <ul className="buyre-proposal__list  buyre-proposal__hiden-desktop">
            <li className="buyre-proposal__list-item-blockButton">
              <div>
                <span className="buyre-proposal__lable">
                  Seller’s comments :
                </span>
                <p className="buyre-proposal__comment">{proposal.comment}</p>
              </div>
            </li>
          </ul>
        </>
      )}
      {!proposal.completed ? (
        <ul className="buyre-proposal__list-button">
          {renderAction(proposal)}
          {proposal.request && proposal.request.accepted && (
            <li className="buyre-proposal__list-button-item">
              {!checkResult[proposal.id] && (
                <>
                  <div className="buyre-proposal__field">
                    <input
                      className="buyre-proposal__input "
                      placeholder="Check payment details of the Seller"
                      type="text"
                      value={paymentDetailsInput[proposal.id] || ""}
                      onChange={(e) =>
                        setPaymentDetailsInput((prev) => ({
                          ...prev,
                          [proposal.id]: e.target.value,
                        }))
                      }
                    />
                    <FieldDescription
                      nameField={nameField.enterPaymentDetails}
                    />
                  </div>

                  <button
                    className="buyre-proposal__button buyre-proposal__button-check"
                    onClick={() =>
                      checkPaymentDetails(proposal.id, proposal.description)
                    }
                  >
                    Check
                  </button>
                </>
              )}
              {checkResult[proposal.id] && (
                <div>
                  <div className="buyre-proposal__check-text">
                    {checkResult[proposal.id]}
                  </div>
                  {showCheckAnother[proposal.id] && (
                    <button
                      className="buyre-proposal__button"
                      onClick={() => handleCheckAnother(proposal.id)}
                    >
                      Check another
                    </button>
                  )}
                </div>
              )}
            </li>
          )}
        </ul>
      ) : (
        <ul className="buyre-proposal__list-button">Transaction completed</ul>
      )}
    </div>
  );
};
