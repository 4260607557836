import React from "react";
import "./SellerProposal.scss";
import { useDispatch, useSelector } from "react-redux";
import { deactivateProposal } from "../../../store/reducers/sellerProposals/sellerActions";
import { MapPaymentMethods } from "../../MapPaymentMethods/MapPaymentMethods.js";

export const SellerProposalNoHasRequest = ({ proposal }) => {
  const dispatch = useDispatch();
  const { MainContract_ADDRESS } = useSelector(
    (state) => state.contractsAddress
  );

  return (
    <div className="proposal">
      <ul className="proposal__list proposal__list-main">
        <li className="proposal__list-item">
          <span className="proposal__lable">Proposal ID</span>
          <p className="proposal__value">{proposal.id}</p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Price</span>
          <p className="proposal__value">${proposal.price}</p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Amount available</span>
          <p className="proposal__value proposal__value-amount">
            {proposal.amount}
          </p>
        </li>
        <li className="proposal__list-item">
          <span className="proposal__lable">Payment method</span>
        
          <MapPaymentMethods paymentMethods={proposal.paymentMethods}/>
        
        </li>
      </ul>
      <div className="proposal__line-phone"></div>
      <ul className="proposal__list proposal__list-other">
        <li className="proposal__list-no-active-request">No active requests</li>
      </ul>
      <ul className="proposal__list-button">
        <li className="proposal__list-item">
          <p></p>
        </li>

        <li className="proposal__list-item">
          <button
            className="proposal__button"
            onClick={() =>
              dispatch(deactivateProposal(proposal.id, MainContract_ADDRESS))
            }
          >
            Cancel proposal
          </button>
        </li>
      </ul>
    </div>
  );
};
