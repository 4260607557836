import React, { useState, useEffect, useRef } from "react";
import "./DisputeFilters.scss";
import useOutsideClick from "@rooks/use-outside-click";
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from "react-redux";
import { updateFilterDisputes } from "../../../store/reducers/dispute/disputeActions";

export const DisputeFilters = () => {
  const [showLess, setShowLess] = useState(false);
  const { disputesList, loaderDisputeList } = useSelector(
    (state) => state.dispute
  );
  const dispatch = useDispatch();

  const [togleFilters, setTogleFilters] = useState({
    buyerVotes: false,
    sellerVotes: false,
    endTime: false,
  });

  const { buyerVotes, sellerVotes, endTime } = togleFilters;

  const [nameFilters, setNameFilters] = useState({
    disputeId: "",
    proposalID: "",
    seller: "",
    buyer: "",
    buyerVotes: "",
    sellerVotes: "",
    endTime: "",
    buyerRequestIndex: "",
  });

  const categories = {
    disputeId: "disputeId",
    proposalID: "proposalID",
    seller: "seller",
    buyer: "buyer",
    buyerVotes: "buyerVotes",
    sellerVotes: "sellerVotes",
    endTime: "endTime",
    buyerRequestIndex: "buyerRequestInde",
  };

  const typeFilterEndTime = {
    1: "30 minutes",
    2: "24 hours",
    3: "1 week",
    4: "2 week",
    5: "1 month",
  };

  const typeFilterBuyerVotes = {
    1: [5, "< 5"],
    2: [10, "< 10"],
    3: [50, "< 50"],
    4: [100, "< 100"],
    5: [200, "< 200"],
  };

  const typeFilterSellerVotes = {
    1: [5, "< 5"],
    2: [10, "< 10"],
    3: [50, "< 50"],
    4: [100, "< 100"],
    5: [200, "< 200"],
  };

  const togleFilter = (nameFilter) => {
    setTogleFilters((prev) => ({
      ...prev,
      [nameFilter]: !prev[nameFilter],
    }));
    for (const key in nameFilters) {
      if (key !== nameFilter) {
        setTogleFilters((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
    }
  };

  const filterByDate = (disputes, name) => {
    const nowDate = new Date().getTime();
    switch (name) {
      case typeFilterEndTime[1]:
        return disputes.filter(
          (dispute) => (nowDate - dispute.endTime * 1000) / 1000 < 1800
        );
      case typeFilterEndTime[2]:
        return disputes.filter(
          (dispute) => (nowDate - dispute.endTime * 1000) / 1000 < 86400
        );
      case typeFilterEndTime[3]:
        return disputes.filter(
          (dispute) => (nowDate - dispute.endTime * 1000) / 1000 < 604800
        );
      case typeFilterEndTime[4]:
        return disputes.filter(
          (dispute) => (nowDate - dispute.endTime * 1000) / 1000 < 1209600
        );
      case typeFilterEndTime[5]:
        return disputes.filter(
          (dispute) => (nowDate - dispute.endTime * 1000) / 1000 < 2419200
        );
      default:
        return disputes;
    }
  };

  const filterByCount = (disputes, nameFilter, key, typeFilter) => {
    switch (nameFilter) {
      case typeFilter[1][1]:
        return disputes.filter((dispute) => dispute[key] < typeFilter[1][0]);
      case typeFilter[2][1]:
        return disputes.filter((dispute) => dispute[key] < typeFilter[2][0]);
      case typeFilter[3][1]:
        return disputes.filter((dispute) => dispute[key] < typeFilter[3][0]);
      case typeFilter[4][1]:
        return disputes.filter((dispute) => dispute[key] < typeFilter[4][0]);
      case typeFilter[5][1]:
        return disputes.filter((dispute) => dispute[key] < typeFilter[5][0]);
      default:
        return disputes;
    }
  };

  const handleFilter = () => {
    let filterDisputes = disputesList;
    for (const key in nameFilters) {
      if (nameFilters[key] !== "") {
        if (key === categories.endTime) {
          const filteritem = filterByDate(filterDisputes, nameFilters[key]);
          filterDisputes = filteritem;
        } else if (key === categories.buyerVotes) {
          const filteritem = filterByCount(
            filterDisputes,
            nameFilters[key],
            key,
            typeFilterBuyerVotes
          );
          filterDisputes = filteritem;
        } else if (key === categories.sellerVotes) {
          const filteritem = filterByCount(
            filterDisputes,
            nameFilters[key],
            key,
            typeFilterSellerVotes
          );
          filterDisputes = filteritem;
        } else {
          if (nameFilters[key] !== "") {
            const filteritem = filterDisputes.filter(
              (proposal) => String(proposal[key]) === String(nameFilters[key])
            );
            filterDisputes = filteritem;
            console.log(filteritem);
          }
        }
      }
    }
    return filterDisputes;
  };

  useEffect(() => {
    const result = handleFilter();
    dispatch(updateFilterDisputes(result));
  }, [nameFilters]);

  const filterActiveProposals = (nameFilter, filtersElement) => {
    setNameFilters((prev) => ({
      ...prev,
      [nameFilter]: filtersElement,
    }));

    setTogleFilters((prev) => ({
      ...prev,
      [nameFilter]: false,
    }));
  };

  const clearFilterActiveProposals = (nameFilter) => {
    setNameFilters((prev) => ({
      ...prev,
      [nameFilter]: "",
    }));
    setTogleFilters((prev) => ({
      ...prev,
      [nameFilter]: false,
    }));
  };

  const handleSearch = (name, event) => {
    event.preventDefault();
    setNameFilters((prev) => ({
      ...prev,
      [name]: event.target.value,
    }));
  };

  const filterByNameCount = (disputes, typeFilter, namefilter) => {
    const result = disputes
      .map((dispute) => {
        if (dispute[namefilter] < typeFilter[1][0]) {
          return typeFilter[1][1];
        } else if (dispute[namefilter] < typeFilter[2][0]) {
          return typeFilter[2][1];
        } else if (dispute[namefilter] < typeFilter[3][0]) {
          return typeFilter[3][1];
        } else if (dispute[namefilter] < typeFilter[4][0]) {
          return typeFilter[4][1];
        } else if (dispute[namefilter] < typeFilter[5][0]) {
          return typeFilter[5][1];
        }
        return null;
      })
      .filter((el, ind, arr) => el !== null && ind === arr.indexOf(el));
    return result;
  };

 const filterByNameDate = (disputes) => {
    const nowDate = new Date().getTime();
    const result = disputes
      .map((dispute) => {
        const currentDate = dispute.endTime * 1000;
        if ((nowDate - currentDate) / 1000 < 1800) {
          return typeFilterEndTime[1];
        } else if ((nowDate - currentDate) / 1000 < 86400) {
          return typeFilterEndTime[2];
        } else if ((nowDate - currentDate) / 1000 < 604800) {
          return typeFilterEndTime[3];
        } else if ((nowDate - currentDate) / 1000 < 1209600) {
          return typeFilterEndTime[4];
        } else if ((nowDate - currentDate) / 1000 < 2419200) {
          return typeFilterEndTime[5];
        }
        return null;
      })
      .filter((el, ind, arr) => el !== null && ind === arr.indexOf(el));

    return result;
  };

  const showFilter = (nameFilter) => {
    const currentDisputes = disputesList;
    const currentnameFilter = nameFilter;
    let result;

    if (currentnameFilter === categories.endTime) {
      result = filterByNameDate(currentDisputes);
    } else if (currentnameFilter === categories.buyerVotes) {
      result = filterByNameCount(
        currentDisputes,
        typeFilterBuyerVotes,
        categories.buyerVotes
      );
    } else if (currentnameFilter === categories.sellerVotes) {
      result = filterByNameCount(
        currentDisputes,
        typeFilterSellerVotes,
        categories.sellerVotes
      );
    } else {
      result = currentDisputes
        .map((dispute) => dispute[nameFilter])
        .filter((el, ind, arr) => ind === arr.indexOf(el));
    }

    return (
      <div>
        <button
          className="dispute-filters__names-list-item"
          onClick={() => clearFilterActiveProposals(nameFilter)}
        >
          Any
        </button>
        {result?.map((filtersElement) => (
          <button
            key={filtersElement.id}
            className="dispute-filters__names-list-item"
            onClick={() => filterActiveProposals(nameFilter, filtersElement)}
          >
            {filtersElement}
          </button>
        ))}
      </div>
    );
  };

  const pRef = useRef();
  function outsidePClick() {
    setTogleFilters((prev) => ({
      ...prev,
      buyerVotes: false,
      sellerVotes: false,
      endTime: false,
    }));
  }
  useOutsideClick(pRef, outsidePClick);

  return (
    <div>
      <div ref={pRef} className="dispute-filters">
        <ul className="dispute-filters__list">
          <li className="dispute-filters__list-item">
            <label className="dispute-filters__label">Search Dispute ID</label>
            <input
              className="dispute-filters__input"
              type="search"
              onChange={(e) => handleSearch(categories.disputeId, e)}
            />
            {loaderDisputeList && (
              <div className="dispute-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderDisputeList}
                />
              </div>
            )}
          </li>
          <li className="dispute-filters__list-item">
            <label className="dispute-filters__label">Search Buyer</label>
            <input
              className="dispute-filters__input"
              type="search"
              onChange={(e) => handleSearch(categories.buyer, e)}
            />
            {loaderDisputeList && (
              <div className="dispute-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderDisputeList}
                />
              </div>
            )}
          </li>
          <li className="dispute-filters__list-item">
            <label className="dispute-filters__label">Search Seller</label>
            <input
              className="dispute-filters__input"
              type="search"
              onChange={(e) => handleSearch(categories.seller, e)}
            />
            {loaderDisputeList && (
              <div className="dispute-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderDisputeList}
                />
              </div>
            )}
          </li>
          <li className="dispute-filters__list-item">
            <label className="dispute-filters__label">Search Proposal ID</label>
            <input
              className="dispute-filters__input"
              type="search"
              onChange={(e) => handleSearch(categories.proposalID, e)}
            />
            {loaderDisputeList && (
              <div className="dispute-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderDisputeList}
                />
              </div>
            )}
          </li>
        </ul>
        <button
          className="dispute-filters__showLess"
          onClick={() => setShowLess(!showLess)}
        >
          {showLess ? <p>Show less</p> : <p>Show all</p>}
        </button>
        {showLess && (
          <>
            <ul className="dispute-filters__list">
              <li className="dispute-filters__list-item">
                <label className="dispute-filters__label">Buyer Request</label>
                <input
                  className="dispute-filters__input"
                  type="search"
                  onChange={(e) =>
                    handleSearch(categories.buyerRequestIndex, e)
                  }
                />
                {loaderDisputeList && (
                  <div className="dispute-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderDisputeList}
                    />
                  </div>
                )}
              </li>
              <li className="dispute-filters__list-item">
                <button
                  disabled={loaderDisputeList}
                  onClick={() => togleFilter(categories.buyerVotes)}
                  className={classNames(
                    "dispute-filters__button",
                    {
                      "dispute-filters__button-active": nameFilters.buyerVotes,
                    },
                    {
                      "dispute-filters__button-open": buyerVotes,
                    }
                  )}
                >
                  Buyer Votes
                  <div className="dispute-filters__name">
                    {nameFilters.buyerVotes}
                  </div>
                </button>
                {buyerVotes && (
                  <div className="dispute-filters__names-list">
                    {showFilter(categories.buyerVotes)}
                  </div>
                )}
                {loaderDisputeList && (
                  <div className="dispute-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderDisputeList}
                    />
                  </div>
                )}
              </li>
              <li className="dispute-filters__list-item">
                <button
                  disabled={loaderDisputeList}
                  onClick={() => togleFilter(categories.sellerVotes)}
                  className={classNames(
                    "dispute-filters__button",
                    {
                      "dispute-filters__button-active": nameFilters.sellerVotes,
                    },
                    {
                      "dispute-filters__button-open": sellerVotes,
                    }
                  )}
                >
                  Seller Votes
                  <div className="dispute-filters__name">
                    {nameFilters.sellerVotes}
                  </div>
                </button>
                {sellerVotes && (
                  <div className="dispute-filters__names-list">
                    {showFilter(categories.sellerVotes)}
                  </div>
                )}
                {loaderDisputeList && (
                  <div className="dispute-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderDisputeList}
                    />
                  </div>
                )}
              </li>
              <li className="dispute-filters__list-item">
                <button
                  disabled={loaderDisputeList}
                  onClick={() => togleFilter(categories.endTime)}
                  className={classNames(
                    "dispute-filters__button",
                    {
                      "dispute-filters__button-active": nameFilters.endTime,
                    },
                    {
                      "dispute-filters__button-open": endTime,
                    }
                  )}
                >
                  Time till deadline
                  <div className="dispute-filters__name">
                    {nameFilters.endTime}
                  </div>
                </button>
                {endTime && (
                  <div className="dispute-filters__names-list">
                    {showFilter(categories.endTime)}
                  </div>
                )}
                {loaderDisputeList && (
                  <div className="dispute-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderDisputeList}
                    />
                  </div>
                )}
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
