import React, { useState, useRef } from "react";
import "./AnotherCryptocurrency.scss";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import bungee from "../../../assets/images/anotherCryptocurrency/bungee.svg";
import cbridge from "../../../assets/images/anotherCryptocurrency/cbridge.svg";
import jumper from "../../../assets/images/anotherCryptocurrency/jumper.svg";
import MetaMask from "../../../assets/images/anotherCryptocurrency/MetaMask.svg";
import multichain from "../../../assets/images/anotherCryptocurrency/multichain.webp";
import voyager from "../../../assets/images/anotherCryptocurrency/voyager-router.svg";
import wanchain from "../../../assets/images/anotherCryptocurrency/wanchain.webp";
import uniswap from "../../../assets/images/anotherCryptocurrency/Uniswap-UNI.svg";

const duration = 500;
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const AnotherCryptocurrency = () => {
  const [togleRules, setTogleRules] = useState(false);
  const nodeRef = useRef(null);

  return (
    <div className="another-cryptocurrency">
      <button
        onClick={() => setTogleRules(!togleRules)}
        className="another-cryptocurrency__button"
      >
        Another Cryptocurrency or Blockchain?
      </button>
      <Transition nodeRef={nodeRef} in={togleRules} timeout={duration}>
        {(state) => (
          <div
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {togleRules && (
              <article className="another-cryptocurrency__rules">
                <div className="another-cryptocurrency__rules-item">
                  <h2 className="another-cryptocurrency__title">
                    Need to transfer USDC to another blockchain?
                  </h2>
                  <p className="another-cryptocurrency__rule-text">
                    With the Cross-Chain Transfer Protocol (CCTP), you have the
                    ability to natively move USDC across different blockchains.
                  </p>
                  <ul className="another-cryptocurrency__block-logo">
                    <li className="another-cryptocurrency__logo">
                      <Link
                        to="https://portfolio.metamask.io/bridge"
                        target="_blank"
                      >
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={MetaMask}
                          alt="MetaMask"
                        />
                      </Link>{" "}
                    </li>
                    <li className="another-cryptocurrency__logo">
                      <Link to="https://bungee.exchange/" target="_blank">
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={bungee}
                          alt="bungee"
                        />
                      </Link>{" "}
                    </li>
                    <li className="another-cryptocurrency__logo">
                      <Link
                        to="https://cbridge.celer.network/1/43114/USDC"
                        target="_blank"
                      >
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={cbridge}
                          alt="cbridge"
                        />
                      </Link>{" "}
                    </li>
                    <li className="another-cryptocurrency__logo">
                      <Link to="https://jumper.exchange/" target="_blank">
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={jumper}
                          alt="jumper"
                        />
                      </Link>{" "}
                    </li>
                    <li className="another-cryptocurrency__logo">
                      <Link
                        to="https://usdc.multichain.org/usdc"
                        target="_blank"
                      >
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={multichain}
                          alt="multichain"
                        />
                      </Link>{" "}
                    </li>

                    <li className="another-cryptocurrency__logo">
                      <Link to="https://app.thevoyager.io/swap" target="_blank">
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={voyager}
                          alt="thevoyager"
                        />
                      </Link>{" "}
                    </li>
                    <li className="another-cryptocurrency__logo">
                      <Link to="https://bridge.wanchain.org/" target="_blank">
                        <img
                          className="another-cryptocurrency__logo-img"
                          src={wanchain}
                          alt="wanchain"
                        />
                      </Link>{" "}
                    </li>
                  </ul>
                  <h2 className="another-cryptocurrency__title">
                    Looking to swap your USDC for a different crypto?
                  </h2>
                  <p className="another-cryptocurrency__rule-text">
                    Utilize decentralized exchanges like
                    <Link to="https://uniswap.org/" target="_blank">
                      <img
                        className=" another-cryptocurrency__logo-img-uniswap"
                        src={uniswap}
                        alt="uniswap"
                      />
                    </Link>
                    to seamlessly obtain any other token in a permissionless
                    manner.
                  </p>
                </div>
              </article>
            )}
          </div>
        )}
      </Transition>
    </div>
  );
};
