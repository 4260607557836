import React, { useState, useEffect, useMemo, useRef } from "react";
import { getFirestore } from "firebase/firestore";
import classNames from "classnames";
import { getAccount } from "@wagmi/core";

import "firebase/database";
import {
  collection,
  onSnapshot,
  addDoc,
  orderBy,
  query,
  setDoc,
  doc,
  limit,
  increment,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import "./Chat.scss";
import chat from "../../assets/images/live-chat.svg";

const Chat = ({ app }) => {
  const { address } = getAccount();

  const { activeProposalsFilter } = useSelector((state) => state.buyer);
  const db = getFirestore(app);

  const [newMessage, setNewMessage] = useState([]);
  const [messages, setMessages] = useState([]);
  const [allRecipientAddresses, setAllRecipientAddresses] = useState([]);
  const [newRecipientAddresses, setNewRecipientAddresses] = useState([]);
  const [receiverAddress, setReceiverAddress] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [search, setSearch] = useState("");
  const listRef = useRef(null);
  const [countMessages, setCountMessages] = useState({});
  const [totalcount, setTotalcount] = useState("");
  const [limitMessages, seLlimitMessages] = useState(5);

  const loadOlderMessages = () => {
    seLlimitMessages((prev) => prev + 10);
  };

  const getTotalcount = (countMessages) => {
    if (countMessages !== {}) {
      const sum = Object.values(countMessages).reduce(
        (acc, curr) => acc + curr, 0);
      setTotalcount(sum);
    }
  };

  const allRecipientAddressesSearch = useMemo(() => {
    if (allRecipientAddresses) {
      if (search !== "") {
        const newAddresses = allRecipientAddresses.filter(
          (el) => el === search
        );
        return newAddresses;
      }
      return allRecipientAddresses;
    }
  }, [allRecipientAddresses, search]);

  const uniqueAddressesSeller = async () => {
    const addresses = activeProposalsFilter
      .map((proposal) => {
        if (proposal.seller !== address) {
          return proposal.seller;
        } else {
          return null;
        }
      })
      .filter(
        (addressSeller) => addressSeller !== null && addressSeller !== address
      );

    const addressesBuyer = activeProposalsFilter
      .map((proposal) => {
        if (proposal.request) {
          return proposal.request.buyer;
        } else {
          return null;
        }
      })
      .filter(
        (addressBuyer) => addressBuyer !== null && addressBuyer !== address
      );
    const allAddresses = [
      ...addresses,
      ...addressesBuyer,
      ...newRecipientAddresses,
    ];
    setAllRecipientAddresses([...new Set(allAddresses)]);
  };

  useEffect(() => {
    if (address) {
      uniqueAddressesSeller();
    }
  }, [address, activeProposalsFilter, totalcount, receiverAddress]);

  useEffect(() => {
    if (address) {
      listRef.current?.lastElementChild?.scrollIntoView();
    }
  }, [address, activeProposalsFilter]);

  const resetCount = async () => {
    try {
      await updateDoc(doc(db, "users", address, "chatUsers", receiverAddress), {
        count: 0,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDoct = async (id) => {
    await deleteDoc(
      doc(db, "users", address, "chatUsers", receiverAddress, "messages", id)
    );
  };

  useEffect(() => {
    if (receiverAddress && address) {
      const unsub = onSnapshot(
        query(
          collection(
            db,
            "users",
            address,
            "chatUsers",
            receiverAddress,
            "messages"
          ),
          orderBy("timestamp", "desc"),
          limit(limitMessages)
        ),
        (snapshot) => {
          if (snapshot.docs.length !== 0) {
            resetCount();
            const allowedTime = 86400000;
            for (let i = 0; i < snapshot.docs.length; i++) {
              const date = new Date();
              const diferent =
                date.getTime() -
                (snapshot.docs[i].data().timestamp.seconds * 1000 + 60000);
              if (diferent > allowedTime) {
                deleteDoct(snapshot.docs[i].id);
              }
            }
          }
          setMessages(
            snapshot.docs
              .map((doc) => ({
                id: doc.id,
                messages: doc.data(),
              }))
              .reverse()
          );
        }
      );

      return unsub;
    }
  }, [receiverAddress, address, limitMessages]);

  useEffect(() => {
    if (address) {
      const objectRead = {};
      const currentAddresses = [];
      const q = query(collection(db, "users", address, "chatUsers"));
      onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const countSentmessageses = change.doc.data();

          currentAddresses.push(change.doc.id);
          objectRead[change.doc.id] = countSentmessageses.count;
        });
        setCountMessages(objectRead);
        getTotalcount(objectRead);
        setNewRecipientAddresses(currentAddresses);
      });
    }
  }, [address]);

  const onReceiverAddress = (curentAddress) => {
    setReceiverAddress(curentAddress);
    seLlimitMessages(5);
  };

  const updateError = async () => {
    try {
      await setDoc(doc(db, "users", receiverAddress, "chatUsers", address), {
        count: 1,
      });

      await setDoc(doc(db, "users", address, "chatUsers", receiverAddress), {
        count: 0,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();

    try {
      await addDoc(
        collection(
          db,
          "users",
          receiverAddress,
          "chatUsers",
          address,
          "messages"
        ),
        {
          username: address,
          message: newMessage,
          timestamp: new Date(),
          read: false,
        }
      );

      await addDoc(
        collection(
          db,
          "users",
          address,
          "chatUsers",
          receiverAddress,
          "messages"
        ),
        {
          username: address,
          message: newMessage,
          timestamp: new Date(),
          read: false,
        }
      );

      await updateDoc(doc(db, "users", receiverAddress, "chatUsers", address), {
        count: increment(1),
      });
    } catch (error) {
      console.error(error);
      updateError();
    }
    setNewMessage("");
  };

  return (
    <div className="chat">
      {openChat && (
        <div className="chat__container">
          <div className="chat__nav-bar">
            <input
              className="chat__search"
              type="search"
              placeholder="Search..."
              onChange={(event) => setSearch(event.target.value)}
            />
            {allRecipientAddressesSearch &&
              allRecipientAddressesSearch?.map((address, index) => (
                <div className="chat__block-address" key={index}>
                  <button
                    onClick={() => onReceiverAddress(address)}
                    className={classNames("chat__adress", {
                      "chat__adress-active": receiverAddress === address,
                    })}
                  >
                    {address}
                    {countMessages[address] > 0 && (
                      <div className="chat__block-address-count">
                        {countMessages[address]}
                      </div>
                    )}
                  </button>
                </div>
              ))}
          </div>
          {receiverAddress ? (
            <div className="chat__block-content">
              <div className="chat__block-messagesses-container">
                <div className="chat__block-messagesses" ref={listRef}>
                  <p className="chat__caveat">
                    This chat may not be secure; do not share sensitive data.
                    Messages older than 24 hours will be deleted for security
                    purposes.
                  </p>

                  <button
                    onClick={loadOlderMessages}
                    className="chat__load-older-messages"
                  >
                    Load older messages
                  </button>
                  {messages &&
                    messages?.map((element, index) => (
                      <div
                        key={index}
                        className={classNames("chat__block-content-item", {
                          "chat__block-content-item-my":
                            element.messages.username === address,
                        })}
                      >
                        <div
                          className={classNames("chat__message", {
                            "chat__message-my":
                              element.messages.username === address,
                          })}
                        >
                          {element.messages.message}
                        </div>

                        <div
                          className={classNames("chat__time-message", {
                            "chat__time-message-my":
                              element.messages.username === address,
                          })}
                        >
                        {new Date(
                            element.messages.timestamp.toDate()
                          ).getUTCHours() + 3}
                          :
                          {new Date(
                            element.messages.timestamp.toDate()
                          ).getUTCMinutes()}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="chat__block-sendMessage">
                <input
                  className="chat__inpute-message"
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <button
                  className="chat__send"
                  onClick={(event) => handleSendMessage(event)}
                >
                  Send
                </button>
              </div>
            </div>
          ) : (
            <div className="chat__block-content-hiden"></div>
          )}
        </div>
      )}
      <div className="chat__block-open-chat">
        <button
          onClick={() => setOpenChat(!openChat)}
          className="chat__open-chat"
        >
          <img className="chat__open-chat-img" src={chat} alt="chat" />
        </button>
        {totalcount > 0 && <div className="chat__totalcount">{totalcount}</div>}
      </div>
    </div>
  );
};

export default Chat;
