export const addressesPolygon = {
    CompletedTransactions_ADDRESS: "0x8F2045D4051aD90418050f39B49EE9C9f0A1f63c",
    DisputeResolution_ADDRESS: '0x52eBaAd32f1d6D1a1622Fc9508E82ac7c931F871',
    FinjaToken_ADDRESS: '0x5f57Cb9c975703364fdd7444dD426aFc11aaC5b3',
    MainContract_ADDRESS: '0xdf91533cdcFdd5C6C11C9193FBc677020F7739Bb',
    PaymentMethods_ADDRESS: '0x9b67Bb87d59D08e5aabc4DB2513831e9E37fE258',
    ProposalsAndRequests_ADDRESS: '0x2D950e17c8175124D98b46D1909419B8373DD28C',
    USDC_ADDRESS: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  }
  
  export const addressesPolygonMumbai = {
    CompletedTransactions_ADDRESS: "0x4C1004E90e32Da48b448ba189957F3667c482B82",
    DisputeResolution_ADDRESS: "0x1695dCEB4A84f5C7EcdE8b99d36fD3B794F00803",
    FinjaToken_ADDRESS: "0xdD18bC7319c2418267e839ebf3E544C12DB4A4d9",
    MainContract_ADDRESS: "0x23673D43Ab9D2bea9581b86EfF0b3F18C8C0D513",
    PaymentMethods_ADDRESS: "0x7b4781290Bee16e9bc3b2C93896BDf4Ba17441a0",
    ProposalsAndRequests_ADDRESS: "0x66bc6643b05c1672B2C82e1bf05511BF83189677",
    USDC_ADDRESS: "0xA285Fe78b1378c65771687BDA5F33b43A66B04c0",
    USDCDistributor_ADDRESS: "0x9BD658345A0e30718d81a567095A23F349b39164"
  }
  
  export const addressesArbitrumGoerli = {
    CompletedTransactions_ADDRESS: "0xbe5e8726B143bec711c89f4df79Adf66f3b24393",
    DisputeResolution_ADDRESS: "0x16bbf39EE4c6a58A6B21E9F423FF5b502210Ce59",
    FinjaToken_ADDRESS: "0x6342edEe02C5e021f74Dd9FFd943D202bc54cF15",
    MainContract_ADDRESS: "0x10f80d5f949ba4D91a714b73054ADb09C51476fF",
    PaymentMethods_ADDRESS: "0xECFB5b77bd64e8baBB276d941dB954F2a9504Cc3",
    ProposalsAndRequests_ADDRESS: "0xDff65aF3580792f598Dee399a0589f0c81d2dB0f",
    USDC_ADDRESS: "0x481F937e4b91a634B59A2327b17ca129fca26754",
    USDCDistributor_ADDRESS: "0x3BdA8C6366E82E3049EF60b623e4A03baD8b70A7"
  }

  export const addressesArbitrumOne = {
    CompletedTransactions_ADDRESS: "0xECFB5b77bd64e8baBB276d941dB954F2a9504Cc3",
    DisputeResolution_ADDRESS: "0xDff65aF3580792f598Dee399a0589f0c81d2dB0f",
    FinjaToken_ADDRESS: "0x481F937e4b91a634B59A2327b17ca129fca26754",
    MainContract_ADDRESS: "0x16bbf39EE4c6a58A6B21E9F423FF5b502210Ce59",
    PaymentMethods_ADDRESS: "0x6342edEe02C5e021f74Dd9FFd943D202bc54cF15",
    ProposalsAndRequests_ADDRESS: "0xbe5e8726B143bec711c89f4df79Adf66f3b24393",
    USDC_ADDRESS: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    USDCDistributor_ADDRESS: null
  }
  
  export const addressesBaseGoreli = {
    CompletedTransactions_ADDRESS: null,
    DisputeResolution_ADDRESS: null,
    FinjaToken_ADDRESS: "0xAF2DFCA4c2F3d72081a6bA7394cAd1D64afcEB28",
    MainContract_ADDRESS: null,
    PaymentMethods_ADDRESS: null,
    ProposalsAndRequests_ADDRESS: null,
    USDC_ADDRESS: "0x0Ecdf02bF8Aa9c76934D452F18F62294DC662c9E",
    USDCDistributor_ADDRESS: "0x70bA9317cbBD63359622632E39aA4056c848F08F"
  }