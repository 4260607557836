import "./GetUSDCButton.scss";
import { writeContract } from "@wagmi/core";
import { USDCDistributor_ABI } from "../../../abis/USDCDistributor";
import { getAccount } from "@wagmi/core";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {handledError} from "../../../utils/handledError";
import {allowedChainsGetTestUSDC} from "../../../data/Chains";

const GetUSDCButton = () => {
  const { address } = getAccount();
  const { chainsName, USDCDistributor_ADDRESS } = useSelector((state) => state.contractsAddress);

  const showSuccessNotification = (message) => {
    toast.success(message);
  };

  const showErrorNotification = (message) => {
    toast.error(message);
  };

  const GetUSDS = async () => {
    const alovedCain = allowedChainsGetTestUSDC.includes(chainsName) ? true : false;
    if(alovedCain) {
    try {
      await writeContract({
        address: USDCDistributor_ADDRESS,
        abi: USDCDistributor_ABI,
        functionName: "claimUSDC",
        account: address,
      });
      showSuccessNotification("Successfully received usdc");
    } catch (error) {
      console.error(error);
      console.error("Error receiving USDC:", error);
      const returnError = handledError("Error receiving USDC:", error);
      showErrorNotification(returnError);
    }
  } else {
    showErrorNotification("Error: Select an available network");
  }
  };

  return (
    <>
      <ToastContainer />
        <button className="get-USDC-button" onClick={GetUSDS}>
          Get 100 test USDC
        </button>
    </>
  );
};

export default GetUSDCButton;
