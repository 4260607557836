import { combineReducers, configureStore } from "@reduxjs/toolkit";
import sellerSlice from "./reducers/sellerProposals/sellerSlice";
import createProposalSlice from "./reducers/createProposal/createProposalSlice";
import buyerSlice from "./reducers/buyerProposals/buyerSlice";
import disputeSlice from "./reducers/dispute/disputeSlice";
import contractsAddressSlice from "./reducers/contractsAddress/contractsAddressSlice";

const rootReducer = combineReducers({
  createProposal: createProposalSlice,
  seller: sellerSlice,
  buyer: buyerSlice,
  dispute: disputeSlice,
  contractsAddress: contractsAddressSlice,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};
