import React, { useState } from "react";
import "./Dispute.scss";
import { Link } from "react-router-dom";
import discord_logo from "../../../assets/images/discord_logo.svg";
import checkmark_tick_grin from "../../../assets/images/checkmark_tick_grin.svg";
import cross from "../../../assets/images/cross.svg";
import FieldDescription from "../../../UI/FieldDescription/FieldDescription";
import { useDispatch, useSelector } from "react-redux";
import {  getAccount } from "@wagmi/core";
import {
  resolveDispute,
  claimTokens,
} from "../../../store/reducers/dispute/disputeActions";

export const Dispute = ({ dispute, time }) => {
  const [showLess, setShowLess] = useState(false);
  const dispatch = useDispatch();
  const { address } = getAccount();
  
  const {
    DisputeResolution_ADDRESS,
 
  } = useSelector((state) => state.contractsAddress);

  const copyToClipboard = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div className="dispute">
      <ul className="dispute__list dispute__list-main">
        <li className="dispute__list-item">
          <span className="dispute__lable">Dispute ID</span>
          <p className="dispute__value-main">{dispute.disputeId}</p>
        </li>
        <ul className="dispute__list-main-item">
          <li className="dispute__list-item">
            <span className="dispute__lable">Buyer</span>
            <p className="dispute__value-main">
              {dispute.buyer.substring(0, 5)}...
              <button
                onClick={() => copyToClipboard(dispute.buyer)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </p>
          </li>
          <li className="dispute__list-item">
            <span className="dispute__lable">Seller</span>
            <p className="dispute__value-main">
              {dispute.seller.substring(0, 5)}...
              <button
                onClick={() => copyToClipboard(dispute.seller)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </p>
          </li>
        </ul>
      </ul>
      <ul className="dispute__list dispute__list-other">
        <ul className="dispute__list-block">
          <li className="dispute__list-item">
            <span className="dispute__lable">Buyer Votes</span>
            <p className="dispute__value">{dispute.buyerVotes}</p>
          </li>
          <li className="dispute__list-item">
            <span className="dispute__lable">Seller Votes</span>
            <p className="dispute__value">{dispute.sellerVotes}</p>
          </li>

          <li className="dispute__list-item ">
            <span className="dispute__lable ">Time till deadline</span>
            <p className="dispute__value"> {time} </p>
          </li>
        </ul>
        <ul className="dispute__list-block">
          <li className="dispute__list-item dispute__hiden-phone">
            <span className="dispute__lable">Dispute Resolved</span>
            <p className="dispute__value">
              {dispute.resolved ? (
                <>
                  <img
                    className="dispute__value-img"
                    src={checkmark_tick_grin}
                    alt="tick"
                  />{" "}
                </>
              ) : (
                <>
                  <img className="dispute__value-img" src={cross} alt="cross" />
                </>
              )}
            </p>
          </li>

          <li className="dispute__list-item dispute__hiden-phone">
            <span className="dispute__lable">Buyer Request</span>
            <p className="dispute__value">
              {dispute.buyerRequestIndex}
              <button
                onClick={() => copyToClipboard(dispute.buyerRequestIndex)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </p>
          </li>
          <li className="dispute__list-item dispute__hiden-phone">
            <span className="dispute__lable">Proposal ID</span>
            <p className="dispute__value">{dispute.proposalID}</p>
          </li>
        </ul>
      </ul>
      <button
        className="dispute__showLess dispute__hiden-desktop"
        onClick={() => setShowLess(!showLess)}
      >
        {showLess ? <p>Show less</p> : <p>Show all</p>}
      </button>
      {showLess && (
        <ul className="dispute__list dispute__hiden-desktop">
          <li className="dispute__list-item">
            <span className="dispute__lable">Proposal ID</span>
            <p className="dispute__value">{dispute.proposalID}</p>
          </li>

          <li className="dispute__list-item">
            <span className="dispute__lable">Buyer Request</span>
            <p className="dispute__value">{dispute.buyerRequestIndex}</p>
          </li>
          <li className="dispute__list-item">
            <span className="dispute__lable">Dispute Resolved</span>
            <p className="dispute__value">
              {dispute.resolved ? (
                <>
                  <img
                    className="dispute__value-img"
                    src={checkmark_tick_grin}
                    alt="tick"
                  />{" "}
                </>
              ) : (
                <>
                  <img className="dispute__value-img" src={cross} alt="cross" />
                </>
              )}
            </p>
          </li>
        </ul>
      )}
      <ul className="dispute__list-button">
        <li className="dispute__list-item dispute__list-item-discord">
          <p>Discuss this dispute on Discord</p>
          <Link to="https://discord.gg/n5kUCkJjHs" target="_blank">
            <img
              className="header__menu-phone-img-discord"
              src={discord_logo}
              alt="discord_logo"
            />
          </Link>
        </li>
        <li className="dispute__list-item">
          {!dispute.resolveDisputeFunctionTriggered && (
            <button
              className="dispute__button  dispute__button-resolve-dispute"
              onClick={() =>
                dispatch(resolveDispute(dispute.disputeId, address, DisputeResolution_ADDRESS))
              }
              disabled={time !== "Voting is closed"}
            >
              Resolve dispute
              <FieldDescription nameField={"resolveDispute"} />
            </button>
          )}
        </li>
        <li className="dispute__list-item">
          {dispute.resolveDisputeFunctionTriggered && (
            <button
              className="dispute__button"
              onClick={() => dispatch(claimTokens(dispute.disputeId, address, DisputeResolution_ADDRESS))}
            >
              Claim Tokens
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};
