import React, { useEffect, useState } from "react";
import FAQ from "./FAQ";
import "./FAQ.scss";

export const FAQList = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What is finja.exchange?",
      answer:
        "Finja.exchange is a decentralized exchange platform that enables fiat-to-crypto transactions (specifically USDC on Polygon) supported by Proof of Stake arbitration for resolving any issues. With Finja, there is no need for registration, KYC, or permission to trade - it's accessible to all.",
      open: false,
    },
    {
      question:
        "How does the decentralized fiat-to-crypto exchange process work?",
      answer:
        "The fiat-to-crypto exchange operates as a peer-to-peer (P2P) transaction, wherein one user acts as the buyer and the other as the seller of cryptocurrency. The seller places their crypto into an escrow smart contract, and the buyer can retrieve it once the seller confirms the receipt of the fiat currency payment. If any complications arise, users have the option to raise a dispute, which will then be addressed by our Arbiters.",
      open: false,
    },
    {
      question: "What crypto assets are available on Finja?",
      answer:
        "Currently, we only support USDC on Polygon. We chose USDC due to its stable exchange rate and minimal volatility. The Polygon blockchain ensures low-cost transaction processing (often less than $0.01 per transaction) and facilitates the seamless transfer of USDC to other networks. Finja is designed to serve as an on and off ramp for crypto trading. If users need a different crypto or blockchain, they can convert or swap their USDC using existing solutions (explained further below).",
      open: false,
    },
    {
      question: "What payment methods are available on Finja.exchange?",
      answer:
        `Finja.exchange provides a wide array of traditional and alternative payment options, including major credit cards (card-to-card transactions), PayPal, Payoneer, wire transfer as well as unconventional methods like paying with Google Play or Amazon gift cards.
        If there's a specific method that you'd like to see added, we invite you to make suggestions on our Discord server.`,
      open: false,
    },
    {
      question:
        "How can I get USDC on another blockchain (Ethereum, Optimism, Avalanche etc)?",
      answer:
        "Users can transfer their USDC across different chains using tools like MetaMask, bungee.exchange, jumper.exchange, or any similar bridge. USDC supports the Cross-Chain Transfer Protocol, which allows for the native movement of USDC from one chain to another. You can learn more about this here.",
      open: false,
    },
    {
      question: "How can I get another crypto (ETH, BTC etc)?",
      answer:
        "Users can convert their USDC to other cryptos using well-established decentralized crypto exchanges like Uniswap, Sushiswap, or others. We don't intend to compete with or replace these efficiently operating decentralized crypto exchanges. Our primary focus lies on facilitating crypto-fiat transactions.",
      open: false,
    },
    {
      question: "Who are the arbiters and what is their role?",
      answer:
        "Arbiters are key participants in resolving disputes on the exchange. Any Finja token holder can serve as an Arbiter. They intervene when a conflict arises between a buyer and a seller and a dispute resolution request is made. Arbiters stake their tokens to back either the buyer's or seller's argument, and evaluate the evidence provided by both parties to reach a decision. A portion of the staked tokens from the losing side is then redistributed to the supporters of the winning side.",
      open: false,
    },
    {
      question: "How are arbiters rewarded?",
      answer:
        "Arbiters earn additional Finja tokens as rewards by staking their tokens during arbitration. In the future, token holders will receive a percentage of the exchange's commission, proportional to their token holdings. This approach ensures both immediate (rewards from backing the winning side) and long-term (commission from the exchange) incentives.",
      open: false,
    },
    {
      question: "How is Finja regulated?",
      answer:
        "The Finja platform itself does not directly engage in crypto-to-fiat exchanges and therefore is not subject to crypto regulations. Instead, Finja serves as a tool that facilitates peer-to-peer crypto exchanges. Nevertheless, all fiat transactions must strictly comply with regulations applicable in their respective jurisdictions. Compliance is ensured by the banking or payment institutions that provide the payment services.",
      open: false,
    },
    {
      question: "Are crypto-fiat transactions done anonymously?",
      answer:
        "Yes, finja.exchange facilitates anonymous transactions. There no KYC requirements, no personal information, such as email addresses or phone numbers, is required to conduct transactions on the platform.",
      open: false,
    },
  ]);

  const [indices, setIndices] = useState([]);

  const toggleFAQ = (index) => {
    if (indices.includes(index)) {
      const filterIndices = indices.map(i => {
        if (i !== index) {
          return i;
        }
      });
      setIndices(filterIndices);
    } else {
      setIndices([...indices, index]);
    }
  };
  useEffect(() => {
    setFaqs(
      faqs.map((faq, i) => {
        if (indices.includes(i)) {
          faq.open = true;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  }, [indices]);

  return (
    <div className="faqs">
        <h1 className="fags-title">FAQ</h1>
      <div >
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};
