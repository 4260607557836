import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeProposalsSeller: [],
  completedProposalsSeller: [],
  loaderActiveProposalsSeller: false,
  loaderCompletedProposalsSeller: false,
  idForNewProposalSeller: "",
  idForUpdateProposalSeller: [],
  idForDeleteProposalSeller: "",
  idForProposalCompletedSeller: [],
  errorSeller: "",
  messageSeller: "",
  dataToRequests: {},
};

const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    fetchActiveProposalsSeller: (state) => {
      state.activeProposalsSeller = [];
      state.loaderActiveProposalsSeller = true;
    },

    fetchActiveProposalsSuccessSeller: (state, action) => {
      state.activeProposalsSeller = [
        ...state.activeProposalsSeller,
        action.payload,
      ];
    },

    fetchActiveProposalsSuccessAllSeller: (state) => {
      state.loaderActiveProposalsSeller = false;
    },

    fetchActiveProposalsFailureSeller: (state, action) => {
      state.loaderActiveProposalsSeller = false;
      state.errorSeller = action.payload;
    },
    
    fetchCompletedProposalsSeller: (state) => {
      state.completedProposalsSeller = [];
      state.loaderCompletedProposalsSeller = true;
    },

    fetchCompletedProposalsSuccessSeller: (state, action) => {
      state.completedProposalsSeller = [
        ...state.completedProposalsSeller,
        action.payload,
      ];
    },

    fetchCompletedProposalsSuccessAllSeller: (state) => {
      state.loaderCompletedProposalsSeller = false;
    },

    fetchCompletedProposalsFailureSeller: (state, action) => {
      state.loaderCompletedProposalsSeller = false;
      state.errorSeller = action.payload;
    },

    cleanActiveProposalsSeller: (state) => {
      state.activeProposalsSeller = [];
      state.completedProposalsSeller = [];
    },

    addDataToRequestsSuccess: (state, action) => {
      state.dataToRequests = action.payload;
    },

    addIdForNewProposalSellerSuccess: (state, action) => {
      state.idForNewProposalSeller = action.payload;
    },

    addIdForUpdateProposalSellerSuccess: (state, action) => {
      state.idForUpdateProposalSeller = [
        ...state.idForUpdateProposalSeller,
        action.payload,
      ];
    },

    addIdForDeleteProposalSellerSuccess: (state, action) => {
      state.idForDeleteProposalSeller = action.payload;
    },

    addIdForProposalCompletedSellerSuccess:  (state, action) => {
      state.idForProposalCompletedSeller = [
        ...state.idForProposalCompletedSeller,
        action.payload,
      ];
      
    },

    // addMessageForUpdateProposalSeller: (state, action) => {
    //   state.messageSeller = action.payload;
    // },

    addNewProposalSellerSucces: (state, action) => {
      state.activeProposalsSeller = action.payload;
      state.idForNewProposalSeller = "";
    },
   
    updateActiveProposalsSeller: (state, action) => {
      state.activeProposalsSeller = action.payload;
      state.idForUpdateProposalSeller = [];
      state.messageSeller = "";
    },
    
    deleteProposalSucces: (state, action) => {
      state.activeProposalsSeller = action.payload;
      state.idForDeleteProposalSeller = '';
    },

    acceptBuyerRequestApprove: (state, action) => {
      state.errorSeller = action.payload;
    },

    acceptBuyerRequestSuccess: (state, action) => {
      state.messageSeller = action.payload;
    },

    acceptBuyerRequestFailure: (state, action) => {
      state.errorSeller = action.payload;
    },
    releaseCryptoSuccess: (state, action) => {
      state.messageSeller = action.payload;
    },
    releaseCryptoFailure: (state, action) => {
      state.errorSeller = action.payload;
    },
    createDisputeSuccess: (state, action) => {
      state.messageSeller = action.payload;
    },
    createDisputeFailure: (state, action) => {
      state.errorSeller = action.payload;
    },
    requestCancellationSuccess: (state, action) => {
      state.messageSeller = action.payload;
    },
    requestCancellationFailure: (state, action) => {
      state.errorSeller = action.payload;
    },
    deactivateProposalSuccess: (state, action) => {
      state.messageSeller = action.payload;
    },
    deactivateProposalFailure: (state, action) => {
      state.errorSeller = action.payload;
    },
    cleanMessageSuccess: (state) => {
      state.messageSeller = "";
    },
    cleanErrorSuccess: (state) => {
      state.errorSeller = "";
    },
  },
});

export const {
  fetchActiveProposalsSeller,
  fetchActiveProposalsSuccessSeller,
  fetchActiveProposalsSuccessAllSeller,
  fetchActiveProposalsFailureSeller,
  fetchCompletedProposalsSeller,
  fetchCompletedProposalsSuccessSeller,
  fetchCompletedProposalsSuccessAllSeller,
  fetchCompletedProposalsFailureSeller,
  cleanActiveProposalsSeller,
  addDataToRequestsSuccess,

  addIdForNewProposalSellerSuccess,
  addIdForUpdateProposalSellerSuccess,
  addIdForDeleteProposalSellerSuccess,
  addIdForProposalCompletedSellerSuccess,
  // addMessageForUpdateProposalSeller,
  addNewProposalSellerSucces,
  updateActiveProposalsSeller,
  deleteProposalSucces,
  acceptBuyerRequestApprove,
  acceptBuyerRequestSuccess,
  acceptBuyerRequestFailure,
  releaseCryptoSuccess,
  releaseCryptoFailure,
  createDisputeSuccess,
  createDisputeFailure,
  requestCancellationSuccess,
  requestCancellationFailure,
  deactivateProposalSuccess,
  deactivateProposalFailure,
  cleanMessageSuccess,
  cleanErrorSuccess,
  
} = sellerSlice.actions;

export default sellerSlice.reducer;
