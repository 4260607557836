import React, { useState, useRef } from "react";
import "./DisputeResolutionList.scss";
import { Dispute } from "../Dispute/Dispute";
import { Transition } from "react-transition-group";
import { DisputeVote } from "../../../components/DisputeResolution/DisputeVote/DisputeVote";
import { DisputeRulesPhone } from "../../../components/DisputeResolution/DisputeRulesPhone/DisputeRulesPhone";
import { DisputeRulesDesktop } from "../../../components/DisputeResolution/DisputeRulesDesktop/DisputeRulesDesktop";
import { DisputeFilters } from "../../../components/DisputeResolution/DisputeFilters/DisputeFilters";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";

const duration = 500;
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const DisputeResolutionList = () => {
  const [togleRules, setTogleRules] = useState(false);
  const nodeRef = useRef(null);
  const { disputesListFilter, loaderDisputeList, timeActiveDispute } =
    useSelector((state) => state.dispute);

  return (
    <div className="dispute-resolution">
      <DisputeVote />
      <button
        onClick={() => setTogleRules(!togleRules)}
        className="dispute-resolution__rules-button"
      >
        How do disputes work?
      </button>
      <Transition nodeRef={nodeRef} in={togleRules} timeout={duration}>
        {(state) => (
          <div
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {togleRules && <DisputeRulesDesktop />}
          </div>
        )}
      </Transition>
      <DisputeRulesPhone />
      <h2 className="dispute-resolution__title">Disputes</h2>
      <DisputeFilters />
      <div className="dispute-resolution__disputes">
        {disputesListFilter.map((dispute, index) => (
          <div
            key={dispute.disputeId}
            className="dispute-resolution__dispute-item"
          >
            <Dispute dispute={dispute} time={timeActiveDispute[index]} />
          </div>
        ))}
      </div>
      {loaderDisputeList && (
        <div className="loader">
          <BeatLoader color="#00ffff" loading={loaderDisputeList} />
        </div>
      )}
    </div>
  );
};
