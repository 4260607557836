import React, {useMemo} from "react";
import "./SellerProposaslList.scss";
import { SellerProposal } from "../SellerProposal/SellerProposal";
import { SellerProposalNoHasRequest } from "../SellerProposal/SellerProposalNoHasRequest";
import { SellerProposalCompleted } from "../SellerProposal/SellerProposalCompleted";
import { CreateProposal } from "../../../components/EscrowSeller/CreateProposal/CreateProposal";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export const SellerProposaslList = () => {
  const {
    activeProposalsSeller,
    loaderActiveProposalsSeller,
    completedProposalsSeller,
    loaderCompletedProposalsSeller,
    dataToRequests
  } = useSelector((state) => state.seller);

  const sortProposals = useMemo(() => {
    const activeProposalsFilterSort = activeProposalsSeller.reduce(
      (sortedProposals, proposal) => {
        if (proposal.request) {
          sortedProposals.unshift(proposal);
        } else {
          sortedProposals.push(proposal);
        }
        return sortedProposals;
      },
      []
    );

    return activeProposalsFilterSort;
  }, [activeProposalsSeller]);

  return (
    <div className="prooals">
      <CreateProposal />
      <Tabs>
        <TabList>
          <Tab>My proposals</Tab>
          <Tab>Completed Transactions</Tab>
        </TabList>

        <TabPanel>
          {sortProposals?.map((proposal, index) => {
            const hasRequests = proposal?.requests?.length > 0;
            return (
              <div key={proposal.id}>
                {proposal?.requests?.map((request) => (
                  <div
                    className="prooals__proposal-item"
                    key={proposal.id + request.buyerRequestIndex}
                  >
                    <SellerProposal
                      proposal={proposal}
                      request={request}
                      dataToRequests={dataToRequests[proposal.id]}
                    />
                  </div>
                ))}
                {!hasRequests && (
                  <div className="prooals__proposal-item">
                    <SellerProposalNoHasRequest proposal={proposal} />
                  </div>
                )}
              </div>
            );
          })}
          {loaderActiveProposalsSeller && (
            <div className="loader">
              <BeatLoader
                color="#00ffff"
                loading={loaderActiveProposalsSeller}
              />
            </div>
          )}
        </TabPanel>
        <TabPanel>
          {completedProposalsSeller?.map((proposal) => {
            return (
              <div className="prooals__proposal-item" key={proposal.date}>
                <SellerProposalCompleted proposal={proposal} />
              </div>
            );
          })}
          {loaderCompletedProposalsSeller && (
            <div className="loader">
              <BeatLoader
                color="#00ffff"
                loading={loaderCompletedProposalsSeller}
              />
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};
