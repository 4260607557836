import { useEffect } from "react";
import USDCFaucetList from "../components/USDCFaucet/USDCFaucetList/USDCFaucetList";

const USDCFaucet = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return <USDCFaucetList />;
};

export default USDCFaucet;
