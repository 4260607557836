import {
  fetchCompletedTransactions_ADDRESS,
  fetchDisputeResolution_ADDRESS,
  fetchFinjaToken_ADDRESS,
   fetchMainContract_ADDRESS,
  fetchPaymentMethods_ADDRESS,
  fetchProposalsAndRequests_ADDRESS,
  fetchUSDC_ADDRESS,
  fetchUSDCDistributor_ADDRESS,
  fetchChainsID,
  fetchAllChains,
  getAllowedChainEscrow
} from "./contractsAddressSlice";
import { chains } from "../../../data/Chains";
import { chainsNames } from "../../../data/Chains";
import { allowedChainsEscrow, allowedChainsEscrowTest } from "../../../data/Chains";
import {
  addressesPolygon, 
  addressesPolygonMumbai, 
  addressesArbitrumGoerli, 
  addressesBaseGoreli, 
  addressesArbitrumOne} from "../../../data/ContractAdresses";

export const getNetwork = (network) =>  (dispatch) => {
  dispatch(fetchChainsID(chainsNames[network.chain.id]));
  dispatch(fetchAllChains(network.chains));
 
}

export const getContractsAddress = (network) => (dispatch) => {
     switch (network.chain.id) {
        case chains.polygon:
          dispatch(fetchCompletedTransactions_ADDRESS(addressesPolygon.CompletedTransactions_ADDRESS));
          dispatch(fetchDisputeResolution_ADDRESS(addressesPolygon.DisputeResolution_ADDRESS));
          dispatch(fetchFinjaToken_ADDRESS(addressesPolygon.FinjaToken_ADDRESS));
          dispatch(fetchMainContract_ADDRESS(addressesPolygon.MainContract_ADDRESS));
          dispatch(fetchPaymentMethods_ADDRESS(addressesPolygon.PaymentMethods_ADDRESS));
          dispatch(fetchProposalsAndRequests_ADDRESS(addressesPolygon.ProposalsAndRequests_ADDRESS));
          dispatch(fetchUSDC_ADDRESS(addressesPolygon.USDC_ADDRESS));
          break;
        case chains.polygonMumbai:
          dispatch(fetchCompletedTransactions_ADDRESS(addressesPolygonMumbai.CompletedTransactions_ADDRESS));
          dispatch(fetchDisputeResolution_ADDRESS(addressesPolygonMumbai.DisputeResolution_ADDRESS));
          dispatch(fetchFinjaToken_ADDRESS(addressesPolygonMumbai.FinjaToken_ADDRESS));
          dispatch(fetchMainContract_ADDRESS(addressesPolygonMumbai.MainContract_ADDRESS));
          dispatch(fetchPaymentMethods_ADDRESS(addressesPolygonMumbai.PaymentMethods_ADDRESS));
          dispatch(fetchProposalsAndRequests_ADDRESS(addressesPolygonMumbai.ProposalsAndRequests_ADDRESS));
          dispatch(fetchUSDC_ADDRESS(addressesPolygonMumbai.USDC_ADDRESS));
          dispatch(fetchUSDCDistributor_ADDRESS(addressesPolygonMumbai.USDCDistributor_ADDRESS));
          break;
        case chains.arbitrumGoerli:
          dispatch(fetchCompletedTransactions_ADDRESS(addressesArbitrumGoerli.CompletedTransactions_ADDRESS));
          dispatch(fetchDisputeResolution_ADDRESS(addressesArbitrumGoerli.DisputeResolution_ADDRESS));
          dispatch(fetchFinjaToken_ADDRESS(addressesArbitrumGoerli.FinjaToken_ADDRESS));
          dispatch(fetchMainContract_ADDRESS(addressesArbitrumGoerli.MainContract_ADDRESS));
          dispatch(fetchPaymentMethods_ADDRESS(addressesArbitrumGoerli.PaymentMethods_ADDRESS));
          dispatch(fetchProposalsAndRequests_ADDRESS(addressesArbitrumGoerli.ProposalsAndRequests_ADDRESS));
          dispatch(fetchUSDC_ADDRESS(addressesArbitrumGoerli.USDC_ADDRESS));
          dispatch(fetchUSDCDistributor_ADDRESS(addressesArbitrumGoerli.USDCDistributor_ADDRESS));
          break;
        case chains.arbitrum:
          dispatch(fetchCompletedTransactions_ADDRESS(addressesArbitrumOne.CompletedTransactions_ADDRESS));
          dispatch(fetchDisputeResolution_ADDRESS(addressesArbitrumOne.DisputeResolution_ADDRESS));
          dispatch(fetchFinjaToken_ADDRESS(addressesArbitrumOne.FinjaToken_ADDRESS));
          dispatch(fetchMainContract_ADDRESS(addressesArbitrumOne.MainContract_ADDRESS));
          dispatch(fetchPaymentMethods_ADDRESS(addressesArbitrumOne.PaymentMethods_ADDRESS));
          dispatch(fetchProposalsAndRequests_ADDRESS(addressesArbitrumOne.ProposalsAndRequests_ADDRESS));
          dispatch(fetchUSDC_ADDRESS(addressesArbitrumOne.USDC_ADDRESS));
         break;  
        case chains.base:
          dispatch(fetchCompletedTransactions_ADDRESS(addressesBaseGoreli.CompletedTransactions_ADDRESS));
          dispatch(fetchDisputeResolution_ADDRESS(addressesBaseGoreli.DisputeResolution_ADDRESS));
          dispatch(fetchFinjaToken_ADDRESS(addressesBaseGoreli.FinjaToken_ADDRESS));
          dispatch(fetchMainContract_ADDRESS(addressesBaseGoreli.MainContract_ADDRESS));
          dispatch(fetchPaymentMethods_ADDRESS(addressesBaseGoreli.PaymentMethods_ADDRESS));
          dispatch(fetchProposalsAndRequests_ADDRESS(addressesBaseGoreli.ProposalsAndRequests_ADDRESS));
          dispatch(fetchUSDC_ADDRESS(addressesBaseGoreli.USDC_ADDRESS));
          dispatch(fetchUSDCDistributor_ADDRESS(addressesBaseGoreli.USDCDistributor_ADDRESS));
          break;
         
        default:
          console.log("Contracts null");
      }

      let alovedCain = false;
      if (
        window.location.href.includes("finja.exchange") ||
        window.location.href.includes("finja-exchange")
      ) {
        alovedCain = allowedChainsEscrow.includes(chainsNames[network.chain.id]);
      } else {
        alovedCain = allowedChainsEscrowTest.includes(chainsNames[network.chain.id]);
      }
      dispatch(getAllowedChainEscrow(alovedCain));

};

