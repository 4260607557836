import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import discord_logo from "../../assets/images/discord_logo.svg";
import github_logo from "../../assets/images/github_logo.svg";
import twitter_logo from "../../assets/images/twitter_logo.svg";
import finja_Logo from "../../assets/images/Finja_Logo.svg";

const Footer = ({setTogleSellerBuyer}) => {
  return (
    <div className="footer">
      <div className="footer__container-top">
        <div className="footer__about">
          <div className="footer__logo">
            <Link to="/">
              <img
                className="footer__logo-img"
                src={finja_Logo}
                alt="discord_logo"
              />
            </Link>
          </div>
          <p className="footer__text">
            Decentralized Bridge from Centralized Finance
          </p>
        </div>
        <div className="footer__item">
          <div className="footer__menu-container">
          <ul className="footer__menu">
            <li className="footer__menu-item">
              <button
                className="footer__link-button"
                onClick={() => setTogleSellerBuyer("seller")}
              >
                <Link to="/escrow">Sell USDC</Link>
              </button>
            </li>
            <button
              className="footer__link-button"
              onClick={() => setTogleSellerBuyer("buyer")}
            >
              <li className="footer__menu-item">
                <Link to="/escrow">Buy USDC</Link>
              </li>
            </button>
          </ul>
          <ul className="footer__menu">
            <li className="footer__menu-item">
              <Link to="/token-governance">Token and Governance</Link>
            </li>

            <li className="footer__menu-item">
              <Link to="/dispute-resolution">Dispute Resolution</Link>
            </li>

            <li className="footer__menu-item">
              <Link to="/usdc-faucet">USDC Faucet</Link>
            </li>
          </ul>

          </div>
         
          <ul className="footer__menu-social">
            <li className="footer__menu-social-item">
              <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">
                <img
                  className="footer__menu-social-img"
                  src={discord_logo}
                  alt="discord_logo"
                />
              </Link>
            </li>
            <li className="footer__menu-social-item">
              <Link to="https://github.com/Finja-Exchange" target="_blank">
                <img
                  className="footer__menu-social-img"
                  src={github_logo}
                  alt="discord_logo"
                />
              </Link>
            </li>
            <li className="footer__menu-social-item">
              <Link to="https://twitter.com/VMachineStudio" target="_blank">
                <img
                  className="footer__menu-social-img"
                  src={twitter_logo}
                  alt="twitter_logo"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer__container-bottom">
        <p className="footer__text">2023</p>
        <Link
          to="/Fiat-Crypto_EX_the_white_paper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Whitepaper
        </Link>
      </div>
      <div className="footer__line-for-phone"></div>
    </div>
  );
};


export default Footer;