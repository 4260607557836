import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disputesList: [],
  disputesListFilter: [],
  loaderDisputeList: false,
  timeActiveDispute: [],
  idForUpdateDispute: "",
  idForNewDispute: "",
  error: "",
  message: "",
};

const disputeSlice = createSlice({
  name: "dispute",
  initialState,
  reducers: {
    fetchActiveDisputes: (state) => {
      state.loaderDisputeList = true;
      state.disputesList = [];
      state.disputesListFilter = [];
    },

    fetchActiveDisputesSuccess: (state, action) => {
      state.disputesList = [...state.disputesList, action.payload];
      state.disputesListFilter = [...state.disputesListFilter, action.payload];
    },
    fetchActiveDisputesSuccessAll: (state) => {
      state.loaderDisputeList = false;
    },

    fetchActiveDisputesFailure: (state, action) => {
      state.loaderDisputeList = false;
      state.error = action.payload;
    },

    cleanActiveDisputes: (state) => {
      state.disputesList = [];
      state.disputesListFilter = [];
    },

    addIdForNewDisputeSuccess: (state, action) => {
      state.idForNewDispute = action.payload;
    },

    addIdForUpdateDisputeSuccess: (state, action) => {
      state.idForUpdateDispute = action.payload;
    },

    addDisputesSuccess: (state, action) => {
      state.disputesListFilter = action.payload;
      state.idForNewDispute = "";
    },

    updateDisputesSuccess: (state, action) => {
      state.disputesListFilter = action.payload;
      state.idForUpdateDispute = "";
    },

    timeActiveDispute: (state, action) => {
      state.timeActiveDispute = action.payload;
    },

    claimTokensSuccess: (state, action) => {
      state.message = action.payload;
    },
    claimTokensFailure: (state, action) => {
      state.error = action.payload;
    },
    resolveDisputeSuccess: (state, action) => {
      state.message = action.payload;
    },
    resolveDisputeFailure: (state, action) => {
      state.error = action.payload;
    },

    updateFilterDisputesSuccess: (state, action) => {
      state.disputesListFilter = action.payload;
    },
    voteSuccess: (state, action) => {
      state.message = action.payload;
    },
    voteFailure: (state, action) => {
      state.error = action.payload;
    },
    cleanMessageSuccess: (state) => {
      state.message = "";
    },
    cleanErrorSuccess: (state) => {
      state.error = "";
    },
  },
});

export const {
  fetchActiveDisputes,
  fetchActiveDisputesSuccess,
  fetchActiveDisputesSuccessAll,
  fetchActiveDisputesFailure,
  updateDisputesSuccess,
  cleanActiveDisputes,
  addIdForNewDisputeSuccess,
  addDisputesSuccess,
  addIdForUpdateDisputeSuccess,
  timeActiveDispute,
  claimTokensSuccess,
  claimTokensFailure,
  resolveDisputeSuccess,
  resolveDisputeFailure,
  updateFilterDisputesSuccess,
  voteSuccess,
  voteFailure,
  cleanMessageSuccess,
  cleanErrorSuccess,
} = disputeSlice.actions;

export default disputeSlice.reducer;
