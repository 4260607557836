import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Escrow from "./pages/Escrow";
import TokenAndGovernance from "./pages/TokenAndGovernance";
import DisputeResolution from "./pages/DisputeResolution";
import USDCFaucet from "./pages/USDCFaucet";
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  polygon,
  polygonMumbai,
  baseGoerli,
  arbitrumGoerli,
  arbitrum,
} from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { alchemyProvider } from "@wagmi/core/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { useDispatch } from "react-redux";
import {
  getContractsAddress,
  getNetwork,
} from "./store/reducers/contractsAddress/contractsAddressActions";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { watchNetwork } from "@wagmi/core";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};
const app = initializeApp(firebaseConfig);

let chains = [polygon, polygonMumbai, arbitrumGoerli, baseGoerli, arbitrum];

const projectId = process.env.REACT_APP_PROJECT_ID;

const { publicClient, webSocketPublicClient } = configureChains(chains, [
  w3mProvider({ projectId }),
  alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }),
  infuraProvider({
    apiKey: process.env.REACT_APP_INFURA_KEY,
  }),
  publicProvider(),
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
  webSocketPublicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  const dispatch = useDispatch();
  const [togleSellerBuyer, setTogleSellerBuyer] = useState("seller");

  useEffect(() => {
    const unwatch = watchNetwork((network) => {
      if (network.chain) {
        dispatch(getContractsAddress(network));
        dispatch(getNetwork(network));
      }
    });

    return () => {
      unwatch?.();
    };
  }, [dispatch]);

  useEffect(() => {
    if (window.location.href.includes("finja.exchange")) {
      getAnalytics(app);
    }
  }, []);

  return (
    <div className="container">
      <WagmiConfig config={wagmiConfig}>
        <Router>
          <Header setTogleSellerBuyer={setTogleSellerBuyer} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/escrow"
              element={<Escrow togleSellerBuyer={togleSellerBuyer} app={app} />}
            />
            <Route path="/token-governance" element={<TokenAndGovernance />} />
            <Route path="/dispute-resolution" element={<DisputeResolution />} />
            <Route path="/usdc-faucet" element={<USDCFaucet />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer setTogleSellerBuyer={setTogleSellerBuyer} />
        </Router>
      </WagmiConfig>
      <div className="web3-modal">
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeVariables={{
            "--w3m-accent-color": "#44E1F2",
            "--w3m-accent-fill-color": "#010007",
          }}
        />
      </div>
    </div>
  );
}
export default App;
