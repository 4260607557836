/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SellerProposaslList } from "./SellerProposaslList/SellerProposaslList";
import { getAccount } from "@wagmi/core";
import PleaseConnect from "../PleaseConnect/PleaseConnect";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanMessage,
  cleanError,
  updateProposalsSeller,
  fetchProposalsSellerCompleted,
  addDataToRequests,
  addProposal,
  deleteProposal,
} from "../../store/reducers/sellerProposals/sellerActions";


const EscrowSeller = () => {
  const { address, isConnected } = getAccount();
   const {
    activeProposalsSeller,
    loaderActiveProposalsSeller,
    errorSeller,
    messageSeller,
    idForNewProposalSeller,
    idForUpdateProposalSeller,
    idForDeleteProposalSeller,
    idForProposalCompletedSeller,
  } = useSelector((state) => state.seller);

  const { CompletedTransactions_ADDRESS, DisputeResolution_ADDRESS, ProposalsAndRequests_ADDRESS } =
    useSelector((state) => state.contractsAddress);

  const dispatch = useDispatch();

  useEffect(() => {
    if (idForNewProposalSeller && !loaderActiveProposalsSeller) {
      dispatch(
        addProposal(idForNewProposalSeller, activeProposalsSeller, address, ProposalsAndRequests_ADDRESS)
      );
    }
  }, [idForNewProposalSeller]);

  useEffect(() => {
    if (idForDeleteProposalSeller && !loaderActiveProposalsSeller) {
      dispatch(
        deleteProposal(idForDeleteProposalSeller, activeProposalsSeller)
      );
    }
  }, [idForDeleteProposalSeller]);

  useEffect(() => {
    if (messageSeller !== "") {
      showSuccessNotification(messageSeller);
      dispatch(cleanMessage());
    }
  }, [messageSeller]);

  useEffect(() => {
    if (errorSeller !== "") {
      showErrorNotification(errorSeller);
      dispatch(cleanError());
    }
  }, [errorSeller]);

  useEffect(() => {
    if (!loaderActiveProposalsSeller && DisputeResolution_ADDRESS && CompletedTransactions_ADDRESS) {
      dispatch(
        addDataToRequests(
          activeProposalsSeller,
          DisputeResolution_ADDRESS,
          CompletedTransactions_ADDRESS
        )
      );
    }
  }, [loaderActiveProposalsSeller, DisputeResolution_ADDRESS]);

  useEffect(() => {
    if (idForUpdateProposalSeller.length && !loaderActiveProposalsSeller) {
      dispatch(
        updateProposalsSeller(
          idForUpdateProposalSeller,
          activeProposalsSeller,
          address,
          ProposalsAndRequests_ADDRESS
        )
      );
    }
  }, [idForUpdateProposalSeller]);

  useEffect(() => {
    if (idForProposalCompletedSeller.length && !loaderActiveProposalsSeller && CompletedTransactions_ADDRESS) {
      dispatch(
        fetchProposalsSellerCompleted(address, CompletedTransactions_ADDRESS)
      );
    }
  }, [idForProposalCompletedSeller, CompletedTransactions_ADDRESS]);

  const showSuccessNotification = (message) => {
    toast.success(message);
  };

  const showErrorNotification = (message) => {
    toast.error(message);
  };

  return (
    <div>
      <ToastContainer />
      {!isConnected && <PleaseConnect />}
      <SellerProposaslList />
    </div>
  );
};

export default EscrowSeller;
