import "./AwardPopup.scss";

const AwardPopup = ({ award }) => {
  return (
    <div className="award-popup">
      <div className="award-popup__container">
        <div className="award-popup__content">
          <h1 className="award-popup__title">
            You've been awarded {award} Finja Tokens for completing
            this transaction!
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AwardPopup;
