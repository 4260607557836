import React from "react";
import "./DisputeRulesDesktop.scss";
import { Link } from "react-router-dom";

export const DisputeRulesDesktop = () => {
  return (
    <div className="dispute-rules-desk">
      <article className="dispute-rules-desk__rules">
        <ul className="dispute-rules-desk__rules-item">
          <h2 className="dispute-rules-desk__title">For Buyers and Sellers</h2>
          <li className="dispute-rules-desk__rules-rule">
            <p className="dispute-rules-desk__rule-text">
              rely on community to resolve your disputes
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">1</div>
            <p className="dispute-rules-desk__rule-text">
              If a dispute arises, create a dispute and then present your
              evidence on Discord to support your position
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">2</div>
            <p className="dispute-rules-desk__rule-text">
            Finja Token holders will vote to support either the buyer's or
              the seller's position.
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">3</div>
            <p className="dispute-rules-desk__rule-text">
            The voting period is 48 hours. The winner is the side that receives 75% of the votes. 
            The winner will receive the crypto from the escrow account.
            </p>
          </li>
        </ul>

        <ul className="dispute-rules-desk__rules-item">
          <h2 className="dispute-rules-desk__title">For Token Holders</h2>
          <li className="dispute-rules-desk__rules-rule">
            <p className="dispute-rules-desk__rule-text">
              vote and get rewarded{" "}
            </p>{" "}
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">1</div>
            <p className="dispute-rules-desk__rule-text">
              Review the evidence on{" "}
              <Link to="https://discord.gg/4rfVXCmnBH" target="_blank">Discord</Link>
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">2</div>
            <p className="dispute-rules-desk__rule-text">
              Vote for either the buyer's or the seller's position by staking
              your Finja Tokens here.
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">3</div>
            <p className="dispute-rules-desk__rule-text">
              Receive a reward equivalent to 20% of your staked tokens for
              participating in the voting process.
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">4</div>
            <p className="dispute-rules-desk__rule-text">
              If you support the winning side of the dispute, you will also
              receive a share of the tokens staked by those who supported the
              losing side. If you support the losing side of the dispute, you
              will forfeit your staked tokens.
            </p>
          </li>
          <li className="dispute-rules-desk__rules-rule">
            <div className="dispute-rules-desk__rule-number">5</div>
            <p className="dispute-rules-desk__rule-text">
              The winner is the side that receives 75% of votes. If no side
              receive 75% of votes, dispute can be relaunched again.
            </p>
          </li>
        </ul>
      </article>
    </div>
  );
};
