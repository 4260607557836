export const handledError = (text, error) => {
  if (error.message.includes("reason:")) {
    const errorMessage =
      error.message.split("reason:")[1].split("Contract Call:")[0] ||
      "An error occurred";
   
     return `${text} ${errorMessage}`
   
  } else {
    
    return  `${text} An error occurred`
   
  }
  };