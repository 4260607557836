import {
  DisputeResolution_ABI,
} from "../../../abis/DisputeResolution";

import { FinjaToken_ABI } from "../../../abis/FinjaToken";

import { readContract, writeContract } from "@wagmi/core";

import { conversionData } from "../../../utils/conversionData";
import { handledError } from "../../../utils/handledError";

import {
  fetchActiveDisputes,
  fetchActiveDisputesSuccess,
  fetchActiveDisputesSuccessAll,
  fetchActiveDisputesFailure,
  addDisputesSuccess,
  updateDisputesSuccess,
  cleanActiveDisputes,
  addIdForNewDisputeSuccess,
  addIdForUpdateDisputeSuccess,
  timeActiveDispute,
  claimTokensSuccess,
  claimTokensFailure,
  resolveDisputeSuccess,
  resolveDisputeFailure,
  updateFilterDisputesSuccess,
  voteSuccess,
  voteFailure,
  cleanMessageSuccess,
  cleanErrorSuccess,
} from "./disputeSlice";

const fetchDispute = async (id, DisputeResolution_ADDRESS) => {
  try {
    const dispute = await readContract({
      address: DisputeResolution_ADDRESS,
      abi: DisputeResolution_ABI,
      functionName: "disputes",
      args: [id],
    });

   
    const newDisputesList = {
      disputeId: id,
      buyer: dispute[0],
      seller: dispute[1],
      proposalID: conversionData(dispute[2]),
      buyerRequestIndex: conversionData(dispute[3]),
      disputedAmount: conversionData(dispute[4]),
      buyerVotes: (conversionData(dispute[5]) * 10 ** -18).toFixed(2),
      sellerVotes: (conversionData(dispute[6]) * 10 ** -18).toFixed(2),
      endTime: conversionData(dispute[7]),
      resolveDisputeFunctionTriggered: dispute[8],
      resolved: dispute[9],
      winnerBuyer: dispute[10],
      
    };
    return newDisputesList;
  } catch (error) {
    console.log("fetchDisputes", error);
    return {};
  }
};

export const addIdForNewDispute = (id) => async (dispatch) => {
  dispatch(addIdForNewDisputeSuccess(id));
};

export const addIdForUpdateDispute = (id) => async (dispatch) => {
  dispatch(addIdForUpdateDisputeSuccess(id));
};

export const addDisputes =
  (updateDisputeId, disputesListFilter, DisputeResolution_ADDRESS) => async (dispatch) => {
    const dispute = await fetchDispute(updateDisputeId, DisputeResolution_ADDRESS);

    const newdisputes = [...disputesListFilter];
    newdisputes.unshift(dispute);

    console.log("newdisputes", newdisputes);

    dispatch(addDisputesSuccess(newdisputes));
  };

export const updateDisputes =
  (updateDisputeId, disputesListFilter, DisputeResolution_ADDRESS) => async (dispatch) => {
    const dispute = await fetchDispute(updateDisputeId, DisputeResolution_ADDRESS);
    const newdisputes = disputesListFilter
      .map((el) => {
        if (el.disputeId === updateDisputeId) {
          if (dispute) {
            return dispute;
          } else {
            return null;
          }
        }
        return el;
      })
      .filter((ele) => ele !== null);

    dispatch(updateDisputesSuccess(newdisputes));
  };

export const fetchDisputes = (DisputeResolution_ADDRESS) => async (dispatch) => {
  dispatch(fetchActiveDisputes());

  try {
    const disputeCount = await readContract({
      address: DisputeResolution_ADDRESS,
      abi: DisputeResolution_ABI,
      functionName: "disputeCount",
    });

    for (let i = conversionData(disputeCount) - 1; i >= 0; i--) {
      const dispute = await fetchDispute(i, DisputeResolution_ADDRESS);

      if (dispute) {
        dispatch(fetchActiveDisputesSuccess(dispute));
      }
    }

    dispatch(fetchActiveDisputesSuccessAll());
  } catch (error) {
    console.error("Error fetching active proposals:", error);
    dispatch(fetchActiveDisputesFailure("Error fetching disputes."));
  }
};

export const cleanDisputes = () => async (dispatch) => {
  dispatch(cleanActiveDisputes());
};

export const getTimeActiveDispute = (disputes) => async (dispatch) => {
  const currentDate = Math.round(new Date().getTime() / 1000);
  const result = disputes.map((dispute) => {
    const timeDifference = dispute.endTime - currentDate;

    if (timeDifference > 0) {
      const hours = String(Math.floor(timeDifference / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((timeDifference % 3600) / 60)).padStart(
        2,
        "0"
      );
      const seconds = String(timeDifference % 60).padStart(2, "0");

      return `${hours} : ${minutes} : ${seconds}`;
    } else {
      return "Voting is closed";
    }
  });

  dispatch(timeActiveDispute(result));
};

export const claimTokens = (idDisput, address, DisputeResolution_ADDRESS) => async (dispatch) => {
  try {
    await writeContract({
      address: DisputeResolution_ADDRESS,
      abi: DisputeResolution_ABI,
      functionName: "claimTokens",
      args: [idDisput],
      account: address,
    });

    dispatch(claimTokensSuccess("Successful!"));
  } catch (error) {
    console.log("Error", error);
    const returnError = handledError("Error:", error);
    dispatch(claimTokensFailure(returnError));
  }
};

export const resolveDispute = (idDisput, address, DisputeResolution_ADDRESS) => async (dispatch) => {
  try {
    await writeContract({
      address: DisputeResolution_ADDRESS,
      abi: DisputeResolution_ABI,
      functionName: "resolveDispute",
      args: [idDisput],
      account: address,
    });

    dispatch(resolveDisputeSuccess("Successful!"));
  } catch (error) {
    console.log("Dispute resolve error:", error);
    const returnError = handledError("Dispute resolve error:", error);
    dispatch(resolveDisputeFailure(returnError));
  }
};

export const updateFilterDisputes = (disputes) => async (dispatch) => {
  dispatch(updateFilterDisputesSuccess(disputes));
};

export const vote =
  (voteAmount, disputeId, inFavorOfBuyer, address, FinjaToken_ADDRESS, DisputeResolution_ADDRESS) => async (dispatch) => {
    const amountToApprove = voteAmount * 10 ** 18;

    try {
      await writeContract({
        address: FinjaToken_ADDRESS,
        abi: FinjaToken_ABI,
        functionName: "increaseAllowance",
        args: [DisputeResolution_ADDRESS, amountToApprove],
        account: address,
      });
      console.log(
        "allowance increased to: ",
        amountToApprove,
        " for dispute resolution contract"
      );
      await writeContract({
        address: DisputeResolution_ADDRESS,
        abi: DisputeResolution_ABI,
        functionName: "vote",
        args: [disputeId, inFavorOfBuyer, amountToApprove],
        account: address,
      });
      dispatch(voteSuccess("Voted successfully!"));
    } catch (error) {
      console.log("votedError", error);

      const returnError = handledError("Voting error:", error);
      dispatch(voteFailure(returnError));
    }
  };

export const cleanMessage = () => async (dispatch) => {
  dispatch(cleanMessageSuccess());
};

export const cleanMError = () => async (dispatch) => {
  dispatch(cleanErrorSuccess());
};
