import React, { useState } from "react";
import "./DisputeVote.scss";
import { getAccount, readContract } from "@wagmi/core";
import {
  DisputeResolution_ABI,
} from "../../../abis/DisputeResolution";
import { useDispatch, useSelector } from "react-redux";
import { vote } from "../../../store/reducers/dispute/disputeActions";

export const DisputeVote = () => {
  const { address } = getAccount();
  const [togleInFavor, setTogleInFavor] = useState(false);
  const [disputeId, setDisputeId] = useState("");
  const [checkVoitButton, setCheckVoitButton] = useState("");
  const [voteAmount, setVoteAmount] = useState("");
  const [inFavorOfBuyer, setInFavorOfBuyer] = useState(true);
  const dispatch = useDispatch();
  const {
    DisputeResolution_ADDRESS,
    FinjaToken_ADDRESS
  } = useSelector((state) => state.contractsAddress);

  const handleFavorOf = (event) => {
    setInFavorOfBuyer(event);
    setTogleInFavor(false);
  };

  const conversionData = (data) => {
    return Number(String(data).split("n")[0]);
  };

  const handleDisputeId = async (event) => {
    // const voidTime = 360;
    if (event) {
      const dispute = await readContract({
        address: DisputeResolution_ADDRESS,
        abi: DisputeResolution_ABI,
        functionName: "disputes",
        args: [event],
      });
      const currentTime = new Date().getTime();
      const differenceTime = conversionData(dispute[7]) * 1000 - currentTime;
      if (conversionData(dispute[7]) === 0) {
        setCheckVoitButton("Not correct Dispute ID");
      } else if (dispute[8] || differenceTime <= 0) {
        setCheckVoitButton("The voting period for this proposal has expired");
      } else {
        setCheckVoitButton("");
        setDisputeId(event);
      }
    } else {
      setCheckVoitButton("");
    }
  };

  return (
    <>
      <div className="dispute-vote">
        <h2 className="dispute-vote__title">Vote</h2>
        <div className="dispute-vote__container">
          <div className="dispute-vote__container-item">
            <div className="dispute-vote__field dispute-vote__input-amount">
              <label className="dispute-vote__label">Dispute ID</label>
              <input
                className="dispute-vote__input "
                type="number"
                onChange={(e) => handleDisputeId(e.target.value)}
              />
              <p className="dispute-vote__text">{checkVoitButton}</p>
            </div>
            <div className="dispute-vote__field  dispute-vote__input-price">
              <label className="dispute-vote__label">Vote Amount</label>
              <input
                className="dispute-vote__input"
                type="number"
                onChange={(e) => setVoteAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="dispute-vote__container-item">
            <div className="dispute-vote__input  dispute-vote__input-select dispute-vote__payment-method">
              <button
                className="dispute-vote__payment-method-button"
                onClick={() => setTogleInFavor(!togleInFavor)}
              >
                {inFavorOfBuyer === true
                  ? "In favor of Buyer"
                  : "In favor of Seller"}
              </button>
              {togleInFavor && (
                <div className="dispute-vote__payment-method-dropdown">
                  <div className="dispute-vote__dropdown">
                    <button
                      className="dispute-vote__dropdown-item"
                      onClick={() => handleFavorOf(true)}
                    >
                      In favor of Buyer
                    </button>
                    <button
                      className="dispute-vote__dropdown-item"
                      onClick={() => handleFavorOf(false)}
                    >
                      In favor of Seller
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="dispute-vote__container-item">
            <button
              className="dispute-vote__button"
              onClick={() =>
                dispatch(vote(voteAmount, disputeId, inFavorOfBuyer, address, FinjaToken_ADDRESS, DisputeResolution_ADDRESS))
              }
              disabled={!disputeId || !voteAmount}
            >
              Submit Vote
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
