import React, { useState, useEffect, useRef } from "react";
import "./BuyerProposalFilters.scss";
import useOutsideClick from "@rooks/use-outside-click";
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from 'react-redux';
import {updateFilterProposals } from "../../../store/reducers/buyerProposals/buyerActions";

export const BuyerProposalFilters = () => {
  const [showLess, setShowLess] = useState(false);
  const [togleSort, setTogleSort] = useState(false);
  const [nameSort, setNameSort] = useState("");

  const { 
    activeProposals,
    loaderActiveProposals,
  } = useSelector(state => state.buyer)
  
  const dispatch = useDispatch();

  const nameFieldSort = {
    completedTransactions: "# of completed transactions",
    date: "date",
    price: "price",
    amount: "amount",
  };

  const nameFieldFilter = {
    amount: "amount",
    id: "id",
    price: "price",
    paymentMethod: "paymentMethod",
    date: "date",
    seller: "seller",
    accepted: "accepted",
    paymentDeclared: "paymentDeclared",
    cryptoReleased: "cryptoReleased",
    escrowBalance: "escrowBalance",
    action: "action",
    transactionsCount: "transactionsCount",
  };

  const [togleFilters, setTogleFilters] = useState({
    amount: false,
    id: false,
    price: false,
    paymentMethod: false,
    date: false,
    seller: false,
    accepted: false,
    paymentDeclared: false,
    cryptoReleased: false,
    escrowBalance: false,
    action: false,
    transactionsCount: false,
  });

  const {
    amount,
    paymentMethod,
    accepted,
    paymentDeclared,
    cryptoReleased,
    escrowBalance,
    action,
    transactionsCount,
  } = togleFilters;

  const [nameFilters, setNameFilters] = useState({
    amount: "",
    id: "",
    price: "",
    paymentMethod: "",
    date: "",
    seller: "",
    accepted: "",
    paymentDeclared: "",
    cryptoReleased: "",
    escrowBalance: "",
    action: "",
    transactionsCount: "",
  });

  const typeFilterDate = {
    1: "30 minutes",
    2: "24 hours",
    3: "1 week",
    4: "2 week",
    5: "1 month",
  };

  const typeFilterAmount = {
    1: [10, "> 10"],
    2: [50, "> 50"],
    3: [100, "> 100"],
    4: [200, "> 200"],
    5: [500, "> 500"],
  };

  const typeFilterPrice = {
    1: [10, "> 10"],
    2: [50, "> 50"],
    3: [100, "> 100"],
    4: [200, "> 200"],
    5: [500, "> 500"],
  };

  const typeFilterEscrowBalance = {
    1: [10, "> 10"],
    2: [50, "> 50"],
    3: [100, "> 100"],
    4: [200, "> 200"],
    5: [500, "> 500"],
  };

  const typeFilterTransactionsCount = {
    1: [5, "> 5"],
    2: [10, "> 10"],
    3: [30, "> 30"],
    4: [50, "> 50"],
    5: [100, "> 100"],
  };

  const typeFilterAction = {
    1: "No requests to buy",
    2: "Accept request",
    3: "Waiting for the buyer to send fiat payment",
    4: "Release Crypto",
    5: "Transaction completed",
  };

  const handleSort = () => {
    setTogleSort(!togleSort);
  };

  const sortActiveProposals = (filtersElement) => {
    setNameSort(filtersElement);
    setTogleSort(!togleSort);
  };

  const clearSortActiveProposals = () => {
    setNameSort("");
    setTogleSort(!togleSort);
  };

  const togleFilter = (nameFilter) => {
    setTogleFilters((prev) => ({
      ...prev,
      [nameFilter]: !prev[nameFilter],
    }));
    for (const key in nameFilters) {
      if (key !== nameFilter) {
        setTogleFilters((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
    }
  };

  const filterActions = (proposals, date) => {
    switch (date) {
      case typeFilterAction[1]:
        return proposals.filter((proposal) => !proposal.request);
      case typeFilterAction[2]:
        return proposals.filter(
          (proposal) => proposal.request && !proposal.request.accepted
        );
      case typeFilterAction[3]:
        return proposals.filter(
          (proposal) =>
            proposal.request &&
            proposal.request.accepted &&
            !proposal.request.paymentDeclared
        );
      case typeFilterAction[4]:
        return proposals.filter(
          (proposal) =>
            proposal.request &&
            proposal.request.accepted &&
            proposal.request.paymentDeclared &&
            !proposal.request.cryptoReleased
        );
      case typeFilterAction[5]:
        return proposals.filter(
          (proposal) =>
            proposal.request &&
            proposal.request.accepted &&
            proposal.request.paymentDeclared &&
            proposal.request.cryptoReleased
        );
      default:
        return proposals;
    }
  };

  const filterByDate = (proposals, name) => {
    const nowDate = new Date().getTime();
    switch (name) {
      case typeFilterDate[1]:
        return proposals.filter(
          (proposal) => (nowDate - proposal.date * 1000) / 1000 < 1800
        );
      case typeFilterDate[2]:
        return proposals.filter(
          (proposal) => (nowDate - proposal.date * 1000) / 1000 < 86400
        );
      case typeFilterDate[3]:
        return proposals.filter(
          (proposal) => (nowDate - proposal.date * 1000) / 1000 < 604800
        );
      case typeFilterDate[4]:
        return proposals.filter(
          (proposal) => (nowDate - proposal.date * 1000) / 1000 < 1209600
        );
      case typeFilterDate[5]:
        return proposals.filter(
          (proposal) => (nowDate - proposal.date * 1000) / 1000 < 2419200
        );
      default:
        return proposals;
    }
  };

  const filterByCount = (proposals, nameFilter, key, typeFilter) => {
    switch (nameFilter) {
      case typeFilter[1][1]:
        return proposals.filter((proposal) => proposal[key] > typeFilter[1][0]);
      case typeFilter[2][1]:
        return proposals.filter((proposal) => proposal[key] > typeFilter[2][0]);
      case typeFilter[3][1]:
        return proposals.filter((proposal) => proposal[key] > typeFilter[3][0]);
      case typeFilter[4][1]:
        return proposals.filter((proposal) => proposal[key] > typeFilter[4][0]);
      case typeFilter[5][1]:
        return proposals.filter((proposal) => proposal[key] > typeFilter[5][0]);
      default:
        return proposals;
    }
  };

  const filterByrequest = (proposals, nameFilters, nameRequest) => {
    if (nameFilters === "Yes") {
      return proposals.filter(
        (proposal) => proposal.request && proposal.request[nameRequest]
      );
    } else {
      return proposals.filter(
        (proposal) => !proposal.request || !proposal.request[nameRequest]
      );
    }
  };
  

  useEffect(() => {
    let filterActiveProposals = activeProposals;
    if(nameSort !== "") {
    if (nameSort === nameFieldSort.price) {
      const resulr =  [...filterActiveProposals].sort((a, b) => a.price - b.price);
      filterActiveProposals = resulr;
    } 
    else {
      const resulr =  [...filterActiveProposals].sort((a, b) => b[nameSort] - a[nameSort]);
      filterActiveProposals = resulr;
    }
  } else {
    const resulr = filterActiveProposals.reduce(
      (sortedProposals, proposal) => {
        if (proposal.request) {
          sortedProposals.unshift(proposal);
        } else {
          sortedProposals.push(proposal);
        }
        return sortedProposals;
      },
      []
    );
      filterActiveProposals = resulr;
  }
  
    for (const key in nameFilters) {
      if (nameFilters[key] !== "") {
        if (
          key === nameFieldFilter.accepted ||
          key === nameFieldFilter.paymentDeclared ||
          key === nameFieldFilter.cryptoReleased
        ) {
          const filt = filterByrequest(
            filterActiveProposals,
            nameFilters[key],
            key
          );
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.date) {
          const filt = filterByDate(filterActiveProposals, nameFilters[key]);
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.amount) {
          const filt = filterByCount(
            filterActiveProposals,
            nameFilters[key],
            key,
            typeFilterAmount
          );
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.price) {
          const filt = filterActiveProposals.filter(
            (proposal) => Number(proposal[key]) <= Number(nameFilters[key])
          );
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.escrowBalance) {
          const filt = filterByCount(
            filterActiveProposals,
            nameFilters[key],
            key,
            typeFilterEscrowBalance
          );
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.transactionsCount) {
          const filt = filterByCount(
            filterActiveProposals,
            nameFilters[key],
            key,
            typeFilterTransactionsCount
          );
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.action) {
          const filt = filterActions(filterActiveProposals, nameFilters[key]);
          filterActiveProposals = filt;
        } else if (key === nameFieldFilter.id || key === nameFieldFilter.seller) {
          const filt = filterActiveProposals.filter((proposal) =>
            String(proposal[key]).includes(String(nameFilters[key]))
          );
          filterActiveProposals = filt;
        } else {
          const filt = filterActiveProposals.filter(
            (proposal) =>
              String(proposal[key]).toLocaleLowerCase() ===
              String(nameFilters[key]).toLocaleLowerCase()
          );
          filterActiveProposals = filt;
        }
      }
    }
    dispatch(updateFilterProposals(filterActiveProposals));
  }, [nameFilters, nameSort, activeProposals]);

  const filterActiveProposals = (nameFilter, filtersElement) => {
    setNameFilters((prev) => ({
      ...prev,
      [nameFilter]: filtersElement,
    }));

    setTogleFilters((prev) => ({
      ...prev,
      [nameFilter]: false,
    }));
  };

  const clearFilterActiveProposals = (nameFilter) => {
    setNameFilters((prev) => ({
      ...prev,
      [nameFilter]: "",
    }));
    setTogleFilters((prev) => ({
      ...prev,
      [nameFilter]: false,
    }));
  };

  const filterByNameCount = (proposal, typeFilter, filter) => {
    const arrayName = [];
    for (let i = 0; i < proposal.length; i++) {
      if (proposal[i][filter] > typeFilter[1][0]) {
        arrayName.push(typeFilter[1][1]);
      }
      if (proposal[i][filter] > typeFilter[2][0]) {
        arrayName.push(typeFilter[2][1]);
      }
      if (proposal[i][filter] > typeFilter[3][0]) {
        arrayName.push(typeFilter[3][1]);
      }
      if (proposal[i][filter] > typeFilter[4][0]) {
        arrayName.push(typeFilter[4][1]);
      }
      if (proposal[i][filter] > typeFilter[5][0]) {
        arrayName.push(typeFilter[5][1]);
      }
    }
    const result = arrayName.filter(
      (el, ind, arr) => el !== null && ind === arr.indexOf(el)
    );
    return result;
  };

  const filterByNameDate = (currentActiveProposals) => {
    const nowDate = new Date().getTime();
    const result = currentActiveProposals
      .map((proposal) => {
        const currentDate = proposal.date * 1000;
        if ((nowDate - currentDate) / 1000 < 1800) {
          return typeFilterDate[1];
        } else if ((nowDate - currentDate) / 1000 < 86400) {
          return typeFilterDate[2];
        } else if ((nowDate - currentDate) / 1000 < 604800) {
          return typeFilterDate[3];
        } else if ((nowDate - currentDate) / 1000 < 1209600) {
          return typeFilterDate[4];
        } else if ((nowDate - currentDate) / 1000 < 2419200) {
          return typeFilterDate[5];
        }
        return null;
      })
      .filter((el, ind, arr) => el !== null && ind === arr.indexOf(el));

    return result;
  };

  const filterByNameAction = (currentActiveProposals) => {
    const result = currentActiveProposals
      .map((proposal) => {
        if (!proposal.request) {
          return typeFilterAction[1];
        } else if (!proposal.request.accepted) {
          return typeFilterAction[2];
        } else if (
          proposal.request.accepted &&
          !proposal.request.paymentDeclared
        ) {
          return typeFilterAction[3];
        } else if (
          proposal.request.accepted &&
          proposal.request.paymentDeclared &&
          !proposal.request.cryptoReleased
        ) {
          return typeFilterAction[4];
        } else if (
          proposal.request.accepted &&
          proposal.request.paymentDeclared &&
          proposal.request.cryptoReleased
        ) {
          return typeFilterAction[5];
        }
        return null;
      })
      .filter((el, ind, arr) => el !== null && ind === arr.indexOf(el));

    return result;
  };

  const showFilter = (nameFilter) => {
    const currentActiveProposals = activeProposals;
    const currentnameFilter = nameFilter;
    let result;

    if (
      currentnameFilter === nameFieldFilter.accepted ||
      currentnameFilter === nameFieldFilter.paymentDeclared ||
      currentnameFilter === nameFieldFilter.cryptoReleased
    ) {
      result = currentActiveProposals
        .map((proposal) =>
          proposal.request && proposal.request[currentnameFilter] ? "Yes" : "No"
        )
        .filter((el, ind, arr) => ind === arr.indexOf(el));
    } else if (currentnameFilter === nameFieldFilter.date) {
      result = filterByNameDate(currentActiveProposals);
    } else if (currentnameFilter === nameFieldFilter.amount) {
      result = filterByNameCount(
        currentActiveProposals,
        typeFilterAmount,
        nameFieldFilter.amount
      );
    } else if (currentnameFilter === nameFieldFilter.price) {
      result = filterByNameCount(
        currentActiveProposals,
        typeFilterPrice,
        nameFieldFilter.price
      );
    } else if (currentnameFilter === nameFieldFilter.escrowBalance) {
      result = filterByNameCount(
        currentActiveProposals,
        typeFilterEscrowBalance,
        nameFieldFilter.escrowBalance
      );
    } else if (currentnameFilter === nameFieldFilter.transactionsCount) {
      result = filterByNameCount(
        currentActiveProposals,
        typeFilterTransactionsCount,
        nameFieldFilter.transactionsCount
      );
    } else if (currentnameFilter === nameFieldFilter.action) {
      result = filterByNameAction(currentActiveProposals);
    } else {
      result = currentActiveProposals
        .map((proposal) => proposal[nameFilter])
        .filter((el, ind, arr) => ind === arr.indexOf(el));
    }

    return (
      <div>
        <button
          className="buyer-filters__names-list-item"
          onClick={() => clearFilterActiveProposals(nameFilter)}
        >
          Any
        </button>
        {result?.map((filtersElement) => (
          <button
            key={filtersElement.id}
            className="buyer-filters__names-list-item"
            onClick={() => filterActiveProposals(nameFilter, filtersElement)}
          >
            {filtersElement}
          </button>
        ))}
      </div>
    );
  };

  const showSort = () => {
    return (
      <div>
        <button
          className="buyer-filters__names-list-item"
          onClick={() => clearSortActiveProposals()}
        >
          No sorting
        </button>
        <button
          className="buyer-filters__names-list-item"
          onClick={() =>
            sortActiveProposals(nameFieldSort.completedTransactions)
          }
        >
          {nameFieldSort.completedTransactions}
        </button>
        <button
          className="buyer-filters__names-list-item"
          onClick={() => sortActiveProposals(nameFieldSort.date)}
        >
          {nameFieldSort.date}
        </button>
        <button
          className="buyer-filters__names-list-item"
          onClick={() => sortActiveProposals(nameFieldSort.price)}
        >
          {nameFieldSort.price}
        </button>
        <button
          className="buyer-filters__names-list-item"
          onClick={() => sortActiveProposals(nameFieldSort.amount)}
        >
          {nameFieldSort.amount}
        </button>
      </div>
    );
  };

  const handleSearch = (name, event) => {
    event.preventDefault();
    setNameFilters((prev) => ({
      ...prev,
      [name]: event.target.value,
    }));
  };

  const pRef = useRef();
  const pRefSort = useRef();
  function outsidePClick() {
    setTogleFilters((prev) => ({
      ...prev,
      amount: false,
      id: false,
      price: false,
      paymentMethod: false,
      date: false,
      seller: false,
      accepted: false,
      paymentDeclared: false,
      cryptoReleased: false,
      escrowBalance: false,
      action: false,
      transactionsCount: false,
    }));
  }

  function outsidePClickSort() {
    setTogleSort(false);
  }
  useOutsideClick(pRef, outsidePClick);
  useOutsideClick(pRefSort, outsidePClickSort);

  return (
    <div>
      <div ref={pRef} className="buyer-filters">
        <ul className="buyer-filters__list">
          <li className="buyer-filters__list-item">
            <button
              disabled={loaderActiveProposals}
              onClick={() => togleFilter(nameFieldFilter.amount)}
              className={classNames(
                "buyer-filters__button",
                {
                  "buyer-filters__button-active": nameFilters.amount,
                },
                {
                  "buyer-filters__button-open": amount,
                }
              )}
            >
              Amount
              <div className="buyer-filters__name">{nameFilters.amount}</div>
            </button>
            {amount && (
              <div className="buyer-filters__names-list">
                {showFilter(nameFieldFilter.amount)}
              </div>
            )}
            {loaderActiveProposals && (
              <div className="buyer-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderActiveProposals}
                />
              </div>
            )}
          </li>

          <li className="buyer-filters__list-item">
            <label className="buyer-filters__label">Search ID</label>
            <input
              className="buyer-filters__input"
              type="search"
              onChange={(e) => handleSearch(nameFieldFilter.id, e)}
            />
            {loaderActiveProposals && (
              <div className="buyer-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderActiveProposals}
                />
              </div>
            )}
          </li>
          <li className="buyer-filters__list-item">
            <label className="buyer-filters__label">Price is lower than</label>
            <input
              className="buyer-filters__input"
              type="search"
              onChange={(e) => handleSearch(nameFieldFilter.price, e)}
            />
            {loaderActiveProposals && (
              <div className="buyer-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderActiveProposals}
                />
              </div>
            )}
          </li>
          <li className="buyer-filters__list-item">
            <button
              disabled={loaderActiveProposals}
              onClick={() => togleFilter(nameFieldFilter.transactionsCount)}
              className={classNames(
                "buyer-filters__button",
                {
                  "buyer-filters__button-active": nameFilters.transactionsCount,
                },
                {
                  "buyer-filters__button-open": transactionsCount,
                }
              )}
            >
              # of completed transactions
              <div className="buyer-filters__name">
                {nameFilters.transactionsCount}
              </div>
            </button>
            {transactionsCount && (
              <div className="buyer-filters__names-list">
                {showFilter(nameFieldFilter.transactionsCount)}
              </div>
            )}
            {loaderActiveProposals && (
              <div className="buyer-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderActiveProposals}
                />
              </div>
            )}
          </li>
        </ul>
        <button
          className="buyer-filters__showLess"
          onClick={() => setShowLess(!showLess)}
        >
          {showLess ? <p>Show less</p> : <p>Show all</p>}
        </button>
        {showLess && (
          <>
            <ul className="buyer-filters__list">
              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.paymentMethod)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active": nameFilters.paymentMethod,
                    },
                    {
                      "buyer-filters__button-open": paymentMethod,
                    }
                  )}
                >
                  Payment Method
                  <div className="buyer-filters__name">
                    {nameFilters.paymentMethod}
                  </div>
                </button>
                {paymentMethod && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.paymentMethod)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>
              <li className="buyer-filters__list-item">
                <div className="buyer-filters__list-item-search">
                  <label className="buyer-filters__label">Search Seller</label>
                  <input
                    className="buyer-filters__input"
                    type="search"
                    onChange={(e) => handleSearch(nameFieldFilter.seller, e)}
                  />
                </div>
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>
              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.date)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active": nameFilters.date,
                    },
                    {
                      "buyer-filters__button-open": togleFilters.date,
                    }
                  )}
                >
                  Date
                  <div className="buyer-filters__name">{nameFilters.date}</div>
                </button>
                {togleFilters.date && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.date)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>

              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.accepted)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active": nameFilters.accepted,
                    },
                    {
                      "buyer-filters__button-open": accepted,
                    }
                  )}
                >
                  Request Accepted
                  <div className="buyer-filters__name">
                    {nameFilters.accepted}
                  </div>
                </button>
                {accepted && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.accepted)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>
            </ul>
            <ul className="buyer-filters__list">
              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.escrowBalance)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active": nameFilters.escrowBalance,
                    },
                    {
                      "buyer-filters__button-open": escrowBalance,
                    }
                  )}
                >
                  Escrow Balance
                  <div className="buyer-filters__name">
                    {nameFilters.escrowBalance}
                  </div>
                </button>
                {escrowBalance && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.escrowBalance)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>
              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.paymentDeclared)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active":
                        nameFilters.paymentDeclared,
                    },
                    {
                      "buyer-filters__button-open": paymentDeclared,
                    }
                  )}
                >
                  Payment Declared
                  <div className="buyer-filters__name">
                    {nameFilters.paymentDeclared}
                  </div>
                </button>
                {paymentDeclared && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.paymentDeclared)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>

              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.cryptoReleased)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active":
                        nameFilters.cryptoReleased,
                    },
                    {
                      "buyer-filters__button-open": cryptoReleased,
                    }
                  )}
                >
                  Crypto Released
                  <div className="buyer-filters__name">
                    {nameFilters.cryptoReleased}
                  </div>
                </button>
                {cryptoReleased && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.cryptoReleased)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>
              <li className="buyer-filters__list-item">
                <button
                  disabled={loaderActiveProposals}
                  onClick={() => togleFilter(nameFieldFilter.action)}
                  className={classNames(
                    "buyer-filters__button",
                    {
                      "buyer-filters__button-active": nameFilters.action,
                    },
                    {
                      "buyer-filters__button-open": action,
                    }
                  )}
                >
                  Action
                  <div className="buyer-filters__name">
                    {nameFilters.action}
                  </div>
                </button>
                {action && (
                  <div className="buyer-filters__names-list">
                    {showFilter(nameFieldFilter.action)}
                  </div>
                )}
                {loaderActiveProposals && (
                  <div className="buyer-filters__loader">
                    <ClipLoader
                      color="#00ffff"
                      size="20px"
                      loading={loaderActiveProposals}
                    />
                  </div>
                )}
              </li>
            </ul>
          </>
        )}
        <div className="buyer-filters__sort">
          <div className="buyer-filters__sort-list">
            <button
              disabled={loaderActiveProposals}
              onClick={() => handleSort()}
              className={classNames(
                "buyer-filters__button",
                {
                  "buyer-filters__button-active": !togleSort,
                },
                {
                  "buyer-filters__button-open": togleSort,
                }
              )}
            >
              Sort by
              <div className="buyer-filters__name">{nameSort}</div>
            </button>
            {togleSort && (
              <div className="buyer-filters__names-list">{showSort()}</div>
            )}
            {loaderActiveProposals && (
              <div className="buyer-filters__loader">
                <ClipLoader
                  color="#00ffff"
                  size="20px"
                  loading={loaderActiveProposals}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
