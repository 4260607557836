import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CompletedTransactions_ADDRESS: null,
  DisputeResolution_ADDRESS: null,
  FinjaToken_ADDRESS: null,
  MainContract_ADDRESS: null,
  PaymentMethods_ADDRESS: null,
  ProposalsAndRequests_ADDRESS: null,
  USDC_ADDRESS: null,
  USDCDistributor_ADDRESS: null,
  chainsName: null,
  allChains: [],
  allowedChain: false
};

const contractsAddressSlice = createSlice({
  name: "contractsAddress",
  initialState,
  reducers: {
    fetchCompletedTransactions_ADDRESS: (state, action) => {
      state.CompletedTransactions_ADDRESS = action.payload;
    },
    fetchDisputeResolution_ADDRESS: (state, action) => {
      state.DisputeResolution_ADDRESS = action.payload;
    },
    fetchFinjaToken_ADDRESS: (state, action) => {
      state.FinjaToken_ADDRESS = action.payload;
    },
    fetchMainContract_ADDRESS: (state, action) => {
      state.MainContract_ADDRESS = action.payload;
    },
    fetchPaymentMethods_ADDRESS: (state, action) => {
      state.PaymentMethods_ADDRESS = action.payload;
    },
    fetchProposalsAndRequests_ADDRESS: (state, action) => {
      state.ProposalsAndRequests_ADDRESS = action.payload;
    },
    fetchUSDC_ADDRESS: (state, action) => {
      state.USDC_ADDRESS = action.payload;
    },
    fetchUSDCDistributor_ADDRESS: (state, action) => {
      state.USDCDistributor_ADDRESS = action.payload;
    },
    fetchChainsID: (state, action) => {
      state.chainsName = action.payload;
    },
    fetchAllChains: (state, action) => {
      state.allChains = action.payload;
    },
    getAllowedChainEscrow: (state, action) => {
      state.allowedChain = action.payload;
    },
  },
});

export const {
  fetchCompletedTransactions_ADDRESS,
  fetchDisputeResolution_ADDRESS,
  fetchFinjaToken_ADDRESS,
  fetchMainContract_ADDRESS,
  fetchPaymentMethods_ADDRESS,
  fetchProposalsAndRequests_ADDRESS,
  fetchUSDC_ADDRESS,
  fetchUSDCDistributor_ADDRESS,
  fetchChainsID,
  fetchAllChains,
  getAllowedChainEscrow
} = contractsAddressSlice.actions;

export default contractsAddressSlice.reducer;
