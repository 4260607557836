import React, { useEffect, useState, useRef } from "react";
import EscrowBuyer from "../components/EscrowBuyer/EscrowBuyer";
import EscrowSeller from "../components/EscrowSeller/EscrowSeller";
import Chat from "../components/Chat/Chat";
import { getAccount } from "@wagmi/core";
import { useDispatch, useSelector } from "react-redux";
import useOutsideClick from "@rooks/use-outside-click";
import {
  fetchProposalsSeller,
  cleanProposalsSeller,
  addIdForNewProposalSeller,
  addIdForDeleteProposalSeller,
  addIdForUpdateProposalSeller,
  addIdForProposalCompletedSeller,
  fetchProposalsSellerCompleted,
} from "../store/reducers/sellerProposals/sellerActions";
import { conversionData } from "../utils/conversionData";
import AwardPopup from "../components/AwardPopup/AwardPopup";
import {
  fetchProposals,
  fetchProposalsCompleted,
  cleanProposals,
  addIdForNewProposal,
  addIdForUpdateProposal,
  addIdForDeleteProposal,
  addIdForProposalCompleted,
} from "../store/reducers/buyerProposals/buyerActions";
import { useAccount } from "wagmi";
import { MainContract_ABI } from "../abis/MainContract";
import { DisputeResolution_ABI } from "../abis/DisputeResolution";
import { watchContractEvent } from "@wagmi/core";
import { fetchBalance } from "@wagmi/core";
import SwitchChain from "../components/SwitchChain/SwitchChain";
import { eventNames } from "../data/Events";

const Escrow = ({ togleSellerBuyer, app }) => {
  const { address, isConnected } = getAccount();
  const { isDisconnected } = useAccount();
  const dispatch = useDispatch();
  const [award, setAward] = useState(null);
  const awardRef = useRef();
  const {
    CompletedTransactions_ADDRESS,
    ProposalsAndRequests_ADDRESS,
    MainContract_ADDRESS,
    DisputeResolution_ADDRESS,
    FinjaToken_ADDRESS,
    allowedChain,
  } = useSelector((state) => state.contractsAddress);

  const fetchBalanceFinja = async () => {
    try {
      const balance = await fetchBalance({
        address: address,
        token: FinjaToken_ADDRESS,
      });
      return balance.value;
    } catch (error) {
      console.error(error);
    }
  };

  const getTheDifferenceBalanceFinja = () => {
    const getBalance = async () => {
      const prevBalanceFinja = localStorage.getItem("FinjaBalance");
      const balanceFinja = await fetchBalanceFinja();
      if (conversionData(balanceFinja) > prevBalanceFinja) {
        setAward(
          (
            (conversionData(balanceFinja) - prevBalanceFinja) *
            10 ** -18
          ).toFixed()
        );
        localStorage.setItem("FinjaBalance", balanceFinja);
      }
    };
    getBalance();
  };

  useEffect(() => {
    if (isConnected && allowedChain && CompletedTransactions_ADDRESS) {
      dispatch(fetchProposalsSeller(address, ProposalsAndRequests_ADDRESS));
      dispatch(
        fetchProposalsSellerCompleted(address, CompletedTransactions_ADDRESS)
      );
      dispatch(
        fetchProposals(
          address,
          ProposalsAndRequests_ADDRESS,
          DisputeResolution_ADDRESS
        )
      );
      dispatch(fetchProposalsCompleted(address, CompletedTransactions_ADDRESS));
      const getBalance = async () => {
        const balanceFinja = await fetchBalanceFinja();
        localStorage.setItem("FinjaBalance", balanceFinja);
      };
      getBalance();
    } else {
      dispatch(cleanProposalsSeller(address));
      dispatch(cleanProposals());
    }
  }, [
    isConnected,
    isDisconnected,
    CompletedTransactions_ADDRESS,
    ProposalsAndRequests_ADDRESS,
    address,
    allowedChain,
  ]);

  useEffect(() => {
    let unwatchMainContract;
    let unwatchDisputeResolution;
    if (address) {
      unwatchMainContract = watchContractEvent(
        {
          address: MainContract_ADDRESS,
          abi: MainContract_ABI,
        },
        (logs) => {
          const eventRequest =
            logs[0].eventName === eventNames.newBuyerRequest ||
            logs[0].eventName === eventNames.requestAccepted ||
            logs[0].eventName === eventNames.paymentConfirmed ||
            logs[0].eventName === eventNames.buyerRequestDeactivated;

          if (logs[0].eventName === eventNames.newProposal) {
            const currentProposalID = conversionData(logs[0].args.proposalId);
            dispatch(addIdForNewProposalSeller(currentProposalID));
            dispatch(addIdForNewProposal(currentProposalID));
          }

          if (logs[0].eventName === eventNames.proposalDeactivated) {
            const currentProposalID = conversionData(logs[0].args.proposalId);
            dispatch(addIdForDeleteProposalSeller(currentProposalID));
            dispatch(addIdForDeleteProposal(currentProposalID));
          }

          if (logs[0].eventName === eventNames.cancellationRequested) {
            const currentProposalID = conversionData(logs[0].args.proposalID);
            const visibilitymessage = logs[0].args.requestor !== address;
            dispatch(
              addIdForUpdateProposalSeller(
                currentProposalID,
                logs[0].eventName,
                visibilitymessage
              )
            );
            dispatch(
              addIdForUpdateProposal(
                currentProposalID,
                logs[0].eventName,
                visibilitymessage
              )
            );
          }

          if (eventRequest) {
            const currentProposalID = conversionData(logs[0].args.proposalID);
            dispatch(
              addIdForUpdateProposalSeller(currentProposalID, logs[0].eventName)
            );
            dispatch(
              addIdForUpdateProposal(currentProposalID, logs[0].eventName)
            );
          }

          if (logs[0].eventName === eventNames.cryptoReleased) {
            const currentProposalID = conversionData(logs[0].args.proposalID);
            dispatch(addIdForUpdateProposalSeller(currentProposalID));
            dispatch(addIdForUpdateProposal(currentProposalID));
            dispatch(addIdForProposalCompletedSeller(currentProposalID));
            dispatch(addIdForProposalCompleted(currentProposalID));
            getTheDifferenceBalanceFinja();
          }
        }
      );

      unwatchDisputeResolution = watchContractEvent(
        {
          address: DisputeResolution_ADDRESS,
          abi: DisputeResolution_ABI,
        },

        (logs) => {
          if (
            logs[0].eventName === eventNames.DisputeCreated ||
            logs[0].eventName === eventNames.DisputeResolved
          ) {
            const currentProposualID = conversionData(logs[0].args.proposalId);
            dispatch(addIdForUpdateProposalSeller(currentProposualID));
            dispatch(addIdForUpdateProposal(currentProposualID));
          }
        }
      );
    }
    return () => {
      unwatchMainContract?.();
      unwatchDisputeResolution?.();
    };
  }, [
    isDisconnected,
    MainContract_ADDRESS,
    DisputeResolution_ADDRESS,
    address,
    allowedChain,
  ]);

  function outsidePClick() {
    setAward(null);
  }
  useOutsideClick(awardRef, outsidePClick);

  return (
    <>
      <SwitchChain />

      {award && (
        <div ref={awardRef}>
          <AwardPopup award={award} />
        </div>
      )}
      {togleSellerBuyer === "seller" ? <EscrowSeller /> : <EscrowBuyer />}
      <Chat app={app} />
    </>
  );
};

export default Escrow;
