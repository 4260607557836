export const eventNames = {
  newBuyerRequest: "newBuyerRequest",
  requestAccepted: "requestAccepted",
  paymentConfirmed: "paymentConfirmed",
  buyerRequestDeactivated: "buyerRequestDeactivated",
  newProposal: "newProposal",
  proposalDeactivated: "proposalDeactivated",
  cancellationRequested: "cancellationRequested",
  cryptoReleased: "cryptoReleased",
  DisputeCreated: "DisputeCreated",
  VoteSubmitted: "VoteSubmitted",
  DisputeResolved: "DisputeResolved",
  DisputeReresolveDisputeFunctionTriggered: "DisputeReresolveDisputeFunctionTriggered"
}