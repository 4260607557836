import React, { useState } from "react";
import { descriptionInputText } from "../../data/DescriptionField";
import "./FieldDescription.scss";

const FieldDescription = ({ nameField }) => {
  const [descriptionInput, setDescriptionInput] = useState("");
  const handleHoverInput = (text) => {
    setDescriptionInput(text);
  };

  const handleLeaveInput = () => {
    setDescriptionInput("");
  };
  return (
    <>
      <div
        className="fieldDescription fieldDescription__desktop"
        onMouseEnter={() => handleHoverInput(descriptionInputText[nameField])}
        onMouseLeave={() => handleLeaveInput()}
      >
        {descriptionInput === descriptionInputText[nameField] && (
          <span className="fieldDescription__item">{descriptionInput}</span>
        )}
      </div>
      <div
        className="fieldDescription  fieldDescription__phone"
        onTouchStart={() => handleHoverInput(descriptionInputText[nameField])}
        onTouchEnd={() => handleLeaveInput()}
      >
        {descriptionInput === descriptionInputText[nameField] && (
          <span className="fieldDescription__item">{descriptionInput}</span>
        )}
      </div>
    </>
  );
};

export default FieldDescription;
