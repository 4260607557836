import React from "react";
import "./BuyerProposalsList.scss";
import { BuyerProposal } from "../../../components/EscrowBuyer/BuyerProposal/BuyerProposal";
import { BuyerProposalCompleted } from "../../../components/EscrowBuyer/BuyerProposal/BuyerProposalCompleted";
import { BuyerProposalFilters } from "../../../components/EscrowBuyer/BuyerProposalFilters/BuyerProposalFilters";
import BeatLoader from "react-spinners/BeatLoader";
import { AnotherCryptocurrency } from "../AnotherCryptocurrency/AnotherCryptocurrency";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export const BuyerProposalsList = () => {
  const {
    loaderActiveProposals,
    activeProposalsFilter,
    completedProposals,
    loaderCompletedProposals,
  } = useSelector((state) => state.buyer);

  return (
    <div className="prooals">
      <BuyerProposalFilters />

      <p className="prooals__title prooals__title-text">
        0.3% commission will be deducted from USDC amount you recieve
      </p>
      <AnotherCryptocurrency />
      <Tabs>
        <TabList>
          <Tab>Buy Crypto</Tab>
          <Tab>Completed Transactions</Tab>
        </TabList>
        <TabPanel>
          <div>
            {activeProposalsFilter?.map((proposal, index) => (
              <div className="prooals__proposal-item" key={proposal.id}>
                <BuyerProposal
                  proposal={proposal}
                />
              </div>
            ))}
          </div>
          {loaderActiveProposals && (
            <div className="loader">
              <BeatLoader color="#00ffff" loading={loaderActiveProposals} />
            </div>
          )}
        </TabPanel>
        <TabPanel>
          <div>
            {completedProposals?.map((proposal) => (
              <div className="prooals__proposal-item" key={proposal.date}>
                <BuyerProposalCompleted proposal={proposal} />
              </div>
            ))}
          </div>
          {loaderCompletedProposals && (
            <div className="loader">
              <BeatLoader color="#00ffff" loading={loaderCompletedProposals} />
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default BuyerProposalsList;
