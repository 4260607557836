
import HomeList from "../components/Home/HomeList/HomeList";

const Home = () => {
  return (
    <HomeList />
  );
};

export default Home;
